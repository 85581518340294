import { DatePicker, Space, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const { Text } = Typography;

export const FormDateInput = ({ name, control, label, rules, ...props }) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    name,
    control,
    rules: rules,
  });

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {label && (
        <label>
          {label}
          {rules?.required && (
            <Text type="danger">
              <sup> *</sup>
            </Text>
          )}
        </label>
      )}
      <DatePicker
        status={fieldState?.error ? "error" : ""}
        {...props}
        {...field}
        style={{ width: "100%" }}
        value={field.value ? dayjs(field.value) : null}
        onChange={(date) => field.onChange(date ? date.toISOString() : null)}
      />
      {fieldState?.error?.message && (
        <Text type="danger">{t(fieldState.error.message)}</Text>
      )}
    </Space>
  );
};
