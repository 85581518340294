import { Input, Space, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";
const { Text } = Typography;

export const FormPhoneInput = ({
  name,
  control,
  label,
  mask = "+\\9\\98 99 999 99 99",
  rules,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    field: { ref, onBlur, ...ff },
    fieldState,
  } = useController({
    name,
    control,
    rules: rules,
  });
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {label && (
        <label>
          {label}
          {rules?.required && (
            <Text type="danger">
              <sup> *</sup>
            </Text>
          )}
        </label>
      )}

      <ReactInputMask
        mask={mask}
        {...props}
        {...ff}
        {...fieldState}
        onBlur={onBlur}
      >
        {(inputProps) => (
          <Input
            status={fieldState?.error && "error"}
            {...inputProps}
            ref={ref}
            type="tel"
          />
        )}
      </ReactInputMask>
      {fieldState?.error?.message && (
        <Text type="danger">{t(fieldState.error.message)}</Text>
      )}
    </Space>
  );
};
