export const curh = window.location.hostname;
export let redirectUrl;
let stateTxt;
// export let redirectUrl = window.location.href;

if (curh == "localhost") {
  // dev code
  redirectUrl = "http://localhost:3000/";
} else {
  // production code
  redirectUrl = "https://cabinet.karantin.uz/";
}

if (window.location.search.substring(1) == "price=1") {
  stateTxt = "fumPrice";
} else {
  stateTxt = "test";
}

// console.log(redirectUrl);

export function navigate() {
  window.location.href = `https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=efito_uz&redirect_uri=${redirectUrl}&scope=efito_uz&state=${stateTxt}`;
}

export default redirectUrl;
// https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=efito_uz&redirect_uri=http://localhost:3000/&scope=efito_uz&state=test
