import { Empty, Pagination } from "antd";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { fetchRequest } from "../../helpers/createRequest";
import queryString from "query-string";
import "./inner.css";
import DetailModal from "./DetailModal";

const ExportProduct = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 15;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [productID, setProductID] = useState(null);

  const [list, setList] = useState([]);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState();

  const [filter, setFilter] = useState(true);

  useEffect(() => {
    if (_.isEmpty(products)) {
      getProducts();
    }
    getList();
  }, [queryParams.page, queryParams.name, product]);

  async function getProducts() {
    await fetchRequest
      .get(`export/product-types/`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function getList() {
    setIsLoading(true);
    await fetchRequest
      .get(`export/products/?page_size=${LIMIT}`, {
        params: {
          page: queryParams.page,
          search: queryParams.name,
          product_types: product,
        },
      })
      .then((res) => {
        setList(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const onProductClick = (id) => {
    setProductID(id);
    setIsOpen(true);
  };
  const onProductClose = () => {
    setProductID(null);
    setIsOpen(false);
  };

  const onFilter = (itemValue, itemKey) => {
    const rootParams = { ...queryParams };
    if (itemValue) {
      rootParams[itemKey] = itemValue;
      rootParams.page = null;
    } else {
      rootParams[itemKey] = null;
    }
    props.history.push({
      search: queryString.stringify(rootParams, { skipNull: true }),
    });
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="balance-page">
      <div className="top">
        <h1>{t("agro.export-product")}</h1>
        <div className="right">
          <div style={{ display: "flex" }}>
            <div
              className="filter-icon"
              onClick={() => setFilter(!filter)}
            ></div>
          </div>
        </div>
      </div>

      <div className={`${filter ? "filter active-filter" : "filter"}`}>
        <input
          type="text"
          placeholder={t("common.name")}
          value={queryParams.name || ""}
          onChange={(e) => onFilter(e.target.value, "name")}
        />
        <select
          value={product}
          onChange={(e) => {
            setProduct(e.target.value);
            onFilter();
          }}
        >
          <option value={""}>{t("common.product")}</option>
          {products?.map((s, idx) => (
            <option value={s.id} key={idx}>
              {s.name}
            </option>
          ))}
        </select>
        <button
          onClick={(e) => {
            getList();
            onPageChange(1);
          }}
        >
          {t("balance.show")}
        </button>
      </div>

      {isLoading ? <Loader /> : null}

      {!isEmpty(list) && (
        <div className="cards-page">
          <div className="agro-inner-cards">
            {list.map((cli, idx) => (
              <div
                className="agro-inner-card sm"
                key={idx}
                onClick={(e) => onProductClick(cli.id)}
              >
                {/* <div className="card-info">
                  <div className="card-title">
                    <h2>{cli.title}</h2>
                  </div>
                  <div className="card-text"></div>
                </div> */}
                <div className="product-info">
                  <div className="product-title">
                    <h3>{cli.title}</h3>
                  </div>
                  <div className="card-img-sm">
                    <img src={cli.image}></img>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {productID && (
        <DetailModal open={isOpen} setOpen={onProductClose} id={productID} />
      )}

      {_.isEmpty(list) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
      {!isLoading && !_.isEmpty(list) && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default ExportProduct;
