import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import request from "../../helpers/createRequest";
import { Badge, Empty, Pagination } from "antd";
import _ from "lodash";
import Loader from "../../components/loader/Loader";
import TableRow from "./TableRow";
import Price from "./Price";
import AddFumigation from "./AddFumigation";

const Fumigation = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 10;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  const [open, setOpen] = useState(false);
  const [openFumigation, setOpenFumigation] = useState(false);

  const [allLists, setAllLists] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (queryParams.price) {
      setOpen(true);
    }

    // eslint-disable-next-line
  }, []);

  async function getPriceList() {
    await request
      .get(`api/fumigation-declarations/`)
      .then((res) => {
        setPriceList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function getList() {
    setIsLoading(true);
    await request
      .get(
        `applicant/certificate-of-disinfestation-application/list/?limit=${LIMIT}&offset=${OFFSET}`
      )
      .then((res) => {
        setAllLists(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const handleClose = () => {
    props.history.push({ search: queryString.stringify({}) });
    setOpen(false);
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  useEffect(() => {
    getList();

    // eslint-disable-next-line
  }, [queryParams.page]);

  useEffect(() => {
    getPriceList();
  }, []);

  return (
    <div className="inner-fcc-page">
      <Price
        open={open}
        setOpen={setOpen}
        data={priceList}
        handle={handleClose}
      />
      <AddFumigation
        setOpenFumigation={setOpenFumigation}
        openFumigation={openFumigation}
        getList={getList}
        setOpen={setOpen}
      />
      <div className="top">
        <h1>{t("navbar.fum")}</h1>
        <div className="right">
          <div className="btn-wrapper" style={{ display: "flex" }}>
            <div className="btn-wrap">
              <button
                className={"button-active"}
                onClick={() => setOpenFumigation(true)}
                style={{ position: "relative" }}
              >
                <div className="badge-new">
                  <Badge count={"new"} style={{ backgroundColor: "#f40003" }} />
                </div>
                {t("inner-fcc.single")}
              </button>
              <button
                className={"button-active"}
                onClick={() => setOpen(true)}
              >
                {t("fumig.pricelist")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <Loader /> : null}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.zay-ser")}</th>
              <th>{t("inner-fcc.naim")}</th>
              <th>{t("fumig.region")}</th>
              <th>{t("fumig.district")}</th>
              <th>{t("inner-fcc.added_at")}</th>
              <th>{t("inner-fcc.sos-ot")}</th>
              <th>{t("inner-fcc.otp-zan")}</th>
            </tr>
          </thead>
          <tbody>
            {allLists?.map((item, index) => {
              const date = new Date(item.given_date);
              return (
                <TableRow
                  item={item}
                  index={index}
                  date={date}
                  key={index}
                  offset={OFFSET}
                  setOpen={setOpen}
                  getList={getList}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {_.isEmpty(allLists) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}

      {!_.isEmpty(allLists) && !isLoading && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Fumigation;
