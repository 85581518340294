import { Space } from "antd";
import { StampWrapper } from "./style";
export const StampStatus = ({
  region = "JIZZAX VILOYATI",
  status = "dangerous_region_product",
}) => {
  const statuses = {
    dangerous_region_product: (
      <div className="status">
        <p>XAVFLI-ZARARLANGAN MINTAQA MAHSULOTI</p>
        <span>« МАТЕРИАЛ ИЗ ЗАРАЖЕННОЙ ЗОНЫ »</span>
      </div>
    ),
    quarantine_organisms_exist: (
      <div className="status">
        <p>KARANTIN ORGANIZMLARI MAVJUD</p>
        <span>« ОБНАРУЖЕНЫ КАРАНТИННЫЕ ОРГАНИЗМЫ »</span>
      </div>
    ),
    harmful_organisms_exist: (
      <div className="status">
        <p>ZARARLI ORGANIZMLAR MAVJUD</p>
        <span>« ОБНАРУЖЕНЫ ВРЕДНЫЕ ОРГАНИЗМЫ »</span>
      </div>
    ),
    quarantine_organisms_not_exist: (
      <div className="status">
        <p>KARANTIN ORGANIZMLARI MAVJUD EMAS</p>
        <span>« КАРАНТИННЫХ ОРГАНИЗМОВ НЕ ОБНАРУЖЕНО »</span>
      </div>
    ),
  };
  return (
    <Space size="medium">
      <StampWrapper className={status}>
        <Space className="stamp" size="small" direction="vertical">
          <h4>
            {region}
            {/* O'SIMLIKLAR
            <br />
            KARANTINI VA HIMOYASI BOSHQARMASI
            <br />
            FITOSANITARIYA LABORATORIYASI */}
          </h4>
          {statuses[status]}
        </Space>
      </StampWrapper>
    </Space>
  );
};
