import { USER_LOGIN, USER_LOGOUT } from "../types/Types";

let initialToken = JSON.parse(window.localStorage.getItem("user_data"))?.token;
let expiresIn = JSON.parse(
  window.localStorage.getItem("user_data")
)?.expires_in;
let name = JSON.parse(window.localStorage.getItem("user_data"))?.name;
let pinOrTin = JSON.parse(window.localStorage.getItem("user_data"))?.pin_or_tin;
let email = JSON.parse(window.localStorage.getItem("user_data"))?.email;
let phone = JSON.parse(window.localStorage.getItem("user_data"))?.mob_phone_no;

const initialState = {
  token: initialToken,
  expiresIn,
  name,
  pinOrTin,
  email,
  phone,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        expiresIn: action.payload.expires_in,
        name: action.payload.name,
        pinOrTin: action.payload.pin_or_tin,
        email: action.payload.email,
        phone: action.payload.mob_phone_no,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default loginReducer;
