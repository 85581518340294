import React from "react";
import _ from "lodash";
import "../comparison/table.css";
import { useTranslation } from "react-i18next";
import "./export.css";
import moment from "moment";
import { packagings } from "./packaging";
import redirectUrl from "../../utils/navigate";

const textCenter = {
  textAlign: "center",
};

const tableTwo = {
  width: "50%",
};

const tableThree = {
  width: "33.33%",
};

const PrintableComponentNewUz = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { data, countries } = props;

  let toCountry;
  let fromCountry;

  if (data) {
    toCountry = countries.filter((c) => c.id == data.importer_country)[0]
      ?.name_en;

    fromCountry = countries.filter((c) => c.id == data.exporter_country)[0]
      ?.name_en;
  }

  const regExp = new RegExp("23\\d{9}");
  let importNum = data.extra_info?.match(regExp);
  const regExpNum = new RegExp("2315\\d{7}");
  const spsNote = data?.api_data?.SPSExchangedDocument?.IncludedSPSNote;
  let spsImpNum;
  let spsFinContent = "";

  if (spsNote) {
    if (_.isArray(spsNote)) {
      spsNote?.map((item, idx) => {
        if (typeof item.Content != "string") {
          spsImpNum = item.Content["#text"]?.match(regExpNum);
        }
        if (item.Content == "5") {
          spsFinContent =
            t("fss.spsfl1") +
            `${data?.api_data?.SPSExchangedDocument?.IssuerSPSParty.Name
              ? data?.api_data?.SPSExchangedDocument?.IssuerSPSParty.Name
              : "NPPO"
            }` +
            t("fss.spsfl2");
        }
      });
    } else {
      if (spsNote.Content == "5") {
        spsFinContent =
          t("fss.spsfl1") +
          `${data?.api_data?.SPSExchangedDocument?.IssuerSPSParty.Name
            ? data?.api_data?.SPSExchangedDocument?.IssuerSPSParty.Name
            : "NPPO"
          }` +
          t("fss.spsfl2");
      }
    }
  }

  const itemIsArray = _.isArray(
    data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem
      ?.IncludedSPSTradeLineItem
  );
  const itemParentIsArray = _.isArray(
    data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem
  );

  let productType = "";
  let condition = "";

  if (!spsImpNum || (!productType && !condition)) {
    if (itemParentIsArray) {
      data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem?.map(
        (par, idx) => {
          if (
            _.isArray(
              par.IncludedSPSTradeLineItem?.AdditionalInformationSPSNote
            )
          ) {
            par.IncludedSPSTradeLineItem?.AdditionalInformationSPSNote?.map(
              (cont, id) => {
                if (cont.Subject == "ADIPTLIL") {
                  if (typeof cont.Content != "object") {
                    spsImpNum = cont.Content;
                    return;
                  }
                }
              }
            );
          }
          if (par.IncludedSPSTradeLineItem?.ApplicableSPSClassification) {
            if (
              _.isArray(
                par.IncludedSPSTradeLineItem?.ApplicableSPSClassification
              )
            ) {
              par.IncludedSPSTradeLineItem?.ApplicableSPSClassification?.map(
                (clas, idx) => {
                  if (clas.SystemName == "IPPCPCVP") {
                    if (typeof clas.ClassName == "object") {
                      productType = clas.ClassName["#text"];
                    } else {
                      productType = clas.ClassName;
                    }
                  } else if (clas.SystemName == "IPPCPCC") {
                    if (typeof clas.ClassName == "object") {
                      condition = clas.ClassName["#text"];
                    } else {
                      condition = clas.ClassName;
                    }
                  }
                }
              );
            } else if (
              typeof par.IncludedSPSTradeLineItem
                ?.ApplicableSPSClassification == "object"
            ) {
              const clas =
                par.IncludedSPSTradeLineItem?.ApplicableSPSClassification;
              if (clas.SystemName == "IPPCPCVP") {
                if (typeof clas.ClassName == "object") {
                  productType = clas.ClassName["#text"];
                } else {
                  productType = clas.ClassName;
                }
              } else if (clas.SystemName == "IPPCPCC") {
                if (typeof clas.ClassName == "object") {
                  condition = clas.ClassName["#text"];
                } else {
                  condition = clas.ClassName;
                }
              }
            }
          }
        }
      );
    } else {
      if (!itemIsArray) {
        if (
          _.isArray(
            data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem
              ?.IncludedSPSTradeLineItem?.AdditionalInformationSPSNote
          )
        ) {
          data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem?.IncludedSPSTradeLineItem?.AdditionalInformationSPSNote?.map(
            (content, idx) => {
              if (content.Subject == "ADIPTLIL") {
                if (typeof content.Content != "object") {
                  spsImpNum = content.Content;
                  return;
                }
              }
            }
          );
        }
        if (
          _.isArray(
            data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem
              ?.IncludedSPSTradeLineItem?.ApplicableSPSClassification
          )
        ) {
          data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem?.IncludedSPSTradeLineItem?.ApplicableSPSClassification?.map(
            (clas, idx) => {
              if (clas.SystemName == "IPPCPCVP") {
                if (typeof clas.ClassName == "object") {
                  productType = clas.ClassName["#text"];
                } else {
                  productType = clas.ClassName;
                }
              } else if (clas.SystemName == "IPPCPCC") {
                if (typeof clas.ClassName == "object") {
                  condition = clas.ClassName["#text"];
                } else {
                  condition = clas.ClassName;
                }
              }
            }
          );
        } else if (
          typeof data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem
            ?.IncludedSPSTradeLineItem?.ApplicableSPSClassification == "object"
        ) {
          const clas =
            data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem
              ?.IncludedSPSTradeLineItem?.ApplicableSPSClassification;
          if (clas.SystemName == "IPPCPCVP") {
            if (typeof clas.ClassName == "object") {
              productType = clas.ClassName["#text"];
            } else {
              productType = clas.ClassName;
            }
          } else if (clas.SystemName == "IPPCPCC") {
            if (typeof clas.ClassName == "object") {
              condition = clas.ClassName["#text"];
            } else {
              condition = clas.ClassName;
            }
          }
        }
      } else {
        data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem?.IncludedSPSTradeLineItem?.map(
          (item, id) => {
            if (_.isArray(item.AdditionalInformationSPSNote)) {
              item.AdditionalInformationSPSNote?.map((cont, idx) => {
                if (cont.Subject == "ADIPTLIL") {
                  if (typeof cont.Content != "object") {
                    spsImpNum = cont.Content;
                    return;
                  }
                }
              });
            }
            if (_.isArray(item.ApplicableSPSClassification)) {
              item.ApplicableSPSClassification?.map((clas, idx) => {
                if (clas.SystemName == "IPPCPCVP") {
                  if (typeof clas.ClassName == "object") {
                    productType = clas.ClassName["#text"];
                  } else {
                    productType = clas.ClassName;
                  }
                } else if (clas.SystemName == "IPPCPCC") {
                  if (typeof clas.ClassName == "object") {
                    condition = clas.ClassName["#text"];
                  } else {
                    condition = clas.ClassName;
                  }
                }
              });
            } else if (typeof item.ApplicableSPSClassification == "object") {
              if (item.ApplicableSPSClassification.SystemName == "IPPCPCVP") {
                if (
                  typeof item.ApplicableSPSClassification.ClassName == "object"
                ) {
                  productType =
                    item.ApplicableSPSClassification.ClassName["#text"];
                } else {
                  productType = item.ApplicableSPSClassification.ClassName;
                }
              } else if (
                item.ApplicableSPSClassification.SystemName == "IPPCPCC"
              ) {
                if (
                  typeof item.ApplicableSPSClassification.ClassName == "object"
                ) {
                  condition =
                    item.ApplicableSPSClassification.ClassName["#text"];
                } else {
                  condition = item.ApplicableSPSClassification.ClassName;
                }
              }
            }
          }
        );
      }
    }
  }

  let spsClause = "";

  let additionalContent =
    data?.api_data?.SPSExchangedDocument?.SignatorySPSAuthentication
      ?.IncludedSPSClause;
  if (additionalContent) {
    if (_.isArray(additionalContent)) {
      additionalContent?.map((content, idx) => {
        if (content.ID == 1) {
          spsClause += t("fss.certi");
          spsClause += " ";
        } else if (content.ID == 2) {
          spsClause += t("fss.certi2");
        }
      });
    } else {
      if (additionalContent.ID == 1) {
        spsClause += t("fss.certi");
      }
    }
  }

  let treatment = "";
  if (!_.isEmpty(data?.products[0]?.treatments)) {
    if (data?.products[0]?.treatments[0]?.full_treatment) {
      if (data?.products[0]?.treatments[0]?.full_treatment != "NULL") {
        if (data?.products[0]?.treatments[0]?.full_treatment.includes("{")) {
          let treat = JSON.parse(
            data?.products[0]?.treatments[0]?.full_treatment
              .replaceAll(`"{`, `'{`)
              .replaceAll(`}"`, `}'`)
              .replaceAll("'", `"`)
          );
          treatment = treat["#text"];
        } else {
          treatment = data?.products[0]?.treatments[0]?.full_treatment;
        }
      }

      // console.log(treat["#text"]);
    }
  }

  let reexport = "";

  if (data?.certificate_type == 657) {
    if (!_.isEmpty(data?.products[0]?.sps_notes)) {
      data?.products[0]?.sps_notes?.map((sps, idx) => {
        if (sps.note_type == 8) {
          reexport = sps.text;
        }
      });
    }
  }

  let distMarks = "";

  if (!_.isEmpty(data?.sps_notes)) {
    data?.sps_notes?.map((sps, idx) => {
      if (sps.note_type == 7) {
        distMarks = sps.text;
      }
    });
  }

  let adrpn = "";
  let adrpnContent;

  if (!adrpn) {
    if (_.isArray(data?.api_data?.SPSExchangedDocument?.IncludedSPSNote)) {
      adrpnContent = data?.api_data?.SPSExchangedDocument?.IncludedSPSNote?.map(
        (nt, idx) => {
          if (nt.Subject == "ADRPN") {
            if (typeof nt.Content != "object") {
              adrpn = nt.Content;
              return (
                <span>
                  {t("fss.link")}:{" "}
                  <a
                    href={`${redirectUrl}efito/import-fss/print?number=${nt.Content}`}
                    target="_blank"
                  >
                    {adrpn}
                  </a>
                </span>
              );
            } else {
              adrpn = nt.Content["#text"];
              return (
                <span>
                  {t("fss.link")}:{" "}
                  <a
                    href={`${redirectUrl}efito/import-fss/print?number=${nt.Content["#text"]}`}
                    target="_blank"
                  >
                    {adrpn}
                  </a>
                </span>
              );
            }
          }
        }
      );
    }
  }

  let slicedAdrpn = "";

  if (!adrpn) {
    adrpnContent = data?.sps_notes?.map((nt, idx) => {
      if (nt.note_type == 1) {
        if (nt.text.includes("cancels")) {
          // console.log(nt.text);
          let arr = nt.text.split("cancels");
          slicedAdrpn = arr[1].slice(1, arr[1].indexOf(","));
          return (
            <span>
              {t("fss.link")}:{" "}
              <a
                href={`${redirectUrl}efito/import-fss/print?number=${slicedAdrpn}`}
                target="_blank"
              >
                {slicedAdrpn}
              </a>
            </span>
          );
        }
      }
    });
  }

  let replacedCertContent = "";

  if (!replacedCertContent) {
    data?.sps_notes?.map((nt, idx) => {
      if (nt.note_type == 1) {
        if (nt.text.includes("cancels")) {
          replacedCertContent = nt.text;
        }
      }
    });
  }

  if (!replacedCertContent) {
    const filtered = data?.sps_notes?.filter((nt) => nt.note_type == 6);
    replacedCertContent = filtered?.sort((a, b) => b.id - a.id)[0]?.text || "";
  }

  let description = "";

  if (data?.api_data?.SPSExchangedDocument?.Description) {
    if (typeof data?.api_data?.SPSExchangedDocument?.Description != "object") {
      description = data.api_data.SPSExchangedDocument.Description;
    } else {
      description = data.api_data.SPSExchangedDocument.Description["#text"];
    }
  }

  const adtlil = [];

  if (itemParentIsArray) {
    data?.api_data?.SPSConsignment?.IncludedSPSConsignmentItem?.map(
      (item, idx) => {
        if (item?.IncludedSPSTradeLineItem) {
          if (item?.IncludedSPSTradeLineItem?.AdditionalInformationSPSNote) {
            if (
              _.isArray(
                item?.IncludedSPSTradeLineItem?.AdditionalInformationSPSNote
              )
            ) {
              let count = 0;
              item?.IncludedSPSTradeLineItem?.AdditionalInformationSPSNote.map(
                (sps, sqe) => {
                  if (sps.Subject == "ADTLIL") {
                    if (typeof sps.Content == "object") {
                      // adtlil.push(
                      //   `${
                      //     count == 0 ? "Commodity № " + (idx + 1) + ": " : " "
                      //   } ${sps.Content["#text"]}`
                      // );
                      // count++;
                      if (count == 0) {
                        adtlil.push(
                          `Commodity № ${idx + 1}: ${sps.Content["#text"]}`
                        );
                        count++;
                      } else {
                        adtlil[adtlil.length - 1] =
                          adtlil[adtlil.length - 1] +
                          ` ${sps.Content["#text"]}`;
                      }
                    }
                  }
                }
              );
            }
          }
        }
      }
    );
  }

  let noteTypeDate = "";
  if (!noteTypeDate) {
    data?.sps_notes?.map((note, idx) => {
      if (note.note_type == 3) {
        noteTypeDate = note.text;
        return noteTypeDate;
      }
    });
  }

  const displayCountries = (transits) => {
    const cties = [];
    if (transits) {
      for (let i = 0; i < transits.length; i++) {
        cties.push(countries.filter((ct) => ct.id == transits[i].country)[0]);
      }
    }
    // console.log();
    return cties.filter(Boolean);
  };

  if (!data) {
    return null;
  }
  return (
    <>
      {data?.exporter_country == 192 ? (
        <div
          className="printable-spot"
          style={{
            minHeight: "1011px",
            width: "100%",
            padding: "20px 25px",
            // height: "calc(100% - 30px)",
            backgroundImage: 'url("./assets/images/fss.svg") !important',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center 300px",
            position: "relative",
          }}
          ref={ref}
        >
          <div
            className="cert-top"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <span>
              <strong>{data?.number}</strong>
            </span>
            <span>
              <strong>{t("fss.cert")}</strong>
            </span>
          </div>

          <div style={{ height: "100%" }}>
            <div className="invoice-info">
              <table
                className="table-two"
                style={{ width: "100%", marginBottom: 0 }}
              >
                <tbody>
                  <tr>
                    <th
                      rowSpan={14}
                      style={{
                        padding: "3px",
                      }}
                    >
                      <span
                        style={{
                          width: "16px",
                          // borderBottom: "none",
                          // borderTop: "none",
                          // writingMode: "tb-rl",
                          writingMode: "vertical-rl",
                          // textOrientation: "mixed",
                          transform: "rotate(-180deg)",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                          // padding: "5px",
                        }}
                      >
                        {t("fss.cert")}
                      </span>
                    </th>
                    <th
                      style={{
                        width: "48%",
                        borderBottom: "none",
                        // borderTop: "none",
                      }}
                    >
                      {t("fss.from")}
                    </th>
                    <th
                      style={{
                        width: "48%",
                        borderBottom: "none",
                        // borderTop: "none",
                      }}
                    >
                      {t("fss.to")}
                    </th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center", borderTop: "none" }}>
                      {fromCountry}
                    </td>
                    <td style={{ textAlign: "center", borderTop: "none" }}>
                      {toCountry}
                    </td>
                  </tr>
                  <tr>
                    <th
                      colSpan={2}
                      style={{ textAlign: "center", background: "#c4c7c7" }}
                      className="th-heading"
                    >
                      I. {t("fss.desc")}
                    </th>
                  </tr>
                  <tr>
                    <th style={tableTwo}>{t("fss.exporter")}</th>
                    <th style={tableTwo}>{t("fss.importer")}</th>
                  </tr>
                  <tr>
                    <td style={tableTwo}>
                      {data.exporter_name}
                      <br />
                      {data.exporter_address}
                    </td>
                    <td style={tableTwo}>
                      {data.importer_name}
                      <br />
                      {data.importer_address}
                    </td>
                  </tr>
                  <tr>
                    <th style={tableTwo}>{t("fss.transport")}</th>
                    <th style={tableTwo}>{t("fss.entry")}</th>
                  </tr>
                  <tr>
                    <td style={tableTwo}>
                      {data.transport_method}{" "}
                      {data.transport_number
                        ? `(${data.transport_number})`
                        : ""}
                    </td>
                    <td style={tableTwo}>{toCountry}</td>
                  </tr>
                  <tr>
                    <th style={tableTwo}>{t("fss.seals")}</th>
                    <th style={tableTwo}>{t("fss.dist")}</th>
                  </tr>
                  <tr>
                    <th
                      colSpan={2}
                      style={{ textAlign: "center", background: "#c4c7c7" }}
                      className="th-heading"
                    >
                      II. {t("fss.comm")}
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {data?.products?.map((pro, idx) => (
                        <table
                          key={idx}
                          style={{ marginBottom: "2px", width: "100%" }}
                          className="product-table"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{ width: "12px", background: "#c4c7c7" }}
                                className="th-heading"
                              >
                                {idx + 1}
                              </th>
                              <th>{t("fss.description")}</th>
                              <th>{t("fss.sname")}</th>
                              <th>{t("fss.amount")}</th>
                              <th>{t("fss.pack")}</th>
                              <th>{t("fss.dist")}</th>
                              <th>{t("fss.origin")}</th>
                              <th>{t("fss.permit")}</th>
                              <th>{t("fss.date")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                {pro.name + " " + pro.hs_code}
                              </td>
                              <td>{pro.botanic_name}</td>
                              <td>
                                {pro.net_quantity
                                  ? pro.net_quantity.replace(/\.000$/, "")
                                  : ""}{" "}
                                {pro.net_quantity_unit}
                              </td>
                              <td>
                                {pro.package_quantity
                                  ? pro.package_quantity.replace(/\.000$/, "") +
                                  " " +
                                  pro.package_quantity_unit
                                  : ""}
                                { }
                              </td>
                              <td>{ }</td>
                              <td>
                                {
                                  countries?.filter(
                                    (c) => c.id == pro.manufactured_country
                                  )[0]?.name_en
                                }
                              </td>
                              <td>
                                {importNum ? (
                                  <a
                                    href={`https://efito.uz/ikr/${importNum}/download`}
                                    target="_blank"
                                  >
                                    {importNum}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {moment(data.given_date).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>{t("fss.declare")}</th>
                  </tr>
                  <tr>
                    <td colSpan={2}>{data.extra_info}</td>
                  </tr>
                  <tr>
                    <th colSpan={2}>{t("fss.treat")}</th>
                  </tr>
                  <tr>
                    <th
                      colSpan={2}
                      style={{ textAlign: "center", background: "#c4c7c7" }}
                      className="th-heading"
                    >
                      III. {t("fss.certify")}
                    </th>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: "auto", marginLeft: "23px" }}>
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ borderTop: "none" }}>
                      {t("fss.certi")}
                    </td>
                  </tr>
                  <tr>
                    <td style={tableTwo}>
                      <strong>{t("fss.issue")}:</strong> {fromCountry}
                    </td>
                    <td style={tableTwo}>
                      <strong>{t("fss.officer")}: </strong>
                      {data.inspector_name || ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={tableTwo}>
                      <strong>{t("fss.date")}:</strong>{" "}
                      {moment(data.given_date).format("DD-MM-YYYY")}
                    </td>
                    <td style={tableTwo}>
                      <strong>{t("fss.sign")}:</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${data?.status == 70 ? "printable-spot" : "printable-spot replaced"
            }`}
          style={{
            minHeight: "1011px",
            width: "100%",
            padding: "20px 25px",
            // height: "calc(100% - 30px)",
            backgroundImage: 'url("./assets/images/fss.svg") !important',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center 300px",
            position: "relative",
          }}
          ref={ref}
        >
          <div
            className="cert-top"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <span>
              <strong>{data?.number}</strong>
            </span>
            <span>
              <strong>
                {data?.certificate_type == 851
                  ? t("fss.cert")
                  : data?.certificate_type == 657
                    ? t("fss.reex")
                    : data?.certificate_type == 312
                      ? t("fss.ackn")
                      : t("fss.cert")}
              </strong>
            </span>
          </div>

          <div style={{ height: "100%" }}>
            <div className="invoice-info">
              <table
                className="table-two"
                style={{ width: "100%", marginBottom: 0 }}
              >
                <tbody>
                  <tr>
                    <th
                      rowSpan={16}
                      style={{
                        padding: "3px",
                      }}
                    >
                      <span
                        style={{
                          width: "16px",
                          // borderBottom: "none",
                          // borderTop: "none",
                          // writingMode: "tb-rl",
                          writingMode: "vertical-rl",
                          // textOrientation: "mixed",
                          transform: "rotate(-180deg)",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                          // padding: "5px",
                        }}
                      >
                        {data?.certificate_type == 851
                          ? t("fss.cert")
                          : data?.certificate_type == 657
                            ? t("fss.reex")
                            : data?.certificate_type == 312
                              ? t("fss.ackn")
                              : t("fss.cert")}
                      </span>
                    </th>
                    <th
                      style={{
                        width: "50%",
                        borderBottom: "none",
                        // borderTop: "none",
                      }}
                    >
                      {t("fss.from")}
                    </th>
                    <th
                      style={{
                        width: "50%",
                        borderBottom: "none",
                        // borderTop: "none",
                      }}
                    >
                      {t("fss.to")}
                    </th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center", borderTop: "none" }}>
                      {fromCountry}
                      {data?.api_data?.SPSExchangedDocument?.IssuerSPSParty.Name
                        ? ` (${data?.api_data?.SPSExchangedDocument?.IssuerSPSParty.Name})`
                        : ""}
                    </td>
                    <td style={{ textAlign: "center", borderTop: "none" }}>
                      {toCountry}
                    </td>
                  </tr>
                  <tr>
                    <th
                      colSpan={2}
                      style={{ textAlign: "center", background: "#c4c7c7" }}
                      className="th-heading"
                    >
                      I. {t("fss.desc")}
                    </th>
                  </tr>
                  <tr>
                    <th style={tableTwo}>{t("fss.exporter")}</th>
                    <th style={tableTwo}>{t("fss.importer")}</th>
                  </tr>
                  <tr>
                    <td style={tableTwo}>
                      {data.exporter_name}
                      <br />
                      {data.exporter_address}
                    </td>
                    <td style={tableTwo}>
                      {data.importer_name}
                      <br />
                      {data.importer_address}
                    </td>
                  </tr>
                  <tr>
                    <th style={tableTwo}>{t("fss.transport")}</th>
                    <th style={tableTwo}>{t("fss.entry")}</th>
                  </tr>
                  <tr>
                    <td style={tableTwo}>
                      {data.transports ? data.transports[0]?.method : ""}
                      {data?.api_data?.SPSConsignment
                        ?.LoadingBaseportSPSLocation?.Name
                        ? ` (${data?.api_data?.SPSConsignment?.LoadingBaseportSPSLocation?.Name})`
                        : ""}
                    </td>
                    <td style={tableTwo}>
                      {data.entry_point || ""}
                      {!_.isEmpty(countries) &&
                        !_.isEmpty(data?.transit_countries)
                        ? ", " +
                        t("fss.transit") +
                        ": " +
                        displayCountries(data?.transit_countries)
                          ?.map((country) => country?.name_en || "")
                          .join(", ")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th style={tableTwo}>{t("fss.seals")}</th>
                    <th style={tableTwo}>{t("fss.dist")}</th>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{distMarks ? distMarks : ""}</td>
                  </tr>
                  <tr>
                    <th
                      colSpan={2}
                      style={{ textAlign: "center", background: "#c4c7c7" }}
                      className="th-heading"
                    >
                      II. {t("fss.comm")}
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {data?.products?.map((pro, idx) => (
                        <table
                          key={idx}
                          style={{ marginBottom: "2px", width: "100%" }}
                          className="product-table"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{ width: "12px", background: "#c4c7c7" }}
                                className="th-heading"
                              >
                                {idx + 1}
                              </th>
                              <th>{t("fss.description")}</th>
                              <th>{t("fss.sname")}</th>
                              <th>{t("fss.amount")}</th>
                              <th>{t("fss.pack")}</th>
                              <th>{t("fss.dist")}</th>
                              <th>{t("fss.origin")}</th>
                              <th>{t("fss.permit")}</th>
                              <th>{t("fss.date")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                colSpan={2}
                                style={{ whiteSpace: "break-spaces" }}
                              >
                                {pro.name + " " + pro.hs_code}
                                <br />
                                {productType
                                  ? data?.exporter_country != 106
                                    ? t("fss.type") + `: ${productType}` + "\n"
                                    : t("fss.use") + ": " + productType + "\n"
                                  : ""}
                                {condition
                                  ? t("fss.condition") + `: ${condition}\n`
                                  : ""}
                                {!itemIsArray
                                  ? data?.api_data?.SPSConsignment
                                    ?.IncludedSPSConsignmentItem
                                    ?.IncludedSPSTradeLineItem?.IntendedUse
                                    ? data?.exporter_country != 106
                                      ? t("fss.use") +
                                      ": " +
                                      data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem
                                        ?.IntendedUse["#text"]
                                      : t("fss.type") +
                                      `: ` +
                                      data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem
                                        ?.IntendedUse["#text"]
                                    : ""
                                  : data?.api_data?.SPSConsignment
                                    ?.IncludedSPSConsignmentItem
                                    ?.IncludedSPSTradeLineItem[0]?.IntendedUse
                                    ? data?.exporter_country != 106
                                      ? t("fss.use") +
                                      ": " +
                                      data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem[0]
                                        ?.IntendedUse["#text"]
                                      : t("fss.type") +
                                      `: ` +
                                      data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem[0]
                                        ?.IntendedUse["#text"]
                                    : ""}
                                {itemParentIsArray
                                  ? data?.api_data?.SPSConsignment
                                    ?.IncludedSPSConsignmentItem[idx]
                                    ?.IncludedSPSTradeLineItem?.IntendedUse
                                    ? t("fss.use") +
                                    ": " +
                                    data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem[idx]
                                      ?.IncludedSPSTradeLineItem?.IntendedUse[
                                    "#text"
                                    ]
                                    : ""
                                  : ""}
                              </td>
                              <td>{pro.botanic_name}</td>
                              <td style={{ whiteSpace: "pre-line" }}>
                                {/* {pro.net_quantity} {pro.net_quantity_unit} */}
                                {pro.gross_quantity
                                  ? pro.net_quantity == pro.gross_quantity
                                    ? pro.net_quantity.replace(/\.000$/, "") +
                                    " " +
                                    pro.net_quantity_unit
                                    : t("fss.net") +
                                    ": " +
                                    pro.net_quantity.replace(/\.000$/, "") +
                                    " " +
                                    pro.net_quantity_unit +
                                    `\n` +
                                    t("fss.gross") +
                                    ": " +
                                    pro.gross_quantity.replace(/\.000$/, "") +
                                    " " +
                                    pro.gross_quantity_unit
                                  : pro.net_quantity
                                    ? pro.net_quantity.replace(/\.000$/, "") +
                                    " " +
                                    pro.net_quantity_unit
                                    : ""}
                                {pro.net_weight?.value
                                  ? pro.gross_weight?.value
                                    ? pro.net_weight?.value ==
                                      pro.gross_weight?.value
                                      ? pro.net_weight?.value?.replace(
                                        /\.000$/,
                                        ""
                                      ) +
                                      " " +
                                      pro.net_weight?.unit
                                      : "Net weight: " +
                                      pro.net_weight?.value.replace(
                                        /\.000$/,
                                        ""
                                      ) +
                                      " " +
                                      pro.net_weight?.unit +
                                      `\n` +
                                      "Gross weight: " +
                                      pro.gross_weight?.value.replace(
                                        /\.000$/,
                                        ""
                                      ) +
                                      " " +
                                      pro.gross_weight?.unit
                                    : pro.net_weight?.value?.replace(
                                      /\.000$/,
                                      ""
                                    ) +
                                    " " +
                                    pro.net_weight?.unit
                                  : ""}
                              </td>
                              <td>
                                {/* {pro.package_quantity
                                    ? pro.package_quantity +
                                      ", " +
                                      pro.package_quantity_unit
                                    : ""} */}
                                {/* {data?.api_data?.SPSConsignment
                                    ?.IncludedSPSConsignmentItem
                                    ?.IncludedSPSTradeLineItem
                                    ?.PhysicalSPSPackage &&
                                  data?.api_data?.SPSConsignment
                                    ?.IncludedSPSConsignmentItem
                                    ?.IncludedSPSTradeLineItem?.PhysicalSPSPackage
                                    ?.ItemQuantity +
                                    " " +
                                    data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage?.TypeCode["@name"]
                                    ? data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem
                                        ?.PhysicalSPSPackage?.TypeCode["@name"]
                                    : data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem
                                        ?.PhysicalSPSPackage?.TypeCode} */}
                                {/* {(data?.api_data?.SPSConsignment
                                    ?.IncludedSPSConsignmentItem
                                    ?.IncludedSPSTradeLineItem
                                    ?.PhysicalSPSPackage &&
                                    data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage?.ItemQuantity) ||
                                    ""} */}
                                {!_.isEmpty(pro.physical_packages)
                                  ? pro.physical_packages[0].amount.replace(
                                    /\.000$/,
                                    ""
                                  )
                                  : ""}
                                {pro.physical_packages[0]?.unit
                                  ? " " + pro.physical_packages[0].unit
                                  : ""}
                                {_.isEmpty(pro.physical_packages)
                                  ? pro.sps_notes?.map((note, idx) => {
                                    if (note.note_type == 9) {
                                      return note.text;
                                    }
                                  })
                                  : ""}
                                {/* {data?.api_data?.SPSConsignment
                                  ?.IncludedSPSConsignmentItem
                                  ?.IncludedSPSTradeLineItem?.PhysicalSPSPackage
                                  ? typeof data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage?.ItemQuantity ==
                                    "object"
                                    ? data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem
                                        ?.PhysicalSPSPackage?.ItemQuantity[
                                        "#text"
                                      ] +
                                      " " +
                                      data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem
                                        ?.PhysicalSPSPackage?.ItemQuantity[
                                        "@unitCode"
                                      ]
                                    : data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem
                                        ?.IncludedSPSTradeLineItem
                                        ?.PhysicalSPSPackage?.ItemQuantity
                                  : ""}
                                {data?.api_data?.SPSConsignment
                                  ?.IncludedSPSConsignmentItem
                                  ?.IncludedSPSTradeLineItem?.PhysicalSPSPackage
                                  ?.TypeCode["@name"]
                                  ? " " +
                                    data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage?.TypeCode["@name"]
                                  : typeof data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage?.TypeCode == "object"
                                  ? ""
                                  : data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage?.TypeCode
                                  ? " " +
                                    data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage?.TypeCode
                                  : ""}
                                {!itemIsArray && packagingInfo}
                                {itemIsArray &&
                                  data?.api_data?.SPSConsignment
                                    ?.IncludedSPSConsignmentItem
                                    ?.IncludedSPSTradeLineItem[idx]
                                    ?.PhysicalSPSPackage?.ItemQuantity +
                                    " " +
                                    data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem
                                      ?.IncludedSPSTradeLineItem[idx]
                                      ?.PhysicalSPSPackage?.TypeCode["@name"]}
                                {itemParentIsArray
                                  ? data?.api_data?.SPSConsignment
                                      ?.IncludedSPSConsignmentItem[idx]
                                      ?.IncludedSPSTradeLineItem
                                      ?.PhysicalSPSPackage
                                    ? typeof data?.api_data?.SPSConsignment
                                        ?.IncludedSPSConsignmentItem[idx]
                                        ?.IncludedSPSTradeLineItem
                                        ?.PhysicalSPSPackage?.ItemQuantity ==
                                      "object"
                                      ? data?.api_data?.SPSConsignment
                                          ?.IncludedSPSConsignmentItem[idx]
                                          ?.IncludedSPSTradeLineItem
                                          ?.PhysicalSPSPackage?.ItemQuantity[
                                          "#text"
                                        ] +
                                        " " +
                                        data?.api_data?.SPSConsignment
                                          ?.IncludedSPSConsignmentItem[idx]
                                          ?.IncludedSPSTradeLineItem
                                          ?.PhysicalSPSPackage?.ItemQuantity[
                                          "@unitCode"
                                        ]
                                      : ""
                                    : ""
                                  : ""} */}
                              </td>
                              <td>
                                {!_.isEmpty(pro.sps_notes)
                                  ? pro.sps_notes?.map((sps, idx) => {
                                    if (sps.note_type == 7) {
                                      return sps.text;
                                    }
                                  })
                                  : ""}
                              </td>
                              <td>
                                {
                                  countries?.filter(
                                    (c) => c.id == pro.manufactured_country
                                  )[0]?.name_en
                                }
                              </td>
                              <td>
                                {spsImpNum ? (
                                  <a
                                    href={`https://efito.uz/ikr/${spsImpNum}/download`}
                                    target="_blank"
                                  >
                                    {spsImpNum}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {moment(data.given_date).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>{t("fss.declare")}</th>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ whiteSpace: "wrap" }}>
                      {data.extra_info ? data.extra_info + "\n" : ""}
                      {/* {data?.sps_notes?.map((nt, idx) => {
                        if (nt.note_type == 1) {
                          if (nt.text != "NULL") {
                            return (
                              <p>
                                {nt.text}
                                <br />
                              </p>
                            );
                          }
                        }
                      })} */}
                      {/* {data?.sps_notes
                        ?.filter((nt) => {
                          console.log(nt.id);
                          return nt.note_type == 6;
                        })
                        ?.sort((a, b) => b.id - a.id)[0]?.text || ""} */}
                      {replacedCertContent ? replacedCertContent : ""}
                      {/* {adrpn ? <br /> : ""} */}
                      {replacedCertContent ? <br /> : ""}
                      {adrpn ? adrpnContent : ""}
                      {!adrpn && slicedAdrpn ? adrpnContent : ""}
                      {!_.isEmpty(adtlil)
                        ? adtlil.map((lil, idx) => {
                          return <div key={idx}>{lil}</div>;
                        })
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>{t("fss.treat")}</th>
                  </tr>

                  <tr>
                    <td colSpan={2}>{treatment != "NULL" && treatment}</td>
                  </tr>

                  <tr>
                    <th
                      colSpan={2}
                      style={{ textAlign: "center", background: "#c4c7c7" }}
                      className="th-heading"
                    >
                      III. {t("fss.certify")}
                    </th>
                  </tr>
                </tbody>
              </table>
              <table
                style={{
                  width: "auto",
                  marginLeft: "23px",
                  width: "calc(100% - 23px)",
                }}
              >
                <tbody>
                  {reexport && (
                    <tr>
                      <td colSpan={2} style={{ borderTop: "none" }}>
                        {reexport}
                      </td>
                    </tr>
                  )}
                  {spsClause && (
                    <tr>
                      <td colSpan={2} style={{ borderTop: "none" }}>
                        {spsClause}
                      </td>
                    </tr>
                  )}
                  {spsFinContent && (
                    <tr>
                      <td colSpan={2} style={{ borderTop: "none" }}>
                        {spsFinContent}
                      </td>
                    </tr>
                  )}
                  {description && (
                    <tr>
                      <td colSpan={2} style={{ borderTop: "none" }}>
                        {description}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td style={tableTwo}>
                      <strong>{t("fss.issue")}:</strong>{" "}
                      {
                        data?.api_data?.SPSExchangedDocument
                          ?.SignatorySPSAuthentication?.IssueSPSLocation?.Name
                      }
                    </td>
                    <td style={tableTwo}>
                      <strong>{t("fss.officer")}: </strong>
                      {data.inspector_name || ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={tableTwo}>
                      <strong>{t("fss.date")}:</strong>{" "}
                      {
                        // data?.api_data?.SPSExchangedDocument?.IssueDateTime
                        //   ?.DateTimeString
                        noteTypeDate
                          ? noteTypeDate
                          : typeof data?.api_data?.SPSExchangedDocument
                            ?.IssueDateTime?.DateTimeString == "object"
                            ? data?.api_data?.SPSExchangedDocument?.IssueDateTime
                              ?.DateTimeString["#text"]
                            : data?.api_data?.SPSExchangedDocument?.IssueDateTime
                              ?.DateTimeString
                      }
                      {/* {moment(data.given_date).format("DD-MM-YYYY")} */}
                    </td>
                    <td style={tableTwo}>
                      <strong>{t("fss.sign")}:</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default PrintableComponentNewUz;
