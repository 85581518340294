import Agro from "./agro/Agro";
import Analysis from "./agro/Analysis";
import Biolabs from "./agro/Biolabs";
import Clinics from "./agro/Clinics";
import ExportCountry from "./agro/ExportCountry";
import ExportProduct from "./agro/ExportProduct";
import Minerals from "./agro/Minerals";
import Packaging from "./agro/Packaging";
import Recs from "./agro/Recs";
import Akd from "./akd/Akd";
import Balance from "./balance/Balance";
import Comparison from "./comparison/comparison";
import ExportFcc from "./export-fcc/ExportFcc";
import Fumigation from "./fumigation/Fumigation";
import Guvohnoma from "./guvohnoma";
import Help from "./help/Help";
import InnerFcc from "./inner-fcc/InnerFcc";
import Instruction from "./instruction/Instruction";
import Invoice from "./invoice/Invoice";
import Kr from "./kr/Kr";
import Applications from "./lab/applications";
import Export from "./lab/export";
import Field from "./lab/field";
import Import from "./lab/import";
import Notification from "./notification";
import { SeedExport } from "./seed-export";
import ActComparison from "./special";
import ExportFss from "./special/ExportFss";

const pageList = [
  // {
  //   path: "/act/seed-export",
  //   exact: true,
  //   component: SeedExportAct,
  // },
  {
    path: "/local-fsc",
    exact: true,
    component: InnerFcc,
  },
  {
    path: "/local-fsc",
    exact: true,
    component: InnerFcc,
  },
  {
    path: "/seed-export",
    exact: true,
    component: SeedExport,
  },
  {
    path: "/kr",
    exact: true,
    component: Kr,
  },
  {
    path: "/akd",
    exact: true,
    component: Akd,
  },
  {
    path: "/export-fsc",
    exact: true,
    component: ExportFcc,
  },
  {
    path: "/fumigation",
    exact: true,
    component: Fumigation,
  },
  {
    path: "/invoice",
    exact: true,
    component: Invoice,
  },
  {
    path: "/instruction",
    exact: true,
    component: Instruction,
  },
  {
    path: "/balance",
    exact: true,
    component: Balance,
  },

  {
    path: "/registration/certificates",
    exact: true,
    component: Guvohnoma,
  },
  {
    path: "/help",
    exact: true,
    component: Help,
  },
  {
    path: "/lab/import",
    exact: true,
    component: Import,
  },
  {
    path: "/lab/export",
    exact: true,
    component: Export,
  },
  {
    path: "/lab/field",
    exact: true,
    component: Field,
  },
  {
    path: "/lab/applications",
    exact: true,
    component: Applications,
  },
  {
    path: "/agrohelp",
    exact: true,
    component: Agro,
  },
  {
    path: "/agrohelp/3",
    exact: true,
    component: Biolabs,
  },
  {
    path: "/agrohelp/2",
    exact: true,
    component: Clinics,
  },
  {
    path: "/agrohelp/7",
    exact: true,
    component: Analysis,
  },
  {
    path: "/agrohelp/1",
    exact: true,
    component: Minerals,
  },
  {
    path: "/agrohelp/4",
    exact: true,
    component: Packaging,
  },
  {
    path: "/agrohelp/5",
    exact: true,
    component: ExportCountry,
  },
  {
    path: "/agrohelp/8",
    exact: true,
    component: ExportProduct,
  },
  {
    path: "/agrohelp/6",
    exact: true,
    component: Recs,
  },
  {
    path: "/act-invoice",
    exact: true,
    component: Comparison,
  },
  {
    path: "/efito/act-invoice",
    exact: true,
    component: ActComparison,
  },

  {
    path: "/notifications",
    exact: true,
    component: Notification,
  },
  {
    path: "/efito/import-fss/print",
    exact: true,
    component: ExportFss,
  },
  {
    path: "/efito/import-fss/print",
    exact: true,
    component: ExportFss,
  },
];

export default pageList;
