import React from "react";
import { useMyForm } from "../forms/usemyForm";
import { useFieldArray } from "react-hook-form";
import { FormInput } from "../../../../components/formComponents/input";
import { Button, Col, Flex, Row, Tooltip, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AddButton } from "./components/addButton";
import { Hscodes } from "./components/hcodes";
import { FormSelect } from "../../../../components/formComponents/select";
import { UNITS, UNITS_PACKAGING } from "../useModal";
import { ExcelImport } from "../excel-import/excel";
const { Title } = Typography;

export const One = () => {
  const { t, props, watch } = useMyForm();

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: "productsOne",
  });
  return (
    <>
      <Col span={24}>
        <Flex style={{ width: "100%", justifyContent: "space-between" }}>
          <Title style={{ marginBottom: 0 }} level={4}>
            {t("inner-fcc.prod")}
          </Title>
          <ExcelImport />
        </Flex>
      </Col>
      {fields.map((field, index) => (
        <Col
          key={field.id}
          span={24}
          style={{
            padding: "8px",
            borderRadius: "8px",
            border: "1px solid #f1f1f1",
          }}
        >
          <Row gutter={[16, 8]} align="bottom">
            <Col span={24} md={6}>
              <FormInput
                control={props.control}
                rules={
                  fields?.length - 1 > index || index === 0
                    ? { ...props.rules }
                    : undefined
                }
                disabled={fields?.length - 1 > index}
                label={t("lab.product-name")}
                name={`productsOne.${index}.name`}
              />
            </Col>

            <Col span={24} md={watch("box_type") === 1 ? 6 : 5}>
              <Hscodes
                fieldName="productsOne"
                disabled={fields?.length - 1 > index}
                rul={
                  fields?.length - 1 > index || index === 0
                    ? {
                        ...props,
                        rules: {
                          required: {
                            value: true,
                            message: "inner-fcc.error",
                          },

                          maxLength: { value: 10, message: "inner-fcc.error" },
                        },
                      }
                    : {
                        ...props.control,
                        rules: {
                          required: {
                            value: true,
                            message: "inner-fcc.error",
                          },
                        },
                      }
                }
                ind={index}
              />
            </Col>

            <Col span={24} md={watch("box_type") === 1 ? 6 : 4}>
              <FormInput
                control={props.control}
                rules={
                  fields?.length - 1 > index || index === 0
                    ? { ...props.rules }
                    : undefined
                }
                disabled={fields?.length - 1 > index}
                type="number"
                label={t("inner-fcc.quan")}
                name={`productsOne.${index}.quantity`}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
            </Col>

            <Col span={24} md={watch("box_type") === 1 ? 6 : 3}>
              <FormSelect
                control={props.control}
                rules={
                  fields?.length - 1 > index || index === 0
                    ? { ...props.rules }
                    : undefined
                }
                disabled={fields?.length - 1 > index}
                label={t("unit")}
                placeholder={t("inner-fcc.tanla")}
                name={`productsOne.${index}.unit_code`}
                options={UNITS}
              />
            </Col>
            {watch("box_type") === 1 && (
              <>
                <Col span={24} md={6}>
                  <FormInput
                    control={props.control}
                    rules={
                      fields?.length - 1 > index || index === 0
                        ? { ...props.rules }
                        : undefined
                    }
                    disabled={fields?.length - 1 > index}
                    type="number"
                    label={t("packaging quant")}
                    name={`productsOne.${index}.packaging_quantity`}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                </Col>

                <Col span={24} md={6}>
                  <FormSelect
                    control={props.control}
                    rules={
                      fields?.length - 1 > index || index === 0
                        ? { ...props.rules }
                        : undefined
                    }
                    disabled={fields?.length - 1 > index}
                    label={t("packaging unit")}
                    placeholder={t("inner-fcc.tanla")}
                    name={`productsOne.${index}.packaging_unit_code`}
                    options={UNITS_PACKAGING}
                  />
                </Col>

                <Col span={24} md={6}>
                  <FormInput
                    control={props.control}
                    rules={
                      fields?.length - 1 > index || index === 0
                        ? { ...props.rules }
                        : undefined
                    }
                    disabled={fields?.length - 1 > index}
                    type="number"
                    label={t("packaging kalib")}
                    name={`productsOne.${index}.calibration`}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                </Col>
              </>
            )}

            <Col span={24} md={6} style={{ textAlign: "right" }}>
              {fields?.length - 1 > index ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "16px",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "calc(100% - 64px)" }}>
                    <FormInput
                      style={{
                        textTransform: "uppercase",
                      }}
                      disabled={true}
                      label={t("lab.extra-info")}
                      placeholder="-"
                      name={`productsOne.${index}.transport_number`}
                    />
                  </div>

                  <Tooltip placement="topRight" title={t("lab.delete")}>
                    <Button
                      onClick={() => remove(index)}
                      type="text"
                      danger
                      ghost
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </div>
              ) : (
                <AddButton
                  append={append}
                  index={index}
                  fieldName="productsOne"
                />
              )}
            </Col>
          </Row>
        </Col>
      ))}
    </>
  );
};
