import { ArrowRightOutlined, CopyOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Flex, Modal, Row } from "antd";
import { FormProvider } from "antd/es/form/context";
import React from "react";
import { useTranslation } from "react-i18next";
import "react-indiana-drag-scroll/dist/style.css";
import { FormInput } from "../../../components/formComponents/input";
import { FormSelect } from "../../../components/formComponents/select";
import { Container } from "./style";
import { useTransfer } from "./useTransfer";
export const TransferButton = ({ services, getPaymentLists }) => {
  const { t } = useTranslation();
  const {
    form,
    isModalOpen,
    props,
    handleCancel,
    showModal,
    lang,
    receiver,
    sender,
    isLoading,
    data,
    setIsModalOpen,
    onSubmit,
    mutateLoading,
  } = useTransfer({ getPaymentLists });

  return (
    <>
      <button
        onClick={showModal}
        className="button-active"
        style={{
          paddingLeft: "8px",
          paddingRight: "8px",
          minWidth: "250px",
          position: "relative",
        }}
        type="primary"
      >
        <div className="badge-new">
          <Badge count={"new"} style={{ backgroundColor: "#f40003" }} />
        </div>
        {t("change_payment")}
      </button>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        className="my-modal"
        width={600}
        destroyOnClose={true}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <FormProvider {...form}>
          <Container onSubmit={form.handleSubmit(onSubmit)}>
            <Row gutter={[12, 12]} align="middle" justify="space-between">
              <Col span={24}>
                <h1 style={{ margin: 0, fontSize: "28px", color: "#036323" }}>
                  {t("change_payment")}
                </h1>
              </Col>
              <Col span={22} md={11}>
                <Flex gap="8px">
                  <FormSelect
                    {...props}
                    label={t("select_service_type")}
                    placeholder={t("inner-fcc.tanla")}
                    name="sending_invoice_number"
                    options={data
                      ?.map((d) => ({
                        value: d.number,
                        label: services?.find((s) => d.service_type === s.id)
                          ?.value,
                      }))
                      ?.filter(
                        (f) =>
                          f.value !== form.watch("receiving_invoice_number")
                      )}
                    loading={isLoading}
                  />
                  <span style={{ paddingTop: "34px" }}>
                    {t("from_payment")}
                  </span>
                </Flex>

                {form.watch("sending_invoice_number") && (
                  <div className="card-wrapper">
                    <span className="invoice-name">
                      {
                        services?.find((s) => sender.service_type === s.id)
                          ?.value
                      }
                      <br />
                      {sender.service_type == 3
                        ? sender.region_name[`${lang}`]
                        : ""}
                    </span>

                    <div className="card-amount">
                      {sender?.balance == null ? (
                        "***"
                      ) : (
                        <>
                          <span>
                            {sender?.balance?.toLocaleString("en-US")}
                          </span>
                          <span>{t("invoice.sum")}</span>
                        </>
                      )}
                    </div>

                    <div className="card-bottom">
                      <div className="invoice-num">
                        <span>{t("invoice.invoice-num")}</span>
                        <span>
                          {sender?.number}{" "}
                          <span
                            className="mobile"
                            onClick={() => {
                              navigator.clipboard.writeText(sender?.number);
                            }}
                          >
                            <CopyOutlined />
                          </span>
                        </span>
                      </div>

                      <div className="invoice-date">
                        <span>{t("invoice.date")}</span>
                        <span>{sender?.given_date}</span>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col
                span={24}
                md={2}
                className="icon-arrow-payment"
                style={{
                  textAlign: "center",
                }}
              >
                <ArrowRightOutlined />
              </Col>
              <Col span={22} md={11}>
                <Flex gap="8px" style={{ paddingRight: "10px" }}>
                  <FormSelect
                    {...props}
                    label={t("select_service_type")}
                    placeholder={t("inner-fcc.tanla")}
                    name="receiving_invoice_number"
                    options={data
                      ?.map((d) => ({
                        value: d.number,
                        label: services?.find((s) => d.service_type === s.id)
                          ?.value,
                      }))
                      ?.filter(
                        (f) => f.value !== form.watch("sending_invoice_number")
                      )}
                    loading={isLoading}
                  />

                  <span style={{ paddingTop: "34px" }}>{t("to_payment")}</span>
                </Flex>

                {form.watch("receiving_invoice_number") && (
                  <div className="card-wrapper">
                    <span className="invoice-name">
                      {
                        services?.find((s) => receiver.service_type === s.id)
                          ?.value
                      }
                      <br />
                      {receiver.service_type == 3
                        ? receiver.region_name[`${lang}`]
                        : ""}
                    </span>

                    <div className="card-amount">
                      {receiver?.balance == null ? (
                        "***"
                      ) : (
                        <>
                          <span>
                            {receiver?.balance?.toLocaleString("en-US")}
                          </span>
                          <span>{t("invoice.sum")}</span>
                        </>
                      )}
                    </div>

                    <div className="card-bottom">
                      <div className="invoice-num">
                        <span>{t("invoice.invoice-num")}</span>
                        <span>
                          {receiver?.number}{" "}
                          <span
                            className="mobile"
                            onClick={() => {
                              navigator.clipboard.writeText(receiver?.number);
                            }}
                          >
                            <CopyOutlined />
                          </span>
                        </span>
                      </div>

                      <div className="invoice-date">
                        <span>{t("invoice.date")}</span>
                        <span>{receiver?.given_date}</span>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col span={24} md={12}>
                <FormInput
                  control={form.control}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "inner-fcc.error",
                    },
                    min: {
                      value: 1,
                      message: "min: 1",
                    },
                    max: {
                      value: sender?.balance ?? 0,
                      message: "max: " + sender?.balance ?? 0,
                    },
                    validate: (value) =>
                      Number.isInteger(Number(value)) || t("money_notif"),
                  }}
                  label={t("sended_money")}
                  name="amount"
                  type="number"
                />
              </Col>
              <Col
                span={24}
                md={12}
                style={{ marginTop: "auto", textAlign: "right" }}
              >
                <Button
                  loading={mutateLoading}
                  htmlType="submit"
                  type="primary"
                >
                  {t("confirm_payment_change")}
                </Button>
              </Col>
            </Row>
          </Container>
        </FormProvider>
      </Modal>
    </>
  );
};
