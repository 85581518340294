import React, { useEffect, useRef, useState } from "react";
import "./expertise.css";
// import logo from "../../assets/images/logo.svg";
import labIcon from "../../assets/images/lab-icon.png";
import logo from "../../assets/images/main-logo.webp";
import ReactToPrint from "react-to-print";
import queryString from "query-string";
import { requestPublic } from "../../helpers/createRequest";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import _ from "lodash";
import QRCode from "react-qr-code";
import { API_VPN_URL, BASE_URL } from "../../constants/variables";

const LabSample = (props) => {
  const componentRef = useRef();
  const [sample, setSample] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const queryParams = queryString.parse(window.location.search);
  const number = queryParams.number;
  const domainName = window.location.hostname;
  const fetchData = async () => {
    await requestPublic
      .get(`api/lab-import-sample/?number=${number}`, {
        baseURL: domainName?.includes("10.0.229.30") ? API_VPN_URL : BASE_URL,
      })
      .then((res) => {
        if (res.data) {
          setSample(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.warning("Natija topilmadi!");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        const err = e?.response?.data ?? e;
        toast.error(JSON.stringify(err));
      });
  };

  useEffect(() => {
    if (number) {
      fetchData();
    } else {
      setIsLoading(false);
      toast.error("Xatolik!");
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        sample && (
          <div className="file-container" style={{ paddingBottom: "15px" }}>
            <div className="down-btn1">
              <ReactToPrint
                trigger={() => <button className="btn-icon">Chop etish</button>}
                content={() => componentRef.current}
                pageStyle="@page { margin: 5px !important;}"
              />
              {/* <span className="tooltip">{t("invoice.print")}</span> */}
            </div>
            <page
              size="A4"
              style={{
                width: "21cm",
                height: "29.7cm",
                fontFamily: "serif",
                position: "relative",
                paddingBottom: "105px",
              }}
              ref={componentRef}
            >
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "17px",
                  lineHeight: "25px",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "13%" }}>
                  <img
                    src={logo}
                    style={{ width: "100%", marginTop: "0px", height: "auto" }}
                  />
                </div>
                <div
                  style={{
                    width: "68%",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Хорижий мамлакатлардан олиб кирилаётган карантин остидаги
                  маҳсулотларни Фитосанитария лаборатория (ФЛ) таҳлилидан
                  ўтказиш учун намуна олиш тўғрисида <br></br>Ёрлиқ №
                  <span
                    style={{
                      width: "200px",
                      height: "25px",
                      borderBottom: "1px solid #000",
                      textAlign: "center",
                      display: "inline-block",
                    }}
                  >
                    {sample?.number}
                  </span>
                </div>

                <div style={{ width: "13%" }}>
                  {/* <img
                src={logo}
                style={{ width: "100%", marginTop: "0px", height: "auto" }}
              /> */}
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      // border: "1px solid #000",
                      margin: "auto",
                    }}
                  >
                    <QRCode
                      size={80}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={`https://cabinet.karantin.uz/lab/import-sample?number=${sample?.number}`}
                      viewBox={`0 0 80 80`}
                    />
                  </div>
                </div>
              </div>
              <table
                className="lab-print-table custom"
                style={{ marginTop: "25px" }}
              >
                <thead>
                  <tr>
                    <th rowSpan={2}>Т/р</th>
                    <th colSpan={4}>
                      Хорижий мамлакатлардан олиб кирилган карантин остидаги
                      маҳсулотнинг
                    </th>
                    <th colSpan={2}>
                      *ФЛ таҳлилидан ўтказиш учун олинган намуна миқдори
                    </th>
                  </tr>
                  <tr>
                    <th style={{ width: "42%" }}>Номи</th>
                    <th style={{ width: "20%" }}>ТИФ ТН бўйича коди</th>
                    <th style={{ width: "10%" }}>Миқдори</th>
                    <th style={{ width: "7%" }}>Ўлчов бирлиги</th>
                    <th style={{ width: "10%" }}>Миқдори</th>
                    <th style={{ width: "7%" }}>Ўлчов бирлиги</th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(sample?.products)
                    ? sample?.products?.map((pro, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{pro.name}</td>
                            <td>{pro.hs_code}</td>
                            <td>{pro.amount}</td>
                            <td>{pro.unit}</td>
                            <td>{pro.sample_amount}</td>
                            <td>{pro.sample_unit}</td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <table
                className="lab-print-table custom"
                style={{ marginTop: "20px" }}
              >
                <thead>
                  <tr>
                    <th colSpan={2}>
                      Транспорт бирлигини очиш тўғрисидаги далолатнома
                    </th>
                    <th colSpan={2}>Транспорт</th>
                    <th colSpan={2}>Фитосанитария сертификати</th>
                    <th colSpan={2}>Карантин рухсатномаси</th>
                  </tr>
                  <tr>
                    <th style={{ width: "15%" }}>Рақами</th>
                    <th style={{ width: "10%" }}>сана</th>
                    <th style={{ width: "12%" }}>Тури</th>
                    <th>Давлат рақами</th>
                    <th>Рақами</th>
                    <th style={{ width: "10%" }}>Сана</th>
                    <th>Рақами</th>
                    <th>Сана</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{sample?.tbotd_number}</td>
                    <td>{sample?.tbotd_given_date}</td>
                    <td>{sample?.transport_method}</td>
                    <td>{sample?.transport_number}</td>
                    <td>{sample?.fss_number}</td>
                    <td>{sample?.fss_given_date}</td>
                    <td>{sample?.ikr_number}</td>
                    <td>{sample?.ikr_given_date}</td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                <span>
                  <strong>Намуна олинган сана</strong>
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "150px",
                    borderBottom: "1px solid #000",
                    textAlign: "center",
                  }}
                >
                  {sample?.given_date}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                <span>
                  <strong>ФЛ томонидан намуна қабул қилинган сана</strong>
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "150px",
                    borderBottom: "1px solid #000",
                    textAlign: "center",
                  }}
                >
                  {sample?.received_date}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  marginTop: "20px",
                  alignItems: "center",
                }}
              >
                <span>
                  <strong>Давлат инспектори:</strong>
                </span>
                <div
                  style={{
                    // display: "inline-block",
                    width: "300px",
                    borderBottom: "1px solid #000",
                    position: "relative",
                    // textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    {sample?.sampling_inspector || ""}
                  </span>
                  <label
                    style={{
                      position: "absolute",
                      bottom: "-18px",
                      left: "0",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    (Ф.И.Ш)
                  </label>
                </div>
              </div>

              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "20px",
                  right: "20px",
                  backgroundColor: "white",
                }}
              >
                <p
                  style={{
                    fontStyle: "italic",
                    fontSize: "15px",
                    textAlign: "justify",
                    opacity: "0.9",
                  }}
                >
                  * Давлат инспектори намунани танлаб олиш услуби ГОСТ
                  12430-2019: “Карантин фитосанитария текшируви ва лаборатория
                  тадқиқотлари пайтида карантин остидаги маҳсулотлардан намуна
                  олиш усуллари ва меъёрлари” Давлатлараро стандартининг бўйича
                  намуналар танлаб, ГОСТ 12430-2019:нинг А ва Б иловаларида
                  келтирилган Лаборатория тадқиқотлари учун карантин остидаги
                  маҳсулотларнинг ўртача намуналари ҳажми бўйича намуналар
                  олинган.
                </p>
              </div>
            </page>
          </div>
        )
      )}
    </>
  );
};

export default LabSample;
