import { PIN_LOGIN, PIN_LOGOUT } from "../types/Types";

export const userPinLogin = (data) => {
  console.log(data);
  window.localStorage.setItem("pin_data", JSON.stringify(data));

  return {
    type: PIN_LOGIN,
    payload: data,
  };
};

export const userPinLogout = () => {
  window.localStorage.removeItem("pin_data");

  return {
    type: PIN_LOGOUT,
  };
};
