import React from "react";

const Fss = () => {
  return (
    <>
      <p>
        <strong>1. Умумий қоидалар</strong>
      </p>
      <p>
        1. Ўзбекистон Республикаси Фуқаролик кодексининг 370-моддасига мувофиқ
        агар қуйида кўрсатилган шартлар қабул қилиниб, хизматлар учун тўлов
        амалга оширилса, қабул қилувчи юридик ёки жисмоний шахс Ариза берувчига
        айланади.
      </p>
      <p>
        2. Оферта шартномасини тўлиқ ва сўзсиз қабул қилиш (таклиф маъқулланиш)
        пайтида Агентлик томонидан Ариза берувчидан фитосанитария ва реэкспорт
        сертификатини олиш учун&nbsp;электрон&nbsp;ариза (кейинги ўринларда -
        ариза) электрон ҳужжат кўринишида қабул қилинади ва фитосанитария
        сертификатини олиш тўғрисидаги ариза кўриб чиқилганлиги учун йиғим
        ундирилади.
      </p>
      <p>
        3. «Электрон ҳужжат айланиши тўғрисида»ги Ўзбекистон Республикаси
        Қонунига мувофиқ электрон ҳужжат шаклидаги ариза қоғоз ҳужжатга тенг
        бўлиб, Вазирлар Маҳкамасининг 2018 йил 29 январдаги 65- сонли қарори
        билан тасдиқланган&nbsp;“Ўсимликлар карантини назоратидаги маҳсулотлар
        учун фитосанитария сертификатларини бериш тартиби тўғрисида
        Низом”нинг&nbsp;2-иловасига мувофиқ маълумотлар батафсил баён этилиши,
        шунингдек Ариза берувчининг электрон рақамли имзоси билан тасдиқланган
        бўлиши лозим.
      </p>
      <p>
        4. Ариза берувчи Оферта шартномаси (кейинги ўринларда - Шартнома)
        шартлари билан танишиб, &nbsp;Фитосанитар сертификатини &nbsp;“Ягона
        дарча” божхона ахборот тизими
        (http://www.&nbsp;singlewindow.uz)&nbsp;манзили бўйича&nbsp;ва&nbsp;Ички
        фитосанитария
        сертификати&nbsp;учун&nbsp;www.karantin.uz&nbsp;веб-сайти&nbsp;орқали&nbsp;электрон
        ариза юборади. Ариза берувчи Шартномада белгиланган йиғим миқдорини
        тўлагандан сўнг унинг аризаси фаоллаштирилади.
      </p>
      <p>
        5. Ариза берувчи Шартномани белгиланган тартибда қабул қилиб, унинг
        матнида келтириб ўтилган барча шартлар билан танишиб чиққанлигини, рози
        бўлганлигини ҳамда тўлиқ ва сўзсиз қабул қилганлигини тасдиқлайди.
      </p>
      <p>
        6. Ариза берувчи ушбу ҳужжатнинг 2-бандида кўрсатилган тартибда уни
        қабул қилади ва унда белгиланган шартлар асосида Шартнома тузилишига
        розилик билдиради.
      </p>
      <p>7. Шартнома&nbsp;чақириб олинмайди.</p>
      <p>
        8. Ўзбекистон Республикаси Фукаролик кодексининг 367-375-моддалари ва
        «Электрон ҳужжат айланиши тўғрисида»ги, «Электрон рақамли имзо
        тўғрисида»га Ўзбекистон Республикаси Қонунларига мувофик Шартномада
        Ариза берувчининг муҳри ва / ёки имзоси талаб этилмайди ҳамда шунга
        асосан ўз юридик кучини саклаб колади.
      </p>
      <p>
        <strong>2. Шартнома предмети</strong>
      </p>
      <p>
        9. Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2018 йил 29 январдаги
        № 65-сонли «Ўзбекистон Республикаси ҳудудини ўсимликлар карантинидаги
        зарарли организмлардан ҳимоя қилиш ва Ўсимликлар карантини соҳасида
        рухсат бериш тартиб-таомилларидан ўтиш тартиби тўғрисидаги низомларни
        тасдиқлаш ҳақида»ги карорига мувофиқ Агентлик аризани кўриб чиқиши учун
        Ариза берувчи куйидаги тартибда йиғим суммасини тўлайди:
      </p>
      <table className="doc-table">
        <thead>
          <tr>
            <th>Т/р</th>
            <th>Номи</th>
            <th>Бир бирлик учун йиғим суммаси</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2}>1.</td>
            <td>Фитосанитар сертификатини бериш тўғрисидаги аризани кўриб чиқиш.</td>
            <td>
              <ul>
                <li>40 кг гача бўлган бир дона жўнатмага (қўл юки) фитосанитария сертификатини олиш учун базавий ҳисоблаш миқдорининг ўн беш фоизи миқдорида;</li>
                <li>500 кг гача юк ортилган бир транспорт бирлиги учун базавий ҳисоблаш миқдорининг йигирма фоизи миқдорида;</li>
                <li>1000 кг гача юк ортилган бир транспорт бирлиги учун базавий ҳисоблаш миқдорининг йигирма беш фоизи миқдорида;</li>
                <li>1000 кг дан ортиқ юк ортилган бир транспорт бирлигига фитосанитария сертификати олиш учун базавий ҳисоблаш миқдорининг бир баробари миқдорида йиғим ундирилади.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Реэкспорт фитосатария сертификати бериш.</td>
            <td>
              <ul>
                <li>1000 кг дан ортиқ юк ортилган бир транспорт бирлигига реэкспорт фитосанитария сертификати учун базавий ҳисоблаш миқдорининг бир баробари миқдорида йиғим ундирилади.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.</td>
            <td>
            Ички фитосанитария сертификати бериш.
            </td>
            <td>
            <ul>
                <li>
                500 кг дан ортиқ юк ортилган бир транспорт бирлигига ички фитосанитария сертификати учун базавий ҳисоблаш миқдорининг ўн фоизи миқдорида йиғим ундирилади.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>3.</td>
            <td>Фитосанитария сертификатларини қайта расмийлаштириш.</td>
            <td>
            <ul>
                <li>
                базавий ҳисоблаш миқдорининг ярми миқдорида
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        
      </table>
      <p>
        10. Тўлов тартиби: ҳар бир &nbsp;бирлиги учун йиғим миқдори 100%
        олдиндан тўланади. Тўлиқ ҳисоб-китоб сертификат расмийлаштириш
        санасидаги базавий ҳисоблаш миқдоридан келиб чиқиб, йиғим ҳисобланади.
      </p>
      <p>
        11. Агар йиғим белгиланган кундан бошлаб беш кун ичида тўланмаса,
        буюртмачининг электрон аризаси бекор қилинади ва уни қабул қилиш амалга
        оширилмаган ҳисобланади.
      </p>
      <p>
        <strong>3. Тарафларнинг мажбуриятлари</strong>
      </p>
      <div>
        <p>
          12. Агентлик аризани кўриб чиқиб, фитосанитар сертификати беришни рад
          этиш тўғрисида қарорни электрон ариза олинган кундан бошлаб 5 иш куни
          ичида ёзма равишда рад этиш мажбуриятини олади. Ариза берувчи берилган
          аризасидан воз кечган ёки ариза кўриб чиқилгандан сўнг фитосанитар
          сертификатини бериш рад этилган тақдирда тўланган йиғим суммаси
          қайтарилмайди.
        </p>
      </div>
      <p>
        Фитосанитар сертификатини беришни рад этиш асослари Ўзбекистон
        Республикаси Вазирлар Маҳкамасининг 2018 йил 29 январдаги «Ўзбекистон
        Республикаси ҳудудини ўсимликлар карантинидаги зарарли организмлардан
        ҳимоя қилиш ва Ўсимликлар карантини соҳасида рухсат бериш
        тартиб-таомилларидан ўтиш тартиби тўғрисидаги низомларни тасдиқлаш
        ҳақида»ги 65-сонли қарорида белгиланган.
      </p>
      <p>
        Рад этиш тўғрисидаги хабарнома Ариза берувчига электрон шаклда, рад этиш
        сабаблари, қонун ҳужжатларининг аниқ нормалари ва ариза берувчи
        кўрсатилган сабабларни бартараф этиб, такроран кўриб чиқиш учун
        ҳужжатларни тақдим этиши мумкин бўлган муддат кўрсатилган ҳолда&nbsp;
        <a href="http://www.singlewindow.uz">http://www.singlewindow.uz</a>{" "}
        ахборот тизими орқали юборилади. Ариза берувчи рад этиш сабабларини
        бартараф этиб, ҳужжатларни такроран кўриб чиқиш учун тақдим этиши мумкин
        бўлган муддат&nbsp;Фитосанитария сертификатини&nbsp;бериш рад
        этилганлиги ҳақидаги хабарнома олинган кундан эътиборан ўн иш кунидан
        кам бўлмаслиги керак.
      </p>
      <p>
        Ариза берувчи фитосанитар сертификатини беришни рад этиш учун асос
        бўлган сабабларни белгиланган муддатда бартараф этган тақдирда,
        ҳужжатлар такроран кўриб чиқиш, фитосанитар сертификати бериш ёки уни
        беришни рад этиш ариза берувчидан рад этиш сабаблари бартараф
        этилганлигини тасдиқловчи тегишли ҳужжатлар олинган кундан эътиборан беш
        иш куни ичида такроран кўриб чиқилади. Ариза такроран кўриб чиқилганлиги
        учун йиғим ундирилмайди.
      </p>
      <div>
        <p>
          13. Агентлик аризадаги маълумотларни ошкор қилмаслик мажбуриятини
          олади. Амалдаги қонунчиликка мувофиқ асосли равишда маълумотни ошкор
          қилиш ҳолатларида мажбурият бузилган деб ҳисобланмайди.
        </p>
      </div>
      <p>
        <strong>4. Коррупцияга қарши қўшимча шартлар</strong>
      </p>
      <p>
        14. Тарафлар оферта тузишда, офертанинг амал қилиш муддатида ва ушбу
        муддат тугаганидан сўнг, оферта билан боғлиқ коррупциявий ҳаракатлар
        содир қилмасликка келишиб оладилар.
      </p>
      <p>
        15. Тарафлар Адлия вазирлиги, Коррупцияга қарши курашиш агентлиги,
        Савдо-саноат палатасининг 2021 йил 12 ноябрдаги 1/4671, 01-19/7284,
        7/01-08-9544-сон қарорига илова билан тасдиқланган “Хўжалик юритувчи
        субъектлар томонидан шартномалар тузишда қўллаш учун тавсивий характерга
        эга бўлган коррупцияга қарши қўшимча шартлар” (кейинги ўринларда -
        коррупцияга қарши қўшимча шартлар)га мувофиқ коррупциянинг олдини олиш
        чораларини тан олади ва уларга риоя этилиши бўйича ҳамкорликни
        таъминлайдилар.
      </p>
      <p>
        16. Ҳар бир тараф оферта тузилган пайтда бевосита ўзи ёки унинг ижроия
        органлари, мансабдор шахслари ва ходимлари томонидан шартнома билан
        боғлиқ муносабатлар юзасидан қонунга хилоф равишда пул, моддий
        қийматликлар берилмаганлигини, шартнома тузилиши эвазига норасмий пул
        ёки бошқа моддий қийматликлар олинишига йўл қўйилмаганлигини, таклиф
        этилмаганлигини, уларни беришга ваъда қилинмаганлигини, шунингдек моддий
        ёки ҳар қандай турдаги имтиёз, устунликлар олинмаганлигини (келажакда бу
        турдаги ҳаракатларни амалга ошириши мумкинлиги ҳақида таассурот
        қолдирилмаганлигини) кафолатлайди.&nbsp;
      </p>
      <p>
        17.&nbsp;Тарафлар, улар томонидан шартнома доирасида жалб қилинган
        шахсларнинг (ёрдамчи пудратчи ташкилотлар, агентлар ва тарафлар назорати
        остидаги бошқа шахсларнинг) юқоридаги ҳаракатларни содир этмаслиги
        юзасидан оқилона чоралар кўради.
      </p>
      <p>
        18. Тарафлар уларнинг назорати остида бўлган ва улар номидан ҳаракат
        қиладиган шахслар томонидан коррупцияга қарши белгиланган мажбуриятлар
        бузилганлиги ҳолатлари ҳақида хабар берилганлиги учун уларга тазйиқ
        ўтказилмаслигини кафолатлайдилар.
      </p>
      <p>
        19. Агар бир тарафга бошқа тарафнинг коррупцияга қарши қўшимча
        шартларнинг 4 ва 5-бандларида белгиланган мажбуриятларнинг бузилишига
        йўл қўйилганлиги маълум бўлиб қолса, иккинчи тарафни бу ҳақда зудлик
        билан хабардор қилиши ва ушбу тарафдан оқилона муддат ичида тегишли
        чоралар кўрилишини ва амалга оширилган ишлар юзасидан унга хабардор
        қилишини талаб қилиши шарт. Тарафнинг талаби бўйича иккинчи тараф
        томонидан қоидабузарликларни бартараф қилиш бўйича оқилона муддат ичида
        тегишли чоралар кўрилмаган ёки кўриб чиқиш натижалари ҳақида хабардор
        қилмаган тақдирда, ушбу тараф зарарни тўлиқ қоплаб беришни талаб қилишга
        ҳақли.&nbsp;
      </p>
      <p>
        <strong>5. Жавобгарлик, низоларни ҳал этиш тартиби</strong>
      </p>
      <p>
        20. Тарафлар Ўзбекистон Республикаси қонунчилигига мувофиқ жавобгар
        бўладилар.
      </p>
      <p>
        21. Шартномани бажариш вақтида форс-мажор ҳолатлари: давлат ҳокимияти
        органларининг ҳаракатлари, ёнғин, сув тошқини, зилзила, бошқа табиий
        офатлар, электр энергиясининг йўқлиги, иш ташлашлар, фуқаролик урушлари,
        тартибсизликлар, шунингдек юқорида санаб ўтилмаган ҳолатлар билан
        чекланмаган ҳар қандай бошқа фавқулодда ҳодисалар юзага келиб, Шартнома
        шартлари бузилса, Агентлик жавобгарликдан озод этилади.
      </p>
      <p>22. Агентлик қуйидагилар учун жавобгар эмас:</p>
      <div>
        <p>
          - Aриза берувчига ўз хизматларидан фойдаланишни таъминлайдиган умумий
          алоқа каналлари ёки хизматларининг сифати учун;
        </p>
        <p>
          - Ариза берувчи томонидан&nbsp;
          <a href="http://www.singlewindow.uz">http://www.singlewindow.uz</a>.
          &nbsp; &nbsp;сайтида оммавий тарзда тақдим этилган маълумотлар учун.
        </p>
      </div>
      <p>
        23. Ариза берувчининг Агентлик томонидан кўрсатилган хизматларга
        эътирози ёки шикоятлари&nbsp;
        <a href="mailto:info@karantin.uz">info@karantin.uz</a>,&nbsp;электрон
        почтаси орқали кўриб чиқиш учун қабул қилинади.
      </p>
      <p>
        24. Низоли вазиятларни кўриб чиқишда Агентлик Ариза берувчидан низога
        доир барча тегишли ҳужжатларни талаб қилишга ҳақли.
      </p>
      <p>
        25. Тарафлар низо ва келишмовчиликлар юзага келганда, судгача низоларни
        ҳал этиш тартибини қўллаш мажбуриятини оладилар. Агар низони судгача ҳал
        қилишнинг имкони бўлмаса, тарафлар судга мурожаат қилиш ҳуқуқига
        эгадирлар.
      </p>
      <p>
        <strong>6. Алоҳида шартлар</strong>
      </p>
      <p>
        26. Ариза берувчи Шартномани қабул қилиш ҳамда бажариш учун зарур бўлган
        барча хуқуқ ва ваколатларга эга.
      </p>
      <p>
        27. Агентлик ушбу Шартноманинг исталган шартларини ўзгартириш ёки
        тўлдириш ҳуқуқини ўз веб-сайтида барча ўзгаришларни эълон қилиш орқали
        сақлаб қолади.
      </p>
      <p>
        28. Шартнома тўлов амалга оширилган кундан бошлаб ___________гача амал
        қилади.
      </p>
      <p>
        29. Ушбу Шартномада назарда тутилмаган барча масалалар Ўзбекистон
        Республикаси қонунчилиги меъёрлари асосида тартибга солинади.
      </p>
    </>
  );
};

export default Fss;
