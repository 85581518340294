import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditModal from "./EditModal";
import InnerProductsModal from "./InnerProductsModal";
import ProcessModal from "./ProcessModal";

const OneRow = (props) => {
  const { t } = useTranslation();
  const { item, index, date, allRegions } = props;

  const [openAbout, setOpenAbout] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        {item?.request_number}
        <br />
        {item?.application_number ? `${t("navbar.inner-fcc")}` : ""}
        <br />
        <a href={`${item?.link}`} target="_blank">
          {item?.expertise_number ? item?.expertise_number : ""}
        </a>
      </td>
      <td>
        {item?.applicant_name}
        <br />
        {item?.applicant_tin}
      </td>
      <td>+998 {item?.representative_phone}</td>
      <td>
        {date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-
        {date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1}
        -{date.getFullYear()}
        <br />
        {date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
        {date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:
        {date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}
      </td>
      <td>
        <span
          onClick={() => setOpenAbout(true)}
          style={{ color: "#036323", cursor: "pointer" }}
        >
          {item?.status_name}
        </span>
      </td>
      <td>
        <div
          className={
            item?.editable ? "bg-circle bg-circle-editable" : "bg-circle"
          }
          onClick={() => (item?.editable ? setOpenEdit(true) : null)}
        ></div>
      </td>
      <td>
        <div className="bg" onClick={() => setOpenProducts(true)}></div>
      </td>
      <ProcessModal
        openAbout={openAbout}
        setOpenAbout={setOpenAbout}
        steps={item?.status_steps}
      />
      <InnerProductsModal
        openProducts={openProducts}
        setOpenProducts={setOpenProducts}
        products={item?.products}
        allRegions={allRegions}
      />
      <EditModal openEdit={openEdit} setOpenEdit={setOpenEdit} item={item} />
    </tr>
  );
};

export default OneRow;
