import { useRef } from "react";
import { PdfControl } from "../../../components/ui/pdfControl";
import { PageOne } from "./components/pageOne";
import { PageTheree } from "./components/pageTheree";
import { PageTwo } from "./components/pageTwo";
import { Hoc } from "./hoc";
import { Container, Wrapper } from "./style";

export const LabImportAct = ({ match }) => {
  const componentRef = useRef();
  const id = match?.params?.id ?? false;

  return (
    <Hoc params={match?.params}>
      <Wrapper>
        <PdfControl
          name={
            id +
            " - STATE PLANT QUARANTINE AND PROTECTION AGENCY OF THE REPUBLIC OF UZBEKISTAN “CENTRAL PHYTOSANITARY LABORATORY”"
          }
          componentRef={componentRef}
        />

        <Container ref={componentRef}>
          <PageOne />
          <PageTwo />
          <PageTheree />
        </Container>
      </Wrapper>
    </Hoc>
  );
};
