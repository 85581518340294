import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const InnerProductsModal = (props) => {
  const { setOpenProducts, openProducts, products } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={openProducts}
      onOk={() => setOpenProducts(false)}
      onCancel={() => setOpenProducts(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={1060}
      className="add-fcc-modal inner-fcc-page"
    >
      <h1>{t("inner-fcc.prod")}</h1>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.name")}</th>
              <th>{t("inner-fcc.kod")}</th>
              <th>{t("inner-fcc.obyom")}</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.hs_code}</td>
                  <td>
                    {item.quantity} {item.unit_name}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default InnerProductsModal;
