import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import langReducer from "./reducers/langReducer";
import linkReducer from "./reducers/linkReducer";
import listsReducer from "./reducers/listsReducer";
import loginReducer from "./reducers/loginReducer";
import regLoginReducer from "./reducers/regLoginReducer";

const rootReducers = combineReducers({
  language: langReducer,
  link: linkReducer,
  login: loginReducer,
  lists: listsReducer,
  pinLogin: regLoginReducer,
});

const store = createStore(rootReducers, applyMiddleware(thunkMiddleware));
export default store;
