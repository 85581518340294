import React, { useState } from "react";
import { setLocalTime } from "../../utils";
import EditFumigation from "./EditFumigation";
import ProcessModal from "../inner-fcc/ProcessModal";
import { useSelector } from "react-redux";

const TableRow = ({ item, index, offset, setOpen, getList }) => {
  //   const { t } = useTranslation();
  const lang = useSelector((state) => state.language.lang);
  const { pinOrTin, name: nameUser } = useSelector((state) => state.login);

  const [openEdit, setOpenEdit] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);

  return (
    <tr key={index} className={item?.editable ? "is-editable" : ""}>
      <td>{offset + index + 1}</td>
      <td>
        {item?.request_number}
        <br />
        <a href={`${item?.link}`} target="_blank" rel="noreferrer">
          {item?.certificate_of_disinfestation_number ? item?.certificate_of_disinfestation_number : ""}
        </a>
      </td>
      <td>
        {nameUser}
        <br />
        {pinOrTin}
      </td>
      {/* <td>{item?.organization_name}<br />{item?.organization_tin}</td> */}
      <td>{item?.region_name[lang === 'uz' ? "name_local" : `name_${lang}`]}</td>
      <td>{item?.district_name[lang === 'uz' ? "name_local" : `name_${lang}`]}</td>
      <td>{setLocalTime(item?.added_at, 'YYYY-MM-DD H:mm')}</td>
      <td>
        <span
          onClick={() => setOpenAbout(true)}
          style={{ color: "#036323", cursor: "pointer" }}
        >
          {item?.status_name}
        </span>
      </td>
      <td>
        <div
          className={
            item?.editable ? "bg-circle bg-circle-editable" : "bg-circle"
          }
          onClick={() => (item?.editable ? setOpenEdit(true) : null)}
        ></div>
      </td>

      <ProcessModal
        openAbout={openAbout}
        setOpenAbout={setOpenAbout}
        steps={item?.status_steps}
      />

      <EditFumigation
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        item={item}
        setOpen={setOpen}
        getList={getList}
      />
    </tr>
  );
};

export default TableRow;
