import React from "react";
import { PrintPaperComponent } from "../../../../../components/ui/PaperPrint";
import { StampStatus } from "../../../components/statuseStamp";
import { Spacer } from "../../style";
import { useFormContext } from "react-hook-form";
import { QRCodeComponets } from "../../../components/qrcode-generator/QRCode";

export const PageTwo = () => {
  const { watch } = useFormContext();
  const data = watch("data");
  const region = watch("region");
  return (
    <PrintPaperComponent hasBg={false} page={"2/2"} qrCode={true}>
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  Laboratoriya xulosasi / Заключение лаборатории / Laboratory
                  conclusion
                </h3>
                <p>{data?.conclusion}</p>
              </Spacer>
            </td>
            <td colSpan={3}>
              <StampStatus
                region={region?.lab_uzbek_name}
                status={data?.stamp_name}
              />
            </td>
          </tr>

          <tr>
            <td colSpan={5}>
              <h3 style={{ fontWeight: "bold" }}>
                Subpodryad ekspertizasi / Экспертиза проведённая по субподряду/
                Subcontracted expertise
              </h3>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <p>
                Ekspertiza natijalari faqat olingan namunalarga tegishli /
                Результаты экспертизы применимы только к полученным образцам /
                The results of the examination are applicable only to the
                received samples.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  {region?.id === 15 ? "Markaziy f" : "F"}itosanitariya
                  laboratoriya mudiri / Заведующий{" "}
                  {region?.id === 15 && "Центральной"} фитосанитарнной
                  лаборатории / Head of the {region?.id === 15 && "Central"}{" "}
                  Phytosanitary Laboratory
                </h3>
                <div>
                  <p>{data?.head_of_lab}</p>
                  <br />
                  <h3 style={{ fontWeight: "bold" }}>________________</h3>
                </div>
              </Spacer>
            </td>
            <td style={{ width: "120px" }}>
              <div style={{ padding: "10px 0 8px" }}>
                <QRCodeComponets />
              </div>
            </td>
            {/* <td>
              <div style={{ padding: "5px 0" }}>
                <QRCodeComponets size={80} />
              </div>
            </td> */}
          </tr>
        </tbody>
      </table>
    </PrintPaperComponent>
  );
};
