import React from "react";
import { PrintPaperComponent } from "../../../../../components/ui/PaperPrint";
import { StampStatus } from "../../../components/statuseStamp";
import { Spacer } from "../../style";
import { useFormContext } from "react-hook-form";
import { QRCodeComponets } from "../../../components/qrcode-generator/QRCode";

export const PageTwo = () => {
  const { watch } = useFormContext();
  const data = watch("data");
  const region = watch("region");
  return (
    <PrintPaperComponent hasBg={false} page={"2/3"} qrCode={true}>
      <table>
        <tbody>
          <tr>
            <td colSpan={4}>
              <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                Ekspertiza natijalari / Результаты экспертизы / Expertise
                results
              </h3>
            </td>
          </tr>

          <tr>
            <td colSpan={4}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  Normativ xujjatlar / НД на методы испытаний / ND for test
                  methods
                </h3>
                <p>
                  {data?.implemented_lab_test_methods
                    ?.map((item) => item.normative_document)
                    ?.join(", ")}
                </p>
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={4}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  Qo‘shimcha maʼlumot / Дополнительная информация / Additional
                  Information
                </h3>

                {/* <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Deserunt, facere.
                </p> */}
              </Spacer>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  Laboratoriya xulosasi / Заключение лаборатории / Laboratory
                  conclusion
                </h3>
                <div
                  className="dangerously-content"
                  dangerouslySetInnerHTML={{ __html: data?.conclusion }}
                />
              </Spacer>
            </td>
            <td colSpan={2} style={{ verticalAlign: "middle" }}>
              <StampStatus
                region={region?.lab_uzbek_name}
                status={data?.stamp_name}
              />
            </td>
          </tr>

          <tr>
            <td colSpan={4}>
              <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                Subpodryad ekspertizasi / Экспертиза проведённая по субподряду/
                Subcontracted expertise
              </h3>
            </td>
          </tr>

          <tr>
            <td colSpan={4}>
              <p>
                Ekspertiza natijalari faqat olingan namunalarga tegishli /
                Результаты экспертизы применимы только к полученным образцам /
                The results of the examination are applicable only to the
                received samples.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  Markaziy fitosanitariya laboratoriya mudiri / Заведующий
                  Центральной фитосанитарнной лаборатории / Head of the Central
                  Phytosanitary Laboratory
                </h3>
                <div>
                  <p>{data?.head_of_lab}</p>
                  <br />
                  <h3 style={{ fontWeight: "bold" }}>________________</h3>
                </div>
              </Spacer>
            </td>
            <td style={{ width: "120px" }}>
              <div style={{ padding: "10px 0 8px" }}>
                <QRCodeComponets />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </PrintPaperComponent>
  );
};
