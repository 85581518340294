import React, { Suspense } from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import "antd/dist/reset.css";
import store from "./redux/store";
import Loader from "./components/loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { subscribe } from "./helpers/createRequest";
import { ItemContextProvider } from "./store/item-context";
import { unregister } from "./registerServiceWorker";
import { queryClient } from "./helpers/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
// import { unregister } from './registerServiceWorker';

// ...

unregister();

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["uz", "ru", "en"],
    fallbackLng: "uz",
    detection: {
      order: ["cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
      requestOptions: {
        cache: "no-store",
      },
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
subscribe(store);
root.render(
  <Provider store={store}>
    <ItemContextProvider>
      <Suspense fallback={<Loader />}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Suspense>
    </ItemContextProvider>
  </Provider>
);
