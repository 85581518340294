import { Modal, Spin } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import "./modal.css";
import items from "./act.json";
import redirectUrl from "../../utils/navigate";
// import ItemContext from "../../store/item-context";
import queryString from "query-string";
import request from "../../helpers/createRequest";
import { toast } from "react-toastify";
import _ from "lodash";
import { antIcon } from "../invoice/Invoice";
import ReactInputMask from "react-input-mask";

const CompareModal = ({ open, setOpen, ...props }) => {
  const { t } = useTranslation();

  const [isBtnActive, setIsBtnActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [invoiceNums, setInvoiceNums] = useState([]);
  const [invoiceNum, setInvoiceNum] = useState("");
  // const [choosenDate, setChoosenDate] = useState("");

  const queryParams = queryString.parse(window.location.search);

  // const firstDay = moment().startOf("year").format("YYYY-MM-DD");
  // const today = moment().subtract(1, "days");
  const path = redirectUrl + "act-invoice";

  useEffect(() => {
    async function getInvoices() {
      await request
        .get("/applicant/all-invoice/list/")
        .then((res) => {
          // console.log(res.data);
          setInvoiceNums(res.data?.invoices?.map((inv) => inv.number));
        })
        .catch((e) => {
          console.log(e);
          // setIsLoading(false);
        });
    }
    getInvoices();
  }, []);

  useEffect(() => {
    if (invoiceNum && !isLoading) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [invoiceNum, isLoading]);

  const fetchData = async (e) => {
    e.preventDefault();
    // if (moment(choosenDate).isAfter(today)) {
    //   toast.error("Sana noto'g'ri kiritildi!");
    //   return;
    // }
    if (invoiceNum < 20999999999 && invoiceNum > 20230100000) {
      setIsLoading(true);
      await request
        .get(
          // `/applicant/invoice/reconciliation?chosen_date=${choosenDate}&invoice_number=${invoiceNum}`
          `/applicant/invoice/reconciliation?invoice_number=${invoiceNum}`
        )
        .then((res) => {
          // console.log(res.data.results);
          if (!_.isEmpty(res.data.operations)) {
            window.localStorage.setItem("acts", JSON.stringify(res.data));
            window.open(path, "_blank");
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.warning("Natija topilmadi!");
          }
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.response.status == 451) {
            toast.error("Ushbu oferta raqamiga joriy xizmat ko'rsatilmaydi!");
          } else {
            // console.log(e);
            toast.error("Xatolik! Oferta raqamini tekshiring!");
            if (e.response.data?.comment) {
              toast.error(e.response.data.comment);
            }
          }
        });
    } else {
      toast.error("Oferta raqami noto'g'ri kiritilgan!")
    }

  };

  // {/* 20230600295 */}

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={475}
      className="add-fcc-modal"
    >
      <h1>{t("navbar.compare")}</h1>
      <form>
        <div className="roow">
          <div className="row-full">
            <select
              value={invoiceNum}
              onChange={(e) => setInvoiceNum(e.target.value)}
            >
              <option hidden>{t("invoice.invoice-num")}</option>
              {invoiceNums?.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* <ReactInputMask
              type="text"
              required
              mask="99999999999"
              placeholder={t("invoice.invoice-num")}
              value={invoiceNum}
              onChange={(e) => setInvoiceNum(e.target.value)}
            /> */}
          </div>
          {/* <div className="input-group">
            <input
              type="date"
              value={firstDay}
              disabled
            />
          </div>
          <div className="input-group">
            <input
              type="date"
              min={firstDay}
              max={today.format("YYYY-MM-DD")}
              value={choosenDate}
              onChange={(e) => setChoosenDate(e.target.value)}
              required
            />
          </div> */}
        </div>
        <div className="btn-wrap compare-btn">
          <button
            type="submit"
            className={isBtnActive ? "button-active" : ""}
            disabled={!isBtnActive}
            onClick={(e) => fetchData(e)}
          >
            {isLoading ? <Spin indicator={antIcon} /> : t("inner-fcc.send")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CompareModal;
