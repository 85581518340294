import React from "react";
import IMGLOGO from "../../../../../../assets/images/Emblem_of_Uzbekistan.png";
import { Stack } from "../../style";

export const ActHeader = () => {
  return (
    <Stack
      style={{
        justifyContent: "space-between",
        gap: "22px",
        alignItems: "center",
        textTransform: "uppercase",
      }}
    >
      <h2 style={{ marginTop: "4px" }}>
        O‘zbekiston Respublikasi O‘simliklar karantini va himoyasi agentligi
        {/* UNDIRILGAN YOKI UNDIRISHGA MO‘LJALLANGAN URUG‘LAR IMPORTI UCHUN
        SERTIFIKAT */}
      </h2>
      <img
        src={IMGLOGO}
        style={{ width: "80px" }}
        alt="O‘simliklar karantini va himoyasi agentligi"
      />

      <h2 style={{ marginTop: "4px" }}>
        Agency of plant protection and quarantine of the Republic of Uzbekistan
        {/* CERTIFICATE FOR THE IMPORT OF SPROUTS OR SEEDS INTENDED FOR THE
        PRODUCTION OF SPROUTS */}
      </h2>
    </Stack>
  );
};
