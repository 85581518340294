import { Modal } from "antd";
import { MdClose, MdOutlineAdd } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import request from "../../helpers/createRequest";
// import "./main.css";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";
import { nanoid } from "@reduxjs/toolkit";
import _ from "lodash";
import WOW from "wow.js";
import ReactInputMask from "react-input-mask";

const AddField = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { pinOrTin, name: nameUser } = useSelector((state) => state.login);

  const { open, setOpen, getList, allCountries, allRegions } = props;

  const [isBtn, setIsBtn] = useState(false);

  const [name, setName] = useState(""); // representative name
  const [phone, setPhone] = useState(""); // representative phone
  const [inspection, setInspection] = useState("");

  const [manufacturer, setManufacturer] = useState("");
  const [manufacturerTin, setManufacturerTin] = useState("");
  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");
  const [yieldAmount, setYieldAmount] = useState("");
  const [yieldUnit, setYieldUnit] = useState("");
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");

  const [allDistricts, setAllDiscticts] = useState([]);

  const [allProducts, setAllProducts] = useState([]);

  const [formStage, setFormStage] = useState(0);
  const [isNext, setIsNext] = useState(false);

  const getDistricts = (regionId) => {
    if (regionId) {
      request
        .get(`api/districts/?region=${regionId}`)
        .then((res) => {
          setAllDiscticts(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addProduct = () => {
    if (yieldAmount && yieldUnit && productName && productType) {
      setAllProducts((prev) => [
        ...prev,
        {
          id: nanoid(),
          name: productName,
          type: productType,
          productivity_amount: yieldAmount,
          productivity_unit: yieldUnit,
        },
      ]);
      setProductName("");
      setProductType("");
      setYieldAmount("");
      setYieldUnit("");
    } else {
      toast.error("Ma'lumotlarni to'g'ri kiriting!");
    }
  };
  const deleteProduct = (id) => {
    setAllProducts(allProducts.filter((product) => id !== product.id));
  };

  const handleFormStages = () => {
    if (formStage == 0) {
      if (
        name &&
        phone &&
        inspection &&
        manufacturer &&
        manufacturerTin &&
        region &&
        district
      ) {
        setFormStage(1);
      } else {
        toast.error("Ma'lumotlarni to'g'ri kiriting!");
      }
    } else {
      setFormStage(0);
    }
  };

  const submitFirstStage = (e) => {
    e.preventDefault();

    handleFormStages();
  };

  const submitCertificate = (e) => {
    e.preventDefault();

    if (_.isEmpty(allProducts)) {
      toast.error("Mahsulot qo'shilmagan!");
      return;
    }
    if (
      name &&
      phone &&
      inspection &&
      manufacturer &&
      manufacturerTin &&
      region &&
      district
    ) {
      const data = {
        applicant_tin: pinOrTin,
        applicant_name: nameUser,
        representative_name: name,
        representative_phone: phone,
        manufacturer_name: manufacturer,
        manufacturer_tin: manufacturerTin,
        manufactured_region: region,
        manufactured_district: district,
        status: 1,
        inspection: inspection,
        application_type: 2,
        products: allProducts,
      };

      request
        .post(`applicant/lab-application/list/`, { ...data })
        .then((res) => {
          console.log(res);
          if (res.data?.comment) {
            toast.success(res.data?.comment?.uz, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success("Ariza yuborildi!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setOpen(false);
          setFormStage(0);
          setName("");
          setPhone("");
          setInspection("");
          setManufacturer("");
          setManufacturerTin("");
          setRegion("");
          setDistrict("");
          setAllProducts([]);
          setIsBtn(false);
          setIsNext(false);

          getList();
        })
        .catch((err) => {
          if (err.response?.data?.comment) {
            toast.error(err.response.data.comment);
          } else {
            toast.error("Xatolik!");
          }
        });
    } else {
      toast.error("Ma'lumotlarni to'g'ri kiriting!");
    }
  };

  useEffect(() => {
    new WOW().init();
  }, []);

  const checkBtn = () => {
    if (!_.isEmpty(allProducts)) {
      setIsBtn(true);
    } else {
      setIsBtn(false);
    }
  };

  const checkIsNext = () => {
    if (
      name &&
      phone &&
      inspection &&
      manufacturer &&
      manufacturerTin &&
      region &&
      district
    ) {
      setIsNext(true);
    } else {
      setIsNext(false);
    }
  };
  useEffect(() => {
    checkIsNext();
  }, [
    name,
    phone,
    inspection,
    manufacturer,
    manufacturerTin,
    region,
    district,
  ]);

  useEffect(() => {
    checkBtn();
  }, [allProducts]);

  useEffect(() => {
    if (region) {
      getDistricts(region);
    }
  }, [region]);

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={1060}
      className="add-fcc-modal"
    >
      <h1>{t("lab.field")}</h1>
      <div className="steps-wrap">
        <div
          className={
            isNext
              ? "step-left active active-text"
              : formStage == 0
                ? "step-left active-text"
                : "step-left"
          }
        >
          <span>{t("lab.stage-1")}</span>
        </div>
        <div
          className={
            isBtn
              ? "step-right active-text active"
              : formStage == 1
                ? "step-right active-text"
                : "step-right"
          }
        >
          <span>{t("lab.stage-2")}</span>
        </div>
      </div>

      {formStage == 0 && (
        <form
          className="wow slideInLeft"
          data-wow-duration={"0.3s"}
          onSubmit={submitFirstStage}
        >
          <div className="roow">
            <div>
              <label>{t("lab.exporter")}</label>
              <input type="text" value={nameUser} readOnly />
            </div>
            <div>
              <label>{t("inner-fcc.inn")}</label>
              <input type="text" value={pinOrTin} readOnly />
            </div>
          </div>
          <div className="roow">
            <div>
              <label>{t("lab.applicant")}</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label>{t("inner-fcc.phone-num")}</label>
              <ReactInputMask
                type="text"
                required
                mask="999999999"
                placeholder=""
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="roow">
            <div style={{ width: "100%" }}>
              <label>{t("invoice.admin")}</label>
              <select
                value={inspection}
                onChange={(e) => setInspection(e.target.value)}
              >
                <option hidden>{t("inner-fcc.tanla")}</option>
                {allRegions?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name_local}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="roow">
            <div>
              <label>{t("inner-fcc.reg-pro")}</label>
              <select
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              >
                <option hidden>{t("inner-fcc.tanla")}</option>
                {allRegions?.map((reg, index) => {
                  return (
                    <option key={index} value={reg.id}>
                      {reg.name_local}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label>{t("inner-fcc.ray-pro")}</label>
              <select
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              >
                <option hidden>{t("inner-fcc.tanla")}</option>
                {allDistricts?.map((dist, index) => {
                  return (
                    <option key={index} value={dist.id}>
                      {dist.name_local}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="roow">
            <div>
              <label>{t("lab.field-name")}</label>
              <input
                type="text"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
              />
            </div>
            <div>
              <label>{t("inner-fcc.inn")}</label>
              <input
                type="number"
                max={99999999999999}
                value={manufacturerTin}
                onChange={(e) => setManufacturerTin(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-wrap" style={{ textAlign: "right" }}>
            <button
              type="submit"
              className={isNext ? "button-active" : ""}
              disabled={!isNext}
            >
              {t("lab.continue")}
            </button>
          </div>
        </form>
      )}

      {formStage == 1 && (
        <form
          onSubmit={submitCertificate}
          className="wow slideInRight"
          data-wow-duration={"0.3s"}
        >
          <div className="roow">
            <div>
              <label>{t("lab.product-name")}</label>
              <input
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <div>
              <label>{t("lab.product-type")}</label>
              <input
                type="text"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              />
            </div>
          </div>

          {/* <div className="roow">
            <div>
              <label>{t("lab.yield")}</label>
              <input
                type="number"
                value={yieldAmount}
                onChange={(e) => setYieldAmount(e.target.value)}
              />
            </div>
            <div>
              <label>{t("lab.unit")}</label>
              <select
                value={yieldUnit}
                onChange={(e) => setYieldUnit(e.target.value)}
              >
                <option hidden>{t("inner-fcc.tanla")}</option>
                <option value={12}>{"Kg"}</option>
                <option value={13}>{"Tn"}</option>
              </select>
            </div>
          </div> */}

          {/* <div className="roow">
          <div>
            <label>{t("lab.export-amount")}</label>
            <input
              type="number"
              value={exportingAmount}
              onChange={(e) => setExportingAmount(e.target.value)}
            />
          </div>
          <div>
            <label>{t("lab.unit")}</label>
            <select
              value={exportingUnit}
              onChange={(e) => setExportingUnit(e.target.value)}
            >
              <option hidden>{t("inner-fcc.tanla")}</option>
              {options?.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div> */}

          <div className="roow last-row">
            {/* <div>
              <label>{t("lab.export-amount")}</label>
              <input
                type="number"
                value={exportingAmount}
                onChange={(e) => setExportingAmount(e.target.value)}
              />
            </div>
            <div>
              <label>{t("lab.unit")}</label>
              <select
                value={exportingUnit}
                onChange={(e) => setExportingUnit(e.target.value)}
              >
                <option hidden>{t("inner-fcc.tanla")}</option>
                <option value={12}>{"Kg"}</option>
                <option value={13}>{"Tn"}</option>
              </select>
            </div> */}
            <div>
              <label>{t("lab.yield")}</label>
              <input
                type="number"
                value={yieldAmount}
                onChange={(e) => setYieldAmount(e.target.value)}
              />
            </div>
            <div>
              <label>{t("lab.unit")}</label>
              <select
                value={yieldUnit}
                onChange={(e) => setYieldUnit(e.target.value)}
              >
                <option hidden>{t("inner-fcc.tanla")}</option>
                <option value={12}>{"Kg"}</option>
                <option value={13}>{"Tn"}</option>
              </select>
            </div>
            <div>
              <div
                className="btn-wrap"
                style={{
                  width: "100%",
                  alignItems: "end",
                }}
              >
                <button
                  type="button"
                  onClick={addProduct}
                  className={"button-active"}
                  style={{ width: "100%" }}
                >
                  {/* <MdOutlineAdd className="icon" /> */}
                  {t("lab.add")}
                </button>
              </div>
            </div>
          </div>

          {/* <div className="roow">
          <div
            className="btn-wrap"
            style={{
              width: "100%",
              alignItems: "end",
            }}
          >
            <button
              //   type="submit"
              className={"button-active"}
              //   style={{ width: "100%" }}
            >
              +
            </button>
          </div>
        </div> */}

          {!_.isEmpty(allProducts) && (
            <div className="table-outer">
              <div className="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>{t("lab.product-name")}</th>
                      <th>{t("lab.product-type")}</th>
                      {/* <th>{t("inner-fcc.reg-pro")}</th>
                      <th>{t("inner-fcc.ray-pro")}</th>
                      <th>{t("lab.field-name")}</th>
                      <th>{t("inner-fcc.inn")}</th> */}
                      <th>{t("lab.yield")}</th>
                      <th>{t("lab.unit")}</th>
                      {/* <th>{t("lab.export-amount")}</th>
                      <th>{t("lab.unit")}</th> */}
                      <th>{t("lab.delete")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allProducts?.map((pro, idx) => {
                      return (
                        <tr key={pro.id}>
                          <td>{idx + 1}</td>
                          <td>{pro.name}</td>
                          <td>{pro.type}</td>
                          {/* <td>
                            {
                              allRegions?.filter(
                                (reg) => reg.id == pro.manufactured_region
                              )[0]?.name_local
                            }
                          </td>
                          <td>
                            {
                              districts?.filter(
                                (dist) => dist.id == pro.manufactured_district
                              )[0]?.name_local
                            }
                          </td> */}
                          {/* <td>{pro.manufacturer_name}</td>
                          <td>{pro.manufacturer_tin}</td> */}
                          <td>{pro.expected_productivity_amount}</td>
                          <td>{pro.productivity_unit}</td>
                          <td>
                            {
                              <TrashIcon
                                onClick={(e) => deleteProduct(pro.id)}
                                style={{ cursor: "pointer" }}
                              />
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="btn-wrap last-step">
            {/* <button
              type="button"
              className={isNext ? "back button-active" : "back"}
              disabled={!isNext}
              onClick={handleFormStages}
            >
              {t("lab.back")}
            </button> */}
            <span className="back-button" onClick={handleFormStages}>
              <BackIcon />
              {t("lab.back")}
            </span>
            <button
              type="submit"
              className={isBtn ? "button-active" : ""}
              disabled={!isBtn}
            >
              {t("inner-fcc.send")}
            </button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddField;
