import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 100vh;
  padding: 48px 16px;
  width: 100%;
`;
export const Container = styled.div`
  max-width: 22cm;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;
  font-family: "Times New Roman", Times, serif !important;
  * {
    margin: 0;
  }
  h2 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
  }
  h3 {
    font-size: 14px;
  }
  @media (max-width: 1100px) {
    display: block;
    padding: 16px;
  }
  table {
    width: 100%;
    .products-list {
      padding-bottom: 4px;
      border-bottom: 1px solid #d9d9d9;

      &:last-of-type {
        border-bottom: 0;
      }
      p {
        line-height: 1.4;
        font-size: 11px !important;
        &.title {
          font-weight: 600 !important;
        }
      }
    }
    .texts {
      gap: 5px;
      p {
        line-height: 1.2;
        font-size: 10px !important;
        text-align: justify;
        sup {
          font-size: 8px !important;
        }
      }

      .subs {
        p {
          font-size: 9px !important;
          text-indent: 9px;
          sup {
            font-size: 7px !important;
          }
        }
      }
    }
  }
  table,
  tr,
  td {
    border: 1px solid #d9d9d9;
    border-collapse: collapse;
    vertical-align: top;
  }
  td {
    padding: 8px;
    b {
      font-weight: 600;
    }
    * {
      font-size: 12px !important;
    }
    .title {
      font-weight: 500;
    }
  }
  .pageTwo {
    * {
      font-size: 12.4px !important;
    }
  }
  .rotate {
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    width: 1.5em;

    p {
      width: 100%;
      font-weight: bold;
      writing-mode: vertical-lr;
      -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: vertical-lr;
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
  .pagebreak {
    display: none;
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
export const Stack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const Spacer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
