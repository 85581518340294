import i18next from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import pageList from "../../pages/index";
import { language } from "../../redux/actions/langAction";
import "./layout.css";

const Layout = ({ menu }) => {
  const { lang: lan } = useSelector((state) => state.language);

  const dispatch = useDispatch();
  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(language(lang));
  };
  useEffect(() => {
    if (lan == "en") {
      changeLan("uz");
    }
  }, []);
  return (
    <div
      className={
        !menu ? "layout-component layout-component-active" : "layout-component"
      }
    >
      <Switch>
        {pageList.map((page, key) => (
          <Route
            path={page.path}
            component={page.component}
            key={key}
            exact={page.exact}
          />
        ))}
        <Route path="*">
          <Redirect to="/invoice" />
        </Route>
      </Switch>
    </div>
  );
};

export default Layout;
