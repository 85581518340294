import { useRef } from "react";
import { PageOne } from "./components/pageOne";
import { Container, Wrapper } from "./style";
import { PdfControl } from "../../../components/ui/pdfControl";
import { PageTwo } from "./components/pageTwo";
import { Hoc } from "./hoc";

export const LabExportAct = ({ match }) => {
  const componentRef = useRef();

  return (
    <Hoc params={match?.params}>
      <Wrapper>
        <PdfControl
          name="PHYTOSANITARY LABORATORY"
          componentRef={componentRef}
        />

        <Container ref={componentRef}>
          <PageOne />
          <PageTwo />
        </Container>
      </Wrapper>
    </Hoc>
  );
};
