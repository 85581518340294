import styled from "styled-components";
export const Container = styled.div`
  table {
    width: 100%;
  }
  table,
  tr,
  td {
    border: 1px solid #d9d9d9;
    border-collapse: collapse;
    vertical-align: top;
  }
  td {
    padding: 4px 8px;
    b {
      font-weight: 600;
    }
    * {
      font-size: 12px !important;
    }
    .title {
      font-weight: 500;
    }
  }

  tr {
    cursor: pointer;
    &:hover {
      background-color: #f3f3f3;
    }
    &.head {
      background-color: #f5f5f5;
      cursor: inherit;
    }
  }
`;
