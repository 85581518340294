import React, { useEffect, useState } from "react";
import request from "../../helpers/createRequest";
import queryString from "query-string";
import OneRow from "./OneRow";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Empty, Pagination } from "antd";
import Loader from "../../components/loader/Loader";
import { MdClose } from "react-icons/md";

const Applications = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [allRegions, setAllRegions] = useState([]);
  const [allApplications, setAllApplications] = useState([]);

  const [count, setCount] = useState();

  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 10;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;

  useEffect(() => {
    getRegions();
    getList();
  }, []);

  const getRegions = () => {
    request
      .get(`api/regions`)
      .then((res) => {
        setAllRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchList = (e) => {
    e.preventDefault();
    getList();
  };

  async function getList() {
    setIsLoading(true);
    await request
      .get(`applicant/lab-application/list/?limit=${LIMIT}&offset=${OFFSET}`, {
        params: {
          number: queryParams.number,
        },
      })
      .then((res) => {
        setAllApplications(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const onFilter = (itemValue, itemKey) => {
    const rootParams = { ...queryParams };
    if (itemValue) {
      rootParams[itemKey] = itemValue;
      rootParams.page = null;
    } else {
      rootParams[itemKey] = null;
    }
    props.history.push({
      search: queryString.stringify(rootParams, { skipNull: true }),
    });
  };

  const clearFilter = () => {
    props.history.push({ search: queryString.stringify({}) });
    queryParams.number = null;
    getList();
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="inner-fcc-page">
      <div className="top">
        <h1 onClick={() => getList()}>{t("lab.applications")}</h1>
        <div className="right">
          <form className="input-wrap" onSubmit={searchList}>
            <div></div>
            <input
              type="text"
              placeholder={t("inner-fcc.search")}
              id="number"
              value={queryParams.number || ""}
              onChange={(e) => onFilter(e.target.value, "number")}
            />
            <MdClose
              className={queryParams.number ? "icoon icoon-visible" : "icoon"}
              onClick={() => {
                clearFilter();
              }}
            />
          </form>
          {/* <div style={{ display: "flex" }}>
            <div className="filter-icon" onClick={() => setFilter(false)}></div>
            <div className="add-icon" onClick={() => setOpen(true)}></div>
          </div> */}
          {/* <div className="btn-wrapper" style={{ display: "flex" }}>
            <div className="btn-wrap">
              <button
                // type="submit"
                className={"button-active"}
                // disabled={invoices.length === 7}
                onClick={() => setOpen(true)}
              >
                {t("inner-fcc.single")}
            
              </button>
            </div>
          </div> */}
        </div>
      </div>
      {/* 
      <div className={filter ? "filter active-filter" : "filter"}>
        <input type="date" />
        <input type="date" />
        <select>
          <option hidden>Выберите город/область</option>
          <option>Buxoro</option>
          <option>Toshkent</option>
        </select>
        <button>
          <FiSearch className="icon" />
          Поиск
        </button>
      </div> */}

      {isLoading ? <Loader /> : null}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.zay-ser")}</th>
              <th>{t("inner-fcc.naim")}</th>
              <th>{t("inner-fcc.kontakt")}</th>
              <th>{t("inner-fcc.data-pol")}</th>
              <th>{t("inner-fcc.sos-ot")}</th>
              <th>{t("inner-fcc.otp-zan")}</th>
              <th>{t("inner-fcc.prod")}</th>
            </tr>
          </thead>
          <tbody>
            {allApplications?.map((item, index) => {
              const date = new Date(item.added_at);
              return (
                <OneRow
                  item={item}
                  index={index}
                  date={date}
                  key={index}
                  allRegions={allRegions}
                  getList={getList}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {_.isEmpty(allApplications) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}

      {!_.isEmpty(allApplications) && !isLoading && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Applications;
