import { Empty, Pagination } from "antd";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { fetchRequest } from "../../helpers/createRequest";
import "./inner.css";

const Recs = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [recs, setRecs] = useState([]);

  useEffect(() => {
    getIPM();
  }, []);

  async function getIPM() {
    setIsLoading(true);
    await fetchRequest
      .get(`agro/ipm/`)
      .then((res) => {
        setRecs(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  return (
    <div className="balance-page">
      <div className="top">
        <h1>{t("agro.recs")}</h1>
        <div className="right"></div>
      </div>

      {isLoading ? <Loader /> : null}

      {!isEmpty(recs) && (
        <div className="cards-page">
          <div className="agro-inner-cards">
            {recs.map((cli, idx) => (
              <a href={cli.file} target="_blank" key={idx}>
                <div className="agro-inner-card">
                  <div className="product-info">
                    <div className="card-img">
                      <img src={cli.logo}></img>
                    </div>
                    <div className="product-title">
                      <h3>{cli.title}</h3>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}

      {_.isEmpty(recs) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
    </div>
  );
};

export default Recs;
