import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WarningIcon from "../../assets/icons/warningIcon.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { language } from "../../redux/actions/langAction";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./warning.css";
import { NewsModal } from "./news";

const WarningModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openNews, setOpenNews] = useState(false);

  const [isBtn, setIsBtn] = useState(false);
  const { lang: lan } = useSelector((state) => state.language);

  const dispatch = useDispatch();
  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(language(lang));
  };

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      setOpen(true);
      localStorage.setItem("pop_status", 1);
    }
    if (pop_status == 1) {
      setOpen(true);
    }
    setTimeout(() => {
      setIsBtn(true);
    }, 5000);
  }, []);

  const closeWarning = () => {
    if (isBtn) {
      localStorage.setItem("pop_status", 2);
      setOpen(false);
      setOpenNews(true);
    } else {
      toast.warning("Iltimos, ogohlantirish bilan tanishing!");
    }
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too lale...</div>;
    }

    return (
      <div className="timer">
        <div className="text">Remaining</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  if (!open)
    return openNews ? (
      <NewsModal open={openNews} setOpen={setOpenNews} />
    ) : null;

  return (
    <>
      <Modal
        open={open}
        centered
        title={null}
        footer={null}
        //   closeIcon={null}
        closable={false}
        width={780}
        className="add-fcc-modal custom"
      >
        <div className="langs-outer">
          <CountdownCircleTimer
            isPlaying
            duration={5}
            // initialRemainingTime={15}
            isGrowing
            colors="#036323"
            size={40}
            strokeWidth={6}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
          <div className="langs" style={{ maxWidth: "70px" }}>
            <div
              className={lan === "uz" ? "active-lan" : ""}
              onClick={() => changeLan("uz")}
            >
              UZ
            </div>
            <div
              className={lan === "ru" ? "active-lan" : ""}
              onClick={() => changeLan("ru")}
            >
              RU
            </div>
          </div>
        </div>

        <h1 style={{ textAlign: "center" }} className="warning">
          <img src={WarningIcon} />
        </h1>

        <div className="modal-inner warning">
          <div className="warning-wrap common">
            <p>{t("warning.text1")}</p>
            <p>{t("warning.text2")}</p>
            <p>{t("warning.text3")}</p>
          </div>
        </div>

        <div style={{ textAlign: "center" }} className="btn-wrap warning">
          <button
            style={{ marginLeft: "auto", marginRight: "auto" }}
            type="button"
            className={isBtn ? "button-active btn-red" : "btn-red"}
            //   disabled={!isBtn}
            onClick={closeWarning}
          >
            {t("common.read")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default WarningModal;
