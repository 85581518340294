import { Container } from "./style";
import IMGLOGO from "../../../assets/images/main-logo.webp";
import QRCode from "react-qr-code";
import dayjs from "dayjs";

export const PrintPaperComponent = ({
  hasBg = true,
  bgUrl,
  children,
  page,
  // pageFooterLink,
  pageFooterLink = "https://efito.uz/check-certificate",
  qrCode = false,
}) => {
  return (
    <Container className="paper-container">
      {children}
      {hasBg && (
        <img
          className="img-placeholder"
          src={bgUrl ?? IMGLOGO}
          alt="O‘simliklar karantini va himoyasi agentligi"
        />
      )}

      {page && (
        <>
          <div className="page-count">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <div style={{ maxWidth: "32px" }}>
                {qrCode && (
                  <QRCode
                    size={32}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={window.location.href}
                  />
                )}
              </div>
              <p>
                O‘ZBEKISTON RESPUBLIKASI QISHLOQ XO‘JALIGI VAZIRLIGI HUZURIDAGI
                O‘SIMLIKLAR KARANTINI VA HIMOYASI AGENTLIGI{" "}
                <span className="date-footer">
                  | {dayjs().format("DD.MM.YYYY")}
                </span>
              </p>
            </div>
            <span>{page}</span>
          </div>
          {pageFooterLink && (
            <div className="page-footer">
              <p>
                Отчет не должен быть частично воспроизведен иначе как в полном
                объёме без разрешения лаборатории / Confirmation of quarantine
                permit can be checked by following link:{" "}
                <a target="_blank" href={pageFooterLink} rel="noreferrer">
                  {pageFooterLink}
                </a>
              </p>
            </div>
          )}
        </>
      )}

      <div className="pagebreak" />
    </Container>
  );
};
