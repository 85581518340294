import { Col, Divider, Row } from "antd";
import React from "react";
import { FormDateInput } from "../../../../../components/formComponents/dateInput";
import { FormInput } from "../../../../../components/formComponents/input";
import { FormPhoneInput } from "../../../../../components/formComponents/inputPhone";
import { FormSelect } from "../../../../../components/formComponents/select";
import { useMyForm } from "./usemyForm";
import { FormFileUploadInline } from "../../../../../components/formComponents/file-upload/upload";
import { ExportFcc } from "./export-fcc";
export const Forms = () => {
  const {
    t,
    props,
    transport_type,
    transport_condition_type,
    regionsLoading,
    regions,
    countriesLoading,
    countries,
    watch,
  } = useMyForm();
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.fio-za")}
          name="applicant_name"
          disabled
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.inn")}
          name="applicant_tin"
          disabled
        />
      </Col>
      <Col span={24} md={12}>
        <FormPhoneInput
          {...props}
          label={t("inner-fcc.phone-num")}
          placeholder={t("inner-fcc.phone-num")}
          name="applicant_phone"
        />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          label={t("applicant_region")}
          placeholder={t("inner-fcc.tanla")}
          name="applicant_region"
          options={regions}
          loading={regionsLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.reg-za")}
          placeholder={t("inner-fcc.reg-za")}
          name="applicant_address"
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...props}
          rules={{ required: false }}
          label={t("applicantEmail")}
          placeholder={t("applicantEmail")}
          name="applicant_mail"
        />
      </Col>

      <Col span={24}>
        <Divider style={{ margin: "8px 0" }} />
      </Col>

      <Col span={24} md={12} lg={6}>
        <FormInput
          {...props}
          label={t("imparter_country_name")}
          placeholder={t("imparter_country_name")}
          name="importer_name"
        />
      </Col>

      <Col span={24} md={12} lg={6}>
        <FormSelect
          {...props}
          label={t("imparter_country")}
          placeholder={t("inner-fcc.tanla")}
          name="importer_country"
          options={countries}
          loading={countriesLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("importer_address")}
          placeholder={t("importer_address")}
          name="importer_address"
        />
      </Col>

      <Col span={24} md={12} lg={6}>
        <FormSelect
          {...props}
          label={t("country_destination")}
          placeholder={t("inner-fcc.tanla")}
          name="destination_country"
          options={countries}
          loading={countriesLoading}
        />
      </Col>

      <Col span={24} md={12} lg={6}>
        <FormInput
          {...props}
          label={t("destination_place_name")}
          placeholder={t("destination_place_name")}
          name="destination_place_name"
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("destination_address")}
          placeholder={t("destination_address")}
          name="destination_address"
        />
      </Col>
      <Col span={24} md={12} lg={8}>
        <FormInput
          {...props}
          label={t("entry_point")}
          placeholder={t("entry_point")}
          name="entry_point"
        />
      </Col>

      <Col span={24} md={12} lg={8}>
        <FormInput
          {...props}
          label={t("loading_place")}
          placeholder={t("loading_place")}
          name="loading_place"
        />
      </Col>
      {/* <Col span={24} md={12} lg={8}>
        <FormInput
          {...props}
          label={t("origin_place")}
          placeholder={t("origin_place")}
          name="origin_place"
        />
      </Col> */}
      <Col span={24} md={12} lg={8}>
        <FormDateInput
          {...props}
          label={t("departure_date")}
          placeholder={t("departure_date")}
          name="departure_date"
        />
      </Col>
      <Col span={24} md={12} lg={8}>
        <ExportFcc {...props} />
      </Col>

      <Col span={24}>
        <Divider style={{ margin: "8px 0" }} />
      </Col>
      <Col span={24} md={6}>
        <FormSelect
          {...props}
          label={t("transport_type")}
          placeholder={t("inner-fcc.tanla")}
          name="transport_types"
          options={transport_type}
          multiple={true}
        />
      </Col>
      <Col span={24} md={6}>
        <FormSelect
          {...props}
          label={t("transport_condition")}
          placeholder={t("inner-fcc.tanla")}
          name="transport_condition"
          options={transport_condition_type}
        />
      </Col>

      <Col span={24} md={6}>
        <FormInput
          {...props}
          label={t("transport_number")}
          placeholder={t("transport_number")}
          name="transport_number"
        />
      </Col>
      <Col span={24} md={6}>
        <FormInput
          {...props}
          label={t("container_number")}
          placeholder={t("container_number")}
          name="container_number"
          rules={{ required: false }}
        />
      </Col>
      {/* <Col span={24} md={6}>
        <FormInput
          {...props}
          label={t("lot_number")}
          placeholder={t("lot_number")}
          name="lot_number"
        />
      </Col> */}

      <Col span={24} md={6}>
        <FormFileUploadInline
          {...props}
          placeholder={t("inner-fcc.tanla")}
          label={t("sertifikat")}
          name="certificate"
          links={watch("certificate_path")}
        />
      </Col>
      <Col span={24} md={6}>
        <FormFileUploadInline
          {...props}
          placeholder={t("inner-fcc.tanla")}
          label={t("act")}
          name="act"
          links={watch("act_path")}
        />
      </Col>
      <Col span={24} md={6}>
        <FormFileUploadInline
          {...props}
          placeholder={t("inner-fcc.tanla")}
          label={t("top.import")}
          name="conclusion"
          links={watch("conclusion_path")}
        />
      </Col>
      <Col span={24}>
        <Divider style={{ margin: "8px 0" }} />
      </Col>
    </Row>
  );
};
