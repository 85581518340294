import styled from "styled-components";

export const Container = styled.form`
  .card-wrapper {
    margin-top: 16px;
    background-color: red;
    padding: 10px 16px;
    border-radius: 12px;
    background: linear-gradient(90deg, #0fd850 0%, #f9f047 100%);
    max-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .invoice-name {
      font-weight: bold;
    }

    .card-amount {
      width: 100%;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      span {
        &:first-of-type {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .card-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }
    .invoice-num,
    .invoice-date {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      span {
        &:first-of-type {
          opacity: 0.8;
        }
      }
    }
  }
`;
