import { Modal } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const Info = ({ open, setOpen, disposables, reactives }) => {
  const { t } = useTranslation();
  // console.log(data);

  const sumTotal = () => {
    const dispose = disposables.reduce((partialSum, a) => partialSum + a.amount * a.lab_disposable.price, 0)
    const reactive = reactives.reduce((partialSum, a) => partialSum + a.amount * a.lab_chemical_reactive.price, 0)
    const result = Number((dispose + reactive)).toFixed(2)
    // console.log(dispose)
    let nf = new Intl.NumberFormat('en-US');
    return nf.format(result)
  }

  const sumTwo = (amount, price) => {
    const result = Number(amount * price).toFixed(2)
    let nf = new Intl.NumberFormat('en-US');

    return nf.format(result)
  }

  const total = useMemo(() => sumTotal(), [disposables, reactives])
  
  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={800}
      className="add-fcc-modal"
    >
      <h1>{t("inner-fcc.used")}</h1>

      <h2>{t("inner-fcc.chemic")}</h2>

      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.name")}</th>
              <th>{t("inner-fcc.quan")}</th>
              <th>{t("inner-fcc.price")}</th>
              <th>{t("inner-fcc.sum")}</th>
            </tr>
          </thead>
          <tbody>
            {reactives?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.lab_chemical_reactive?.name}</td>

                  <td>
                    {item.amount} {item.lab_chemical_reactive?.unit?.name_local}
                  </td>
                  <td>{item.lab_chemical_reactive?.price}</td>
                  <td>{sumTwo(item?.amount, item.lab_chemical_reactive.price)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <h2 style={{marginTop: '30px'}}>{t("inner-fcc.dispose")}</h2>

      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.name")}</th>
              <th>{t("inner-fcc.quan")}</th>
              <th>{t("inner-fcc.price")}</th>
              <th>{t("inner-fcc.sum")}</th>
            </tr>
          </thead>
          <tbody>
            {disposables?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.lab_disposable?.name}</td>

                  <td>
                    {item.amount} {item.lab_disposable?.unit?.name_local}
                  </td>
                  <td>{item.lab_disposable?.price}</td>
                  <td>{sumTwo(item?.amount, item.lab_disposable.price)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <h3 style={{marginTop: '20px', textAlign: 'center'}}>{t("inner-fcc.sumTotal")}: {total}</h3>
      </div>

      
    </Modal>
  );
};

export default Info;
