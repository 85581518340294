import { Col, Divider, Row } from "antd";
import React from "react";
import { FormInput } from "../../../../components/formComponents/input";
import { FormPhoneInput } from "../../../../components/formComponents/inputPhone";
import { FormSelect } from "../../../../components/formComponents/select";
import { Product } from "../product";
import { useMyForm } from "./usemyForm";
import { BOX_TYPE } from "./const";
import { FormFileUploadInline } from "../../../../components/formComponents/file-upload/upload";
import { API_URL } from "../../../../constants/variables";
import { CompaniesSelect } from "./components/companies";
export const Forms = ({ isEdit }) => {
  const {
    t,
    props,
    regionsLoading,
    regions,
    districts,
    districtsLoading,
    typeOption,
    feaPoints,
    feaPointsLoading,
    feaPointsDistrict,
    feaPointsDistrictLoading,
    marketsLoading,
    markets,
    manfDistricts,
    manDistrictsLoading,
    watch,
  } = useMyForm();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.fio-za")}
          name="applicant_name"
          disabled
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.inn")}
          name="applicant_tin"
          disabled
        />
      </Col>
      <Col span={24} md={12}>
        <FormPhoneInput
          {...props}
          label={t("inner-fcc.phone-num")}
          placeholder={t("inner-fcc.phone-num")}
          name="applicant_phone"
        />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          label={t("inner-fcc.reg-za")}
          placeholder={t("inner-fcc.tanla")}
          name="applicant_region"
          options={regions}
          loading={regionsLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.add-za")}
          placeholder={t("inner-fcc.add-za")}
          name="applicant_address"
        />
      </Col>

      <Col span={24}>
        <Divider style={{ margin: "8px 0" }} />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          label={t("inner-fcc.kon-reg")}
          placeholder={t("inner-fcc.tanla")}
          name="sender_region"
          options={regions}
          loading={regionsLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          placeholder={t("inner-fcc.tanla")}
          label={t("inner-fcc.kon-ray")}
          name="sender_district"
          options={districts}
          loading={districtsLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          label={t("inner-fcc.reg-pro")}
          placeholder={t("inner-fcc.tanla")}
          name="manufactured_region"
          options={regions}
          loading={regionsLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          placeholder={t("inner-fcc.tanla")}
          label={t("inner-fcc.ray-pro")}
          name="manufactured_district"
          options={manfDistricts}
          loading={manDistrictsLoading}
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.place")}
          placeholder={t("inner-fcc.place") + ", MFY yoki F/X"}
          name="manufactured_place"
          maxLength={128}
        />
      </Col>

      <Col span={24}>
        <Divider style={{ margin: "8px 0" }} />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          placeholder={t("inner-fcc.tanla")}
          label={t("inner-fcc.for_export")}
          name="type"
          options={typeOption}
          disabled={isEdit}
        />
      </Col>

      <Col span={24} md={12}>
        <FormSelect
          {...props}
          placeholder={t("inner-fcc.tanla")}
          label={t("inner-fcc.b-hudud")}
          name="region_in_b_point"
          options={regions}
          loading={regionsLoading}
        />
      </Col>

      {watch("type") === 1 && (
        <>
          <Col span={24} md={12}>
            <FormSelect
              {...props}
              placeholder={t("inner-fcc.tanla")}
              label={t("TIF")}
              name="fea_point_in_b_point"
              options={feaPoints}
              loading={feaPointsLoading}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelect
              {...props}
              placeholder={t("inner-fcc.tanla")}
              label={t("type box")}
              name="box_type"
              options={BOX_TYPE?.map((p) => ({ ...p, label: t(p.label) }))}
            />
          </Col>
          {watch("box_type") === 1 && (
            <>
              <CompaniesSelect props={props} />
              {/* <Col span={24} md={12}>
                <FormSelect
                  {...props}
                  placeholder={t("inner-fcc.tanla")}
                  label={t("box company")}
                  name="packaging_company"
                  options={companiies}
                  loading={companiiesLoading}
                />
              </Col> */}
              <Col span={24} md={12}>
                <FormFileUploadInline
                  {...props}
                  placeholder={t("inner-fcc.tanla")}
                  label={t("box company file")}
                  name="packaging_company_contract"
                  links={watch("packaging_company_contract_file")?.file}
                />
              </Col>
            </>
          )}
        </>
      )}
      {watch("type") === 3 && (
        <>
          <Col span={24} md={12}>
            <FormSelect
              {...props}
              placeholder={t("inner-fcc.tanla")}
              label={t("inner-fcc.b-hudud")}
              name="district_in_b_point"
              options={feaPointsDistrict}
              loading={feaPointsDistrictLoading}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelect
              {...props}
              placeholder={t("inner-fcc.tanla")}
              label={t("inner-fcc.name-bozor")}
              name="bazar_in_b_point"
              options={markets}
              loading={marketsLoading}
            />
          </Col>
        </>
      )}

      {watch("type") > 0 && (
        <>
          <Col span={24}>
            <Divider style={{ margin: "8px 0" }} />
          </Col>
          <Product isOne={watch("type") === 1} />
        </>
      )}
    </Row>
  );
};
