import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { getsPrut } from "../../apis/sprut";
import { REACT_QUERY_KEYS } from "../../constants/reactQueryKeys";
import { AddOrEditComponent } from "./components/add";
import "./style.css";
import Loader from "../../components/loader/Loader";
import OneRow from "./OneRow";
export const SeedExport = () => {
  const { t } = useTranslation();
  const [searchNumber, setSearchNumber] = useState("");
  const { isLoading, data } = useQuery({
    queryFn: getsPrut,
    queryKey: [REACT_QUERY_KEYS.GET_SEED_EXPORT],
    select: (res) => res.data?.results,
  });
  return (
    <main className="page">
      {isLoading && <Loader />}
      <div className="top">
        <h1 style={{ fontSize: "18px" }}>{t("seed-export")}</h1>
        <div className="right">
          <form className="input-wrap">
            <div />
            <input
              type="text"
              placeholder={t("inner-fcc.search")}
              value={searchNumber}
              onChange={(e) => setSearchNumber(e.target.value)}
            />
            <MdClose
              className={searchNumber ? "icoon icoon-visible" : "icoon"}
              onClick={() => {
                setSearchNumber("");
              }}
            />
          </form>

          <AddOrEditComponent />
        </div>
      </div>

      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.zay-ser")}</th>
              <th>{t("inner-fcc.naim")}</th>
              <th>{t("inner-fcc.kontakt")}</th>
              <th>{t("inner-fcc.data-pol")}</th>
              <th>{t("inner-fcc.sos-ot")}</th>
              <th>{t("imparter_country")}</th>
              <th>{t("inner-fcc.otp-zan")}</th>
              <th>{t("inner-fcc.prod")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <OneRow key={index} index={index} {...item} />
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};
