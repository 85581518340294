import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import indev from "../../assets/icons/indev.svg";
import queryString from "query-string";
// import { FiSearch } from "react-icons/fi";
// import moment from "moment";
import request from "../../helpers/createRequest";
import { Empty, Pagination } from "antd";
import _ from "lodash";
import Loader from "../../components/loader/Loader";
import TableRow from "./TableRow";

const Import = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 10;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  //   const today = moment();
  //   const [filter, setFilter] = useState(false);
  const [allLists, setAllLists] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getList();
  }, [queryParams.page]);

  async function getList() {
    setIsLoading(true);
    await request
      .get(
        `applicant/lab-import-protocol/list/?limit=${LIMIT}&offset=${OFFSET}`
      )
      .then((res) => {
        setAllLists(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="inner-fcc-page">
      <div className="top">
        <h1>{t("top.import")}</h1>
        <div className="right">
          {/* <form className="input-wrap" onSubmit={searchList}>
            <div></div>
            <input
              type="text"
              placeholder={t("inner-fcc.search")}
            />
            <MdClose
              className={searchNumber ? "icoon icoon-visible" : "icoon"}
              onClick={() => {
                setSearchNumber("");
                getList();
              }}
            />
          </form> */}
          {/* <div style={{ display: "flex" }}>
            <div className="filter-icon" onClick={() => setFilter(false)}></div>
          </div> */}
        </div>
      </div>

      {/* <div className={filter ? "filter active-filter" : "filter"}>
        <input type="date" />
        <input type="date" />
        <select>
          <option hidden>Выберите город/область</option>
          <option>Buxoro</option>
        </select>
        <button>
          <FiSearch className="icon" />
          Поиск
        </button>
      </div> */}

      {isLoading ? <Loader /> : null}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.summary")}</th>
              <th>{t("inner-fcc.give")}</th>
              {/* <th>{t("inner-fcc.chemic")}</th> */}
              <th>{t("inner-fcc.used")}</th>
              <th>{t("inner-fcc.lab")}</th>
              <th>{t("inner-fcc.pay")}</th>
            </tr>
          </thead>
          <tbody>
            {allLists?.map((item, index) => {
              const date = new Date(item.given_date);
              return (
                <TableRow
                  item={item}
                  index={index}
                  date={date}
                  key={index}
                  offset={OFFSET}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {_.isEmpty(allLists) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}

      {!_.isEmpty(allLists) && !isLoading && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Import;
