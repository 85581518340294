import React from "react";
import LabIcon from "../../../../../assets/images/lab-icon.png";
import { PrintPaperComponent } from "../../../../../components/ui/PaperPrint";
import { Spacer, Stack } from "../../style";
import { ActHeader } from "../header";
import { QRCodeComponets } from "../../../components/qrcode-generator/QRCode";
import { useFormContext } from "react-hook-form";

export const PageOne = () => {
  const { watch } = useFormContext();
  const data = watch("data");
  const region = watch("region");
  const manufactured_region = watch("manufactured_region");
  const manufactured_district = watch("manufactured_district");
  const units = watch("units");

  return (
    <PrintPaperComponent hasBg={false} page={"1/2"} qrCode={true}>
      <ActHeader uz={region?.lab_uzbek_name} en={region?.lab_english_name} />

      <Stack style={{ justifyContent: "space-between", padding: "16px 0 8px" }}>
        <div
          style={{
            width: "190px",
            textAlign: "left",
          }}
        >
          <QRCodeComponets size={80} style={{ textAlign: "left" }} />
        </div>
        <Spacer style={{ maxWidth: "240px", gap: "2px" }}>
          <h2 style={{ marginBottom: "0" }}>EKSPERTIZA XULOSASI</h2>
          <h2 style={{ marginBottom: "0" }}>ЗАКЛЮЧЕНИЕ ЭКСПЕРТИЗЫ</h2>
          <h2 style={{ marginBottom: "0" }}>CONCLUSIONS OF EXPERTISE</h2>
          <h3 style={{ marginBottom: "0", textAlign: "center" }}>
            Eksport / Экспорт / Export
          </h3>
        </Spacer>

        <img
          style={{ objectFit: "contain", width: "200px" }}
          src={LabIcon}
          alt="Labaratoriya icons"
        />
      </Stack>

      <div>
        <Stack
          style={{ justifyContent: "space-between", padding: "8px 0 4px" }}
        >
          <h3 style={{ fontSize: "13px" }}>{region?.address_uz ?? "-"}</h3>
          <h3 style={{ fontSize: "13px" }}>{region?.address_en ?? "-"}</h3>
        </Stack>

        <table>
          <tbody>
            <tr>
              <td rowSpan={2} colSpan={3}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Ushbu ekspertiza xulosasi berildi / Заключения экспертизы
                    выдано / This expert opinion is issued
                  </h3>
                  <p>{data?.applicant_name}</p>
                </Spacer>
              </td>
              <td colSpan={2}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Ekspertiza raqami / Номер экспертизы / Expertise number
                  </h3>
                  <p>{data?.shortcut_number}</p>
                </Spacer>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Berildi / Выдано / Issued
                  </h3>
                  <p>{data?.given_date}</p>
                </Spacer>
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  Mahsulot haqida maʼlumot / Информация о продукции / Product
                  information
                </h3>
              </td>
            </tr>

            <tr>
              <td rowSpan={2} colSpan={3}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Mahsulot nomi, miqdori va o‘lchov birligi / Наименование,
                    количество и ед-измер. продукции / Name, quantity and unit
                    of the product
                  </h3>
                  <div>
                    {data?.products?.map((pr, index) => (
                      <p key={index}>
                        {index + 1}. {pr.name} ({pr.type} {pr.hs_code}) -{" "}
                        {pr.productivity_amount}{" "}
                        {
                          units?.find((u) => u.id === pr.sample_unit)
                            ?.name_local
                        }
                        ;
                      </p>
                    ))}
                  </div>
                </Spacer>
              </td>
              <td colSpan={2}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Mahsulot yetishtirilgan davlat va hudud / Страна и регион
                    происхождения продукции / Country and region of origin
                  </h3>
                  <p>
                    {manufactured_region?.name_local},{" "}
                    {manufactured_district?.name_local}
                  </p>
                </Spacer>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    * Davlat inspektor F.I.Sh. / Ф.И.О. Государственного
                    инспектора / Full name State inspector
                  </h3>
                  <p>{data?.inspector}</p>
                </Spacer>
              </td>
            </tr>

            <tr>
              <td colSpan={5}>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  Tanlab olingan namuna haqida maʼlumot / Информация об отборе
                  проб / Sampling information
                </h3>
              </td>
            </tr>

            <tr>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Yorliq raqami / Номер ярлыка / Label number
                  </h3>
                  <p>{data?.shortcut_number}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Namuna soni / Количество образцов / Number of samples
                  </h3>
                  <p>{data?.number_of_sample}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Namuna tanlab olingan sanasi / Дата отбора образцов /
                    Sampling date
                  </h3>
                  <p>{data?.sampling_date}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    {region?.id === 15 && "M"}FL ro‘yxatga olingan raqami/
                    Регистрационный номер {region?.id === 15 && "Ц"}ФЛ /{" "}
                    {region?.id === 15 && "C"}РL registration number
                  </h3>
                  <p>{data?.notification_number}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    {region?.id === 15 && "M"}FL ga qabul qilingan sana/ Дата
                    поступления в {region?.id === 15 && "Ц"}ФЛ/ Date of
                    admission to the {region?.id === 15 && "central"} quarantine
                    laboratory
                  </h3>
                  <p>{data?.shortcut_given_date}</p>
                </Spacer>
              </td>
            </tr>

            <tr>
              <td colSpan={5}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    * Namunalarni tanlab olish akkreditatsiya doirasiga taluqli
                    emas / Отбор образцов не аккредитованный деятельности /
                    Sampling is not an accredited activity / Tanlab olish uslubi
                    / Метод отбора / Sampling method
                  </h3>
                  <p>
                    ГОСТ 12430-2019: O‘simliklar karantini Karantin
                    fitosanitariya tekshiruvi va laboratoriya tadqiqotlari uchun
                    karantin ostidagi mahsulotlardan namuna olish usullari va
                    meʼyorlari / Методы и нормы отбора образцов подкарантинной
                    продукции при карантинном фитосанитарном досмотре и
                    лабораторных исследованиях / Methods and standards for
                    sampling regulated products during quarantine phytosanitary
                    inspection and laboratory research
                  </p>
                </Spacer>
              </td>
            </tr>

            <tr>
              <td colSpan={5}>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  Ekspertiza natijalari / Результаты экспертизы / Expertise
                  results
                </h3>
              </td>
            </tr>

            <tr>
              <td colSpan={5}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Normativ xujjatlar / НД на методы испытаний / ND for test
                    methods
                  </h3>
                  <p>
                    {data?.implemented_lab_test_methods
                      ?.map((item) => item.normative_document)
                      ?.join(", ")}
                  </p>
                </Spacer>
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Qo‘shimcha maʼlumot / Дополнительная информация / Additional
                    Information
                  </h3>
                  {/* <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Deserunt, facere.
                  </p> */}
                </Spacer>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </PrintPaperComponent>
  );
};
