import { Modal, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
// import "./modal.css";
import queryString from "query-string";
import _ from "lodash";
import { fetchRequest } from "../../helpers/createRequest";
import Loader from "../../components/loader/Loader";

const DetailModal = ({ open, setOpen, id, ...props }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [countries, setCountries] = useState({});

  useEffect(() => {
    async function getProductByID() {
      //   setIsLoading(true);
      await fetchRequest
        .get(`export/product/${id}/`)
        .then((res) => {
          console.log(res.data);
          setCountries(res.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
    getProductByID();
  }, []);

  return (
    <Modal
      open={open}
      onOk={setOpen}
      onCancel={setOpen}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={475}
      className="add-fcc-modal"
    >
      {isLoading && <Loader />}
      <h1>{countries.title}</h1>
      <div>
        {countries && (
          <ul>
            {countries.obtained_countries?.map((country, idx) => (
              <li key={idx}>{country.title}</li>
            ))}
          </ul>
        )}
      </div>
    </Modal>
  );
};

export default DetailModal;
