import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InnerProductsModal from "./InnerProductsModal";
import ProcessModal from "./ProcessModal";
import { ModalButton } from "./components/layout/button";

const OneRow = (props) => {
  const { t } = useTranslation();
  const { item, index, date, baseURL, allRegions, allUnits, getList, numId } =
    props;

  const [openAbout, setOpenAbout] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { pinOrTin, name: nameUser } = useSelector((state) => state.login);

  return (
    <tr key={index} className={item?.editable ? "is-editable" : ""}>
      <td>{numId + index + 1}</td>
      <td>
        {item?.request_number}
        <br />
        {item?.local_fss_number ? `${t("navbar.inner-fcc")}` : ""}
        <br />
        <a href={`${item?.link}`} target="_blank" rel="noreferrer">
          {item?.local_fss_number ? item?.local_fss_number : ""}
        </a>
      </td>
      <td>
        {nameUser}
        <br />
        {pinOrTin}
        {/* {item?.applicant_name}
        <br />
        {item?.applicant_tin} */}
      </td>
      <td>+998 {item?.applicant_phone}</td>
      <td>
        {date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-
        {date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1}
        -{date.getFullYear()}
        <br />
        {date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
        {date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:
        {date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}
      </td>
      <td>
        <span
          onClick={() => setOpenAbout(true)}
          style={{ color: "#036323", cursor: "pointer" }}
        >
          {item?.status_name}
        </span>
      </td>
      <td>
        {allRegions?.find((r) => r.id === item?.sender_region)?.name_local}
      </td>
      <td>
        {item?.editable ? (
          <ModalButton getList={getList} data={item} />
        ) : (
          <div
            className={
              item?.editable ? "bg-circle bg-circle-editable" : "bg-circle"
            }
            // onClick={() => (item?.editable ? setOpenEdit(true) : null)}
          ></div>
        )}
      </td>
      <td>
        <div className="bg" onClick={() => setOpenProducts(true)}></div>
      </td>
      <ProcessModal
        openAbout={openAbout}
        setOpenAbout={setOpenAbout}
        steps={item?.status_steps}
      />
      <InnerProductsModal
        openProducts={openProducts}
        setOpenProducts={setOpenProducts}
        products={item?.products}
      />
      {/* <EditModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        item={item}
        baseURL={baseURL}
        allRegions={allRegions}
        allUnits={allUnits}
        getList={getList}
      /> */}
    </tr>
  );
};

export default OneRow;
