import request from "../helpers/createRequest";

export const sprutAdd = async (data) => {
  return await request.post("applicant/sprout/list/", data);
};
export const sprutEdit = async (data) => {
  return await request.put("applicant/sprout/list/", data);
};
export const getsPrut = async () => {
  return await request("applicant/sprout/list/");
};
export const getSprutById = async (id) => {
  return await request(`applicant/sprout/certificate/${id}/detail/`);
};
