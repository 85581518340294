import React from "react";
import IMGLOGO from "../../../../../assets/images/main-logo.webp";
import { Stack } from "../../style";

export const ActHeader = ({ uz, en }) => {
  return (
    <Stack
      style={{
        justifyContent: "space-between",
        gap: "22px",
        alignItems: "center",
      }}
    >
      <h2
        style={{ marginTop: "4px", width: "280px", textTransform: "uppercase" }}
      >
        {uz}
      </h2>
      <img
        src={IMGLOGO}
        style={{ width: "80px" }}
        alt="O‘simliklar karantini va himoyasi agentligi"
      />

      <h2
        style={{ marginTop: "4px", width: "280px", textTransform: "uppercase" }}
      >
        {en}
      </h2>
    </Stack>
  );
};
