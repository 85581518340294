import { PIN_LOGIN, PIN_LOGOUT } from "../types/Types";

let initialToken = JSON.parse(window.localStorage.getItem("pin_data"))?.access;
// let expiresIn = JSON.parse(
//   window.localStorage.getItem("user_data")
// )?.expires_in;
// let name = JSON.parse(window.localStorage.getItem("user_data"))?.name;
// let pinOrTin = JSON.parse(window.localStorage.getItem("user_data"))?.pin_or_tin;
// let email = JSON.parse(window.localStorage.getItem("user_data"))?.email;
// let phone = JSON.parse(window.localStorage.getItem("user_data"))?.mob_phone_no;

const initialState = {
  access: initialToken,
  //   expiresIn,
  //   name,
  //   pinOrTin,
  //   email,
  //   phone,
};

const regLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case PIN_LOGIN:
      return {
        ...state,
        access: action.payload.token,
        // expiresIn: action.payload.expires_in,
        // name: action.payload.name,
        // pinOrTin: action.payload.pin_or_tin,
        // email: action.payload.email,
        // phone: action.payload.mob_phone_no,
      };
    case PIN_LOGOUT:
      return {
        ...state,
        access: null,
      };
    default:
      return state;
  }
};

export default regLoginReducer;
