import React from "react";
import { useFormContext } from "react-hook-form";
import { PrintPaperComponent } from "../../../../../components/ui/PaperPrint";
import { QRCodeComponets } from "../../../components/qrcode-generator/QRCode";
import { Spacer } from "../../style";

export const PageTheree = () => {
  const { watch } = useFormContext();
  const data = watch("data");
  return (
    <PrintPaperComponent hasBg={false} page={"3/3"} qrCode={true}>
      <Spacer style={{ gap: "2px" }}>
        <h2 style={{ marginBottom: "0" }}>EKSPERTIZA XULOSASIGA ILOVA</h2>
        <h2 style={{ marginBottom: "0" }}>
          ПРИЛОЖЕНИЕ К ЗАКЛЮЧЕНИЮ ЭКСПЕРТИЗЫ
        </h2>
        <h2 style={{ marginBottom: "0" }}>
          ATTACHMENT SHEET FOR CONCLUSIONS OF EXPERTISE
        </h2>
      </Spacer>

      <table>
        <tbody>
          <tr>
            <td colSpan={3}>
              <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                Qo‘shimcha maʼlumotlar / Дополнительная информация/ Additional
                information
              </h3>
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <table>
                <thead>
                  <tr>
                    <td rowSpan={2}>
                      <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                        №
                      </h3>
                    </td>
                    <td colSpan={2}>
                      <h3 style={{ fontWeight: "bold" }}>
                        Fitosanitar sertifikat / Фитосанитарный сертификат /
                        Phytosanitary certificate
                      </h3>
                    </td>
                    <td rowSpan={2}>
                      <h3 style={{ fontWeight: "bold" }}>
                        Transport birligi raqami / Номер Транспортной единицы /
                        Transport Unit number
                      </h3>
                    </td>
                    <td rowSpan={2}>
                      <h3 style={{ fontWeight: "bold" }}>
                        Transport birligini ochish to‘g‘risidagi dalolatnoma
                        raqami / Номер акта вскрытия транспортной единицы /
                        Transport unit opening certificate number
                      </h3>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h3 style={{ fontWeight: "bold" }}>
                        Raqam / Номер / Number
                      </h3>
                    </td>
                    <td>
                      <h3 style={{ fontWeight: "bold" }}>Sana / Дата / Date</h3>
                    </td>
                  </tr>
                </thead>
                <tbody style={{ height: "600px" }}>
                  {data?.tbotds?.map((tb, i) => (
                    <tr key={i}>
                      <td style={{ textAlign: "center" }}>
                        <p>{i + 1}</p>
                      </td>
                      <td>
                        <p>{tb?.fss_number}</p>
                      </td>
                      <td>
                        <p>{tb?.fss_given_date}</p>
                      </td>
                      <td>
                        <p>{tb?.transport_number}</p>
                      </td>
                      <td>
                        <p>{tb?.tbotd_number}</p>
                      </td>
                    </tr>
                  ))}
                  {Array.from(
                    { length: 18 - (data?.tbotds?.length ?? 0) },
                    (_, i) => (
                      <tr key={i}>
                        <td
                          style={{ textAlign: "center", color: "transparent" }}
                        >
                          {i + 1 + data?.tbotds?.length}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              <Spacer>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  EKSPERTIZA XULOSASI
                </h3>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  ЗАКЛЮЧЕНИЕ ЭКСПЕРТИЗЫ
                </h3>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  CONCLUSIONS OF EXPERTISE
                </h3>
              </Spacer>
            </td>

            <td>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>
                  Ekspertiza raqami/ Номер экспертизы/ Expertise number
                </h3>
                <p>{data?.number}</p>
              </Spacer>
            </td>

            <td style={{ width: "110px" }} rowSpan={2}>
              <div style={{ padding: "5px 0" }}>
                <QRCodeComponets size={80} />
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={2}>
              <Spacer>
                <h3 style={{ fontWeight: "bold" }}>Berildi /Выдано/ Issued</h3>
                <p>{data?.given_date}</p>
              </Spacer>
            </td>
          </tr>
        </tbody>
      </table>
    </PrintPaperComponent>
  );
};
