import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";

const mutationCache = new MutationCache({
  onError: (res) => {
    const error = res;
    console.log(error);
    // toast.error(
    //   error.response.data?.detail ??
    //     error.response.data?.message ??
    //     error.response.statusText ??
    //     "Xatolik yuz berdi"
    // );
  },
});

const queryCache = new QueryCache({
  onError: (res) => {
    const error = res;
    console.log(error);
    // toast.error(
    //   error.response.data?.detail ??
    //     error.response.data?.message ??
    //     error.response.statusText ??
    //     "Xatolik yuz berdi"
    // );
  },
});

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, res) {
        const error = res;
        if (error.status === 404) return false;
        if (failureCount < 1) return true;
        return false;
      },
    },
  },
});
