import React from "react";

const Lab = () => {
  return (
    <>
        <p><strong>1.</strong><strong>Умумий қоидалар</strong></p>
        <p>1.1.Ўзбекистон Республикаси Фуқаролик кодексининг 370-моддасига мувофиқ агар қуйида кўрсатилган шартлар қабул қилиниб, хизматлар учун тўлов 100 % амалга оширилса, қабул қилувчи юридик ёки жисмоний шахс Буюртмачига айланади.</p>
        <p>1.2.Буюртмачи Оферта шартномаси (кейинги ўринларда - Шартнома) шартлари билан танишганидан сўнг, шартномада кўрсатилган суммани олдиндан тўлиқ тўлайди.</p>
        <p>1.3.Буюртмачи шартномани белгиланган тартибда қабул қилиб, Шартнома матнида келтириб ўтилган шаклдаги барча шартлар билан танишиб чиққанлигини, рози бўлганлигини ҳамда тўлиқ ва сўзсиз қабул қилганлигини тасдиқлайди.</p>
        <p>1.4.Ўзбекистон Республикаси Фуқаролик кодексининг 367-375-моддалари ва «Электрон хужжат айланиши тўгрисида» ги, «Электрон рақамли имзо тўғрисидаги Ўзбекистон Республикаси Қонунларига мувофиқ Шартномада Буюртмачининг мухри ва /ёки имзоси талаб этилмайди ҳамда шунга асосан ўз юридик кучини сақлаб қолади.</p>
        <p><strong>2. Шартнома предмети</strong></p>
        <p>2.1.Ўзбекистон Республикасининг «Ўсимликлар карантини тўғрисида»ги қонунига мувофиқ карантин остидаги маҳсулотларни етиштириш, тайёрлаш, сақлаш, ташиш, қайта ишлаш ҳамда реализация қилиш билан шуғулланувчи давлат ва хўжалик бошқаруви органлари, юридик ва жисмоний шахслар:</p>
        <ul>
          <li>экспортга жўнатилаётган ва хорижий мамлакатлардан Ўзбекистон Республикасига олиб кирилаётган карантин остидаги маҳсулотнинг ҳамда ўсимликлар карантини объектларини ташувчиларнинг карантин текширувини ва лаборатория экспертизасини ўтказади;</li>
          <li>лаборатория текширувлари ва тегишли экспертиза ўтказиш учун карантин остидаги маҳсулотдан намуналар олинади;</li>
          <li>ўсимликлар карантини объектларига ташхис қўйиш мақсадида лаборатория текширувларини ўтказилишини ташкил этилади;</li>
        </ul>
        <p>2.2.Агентлик карантин остидаги ўсимлик маҳсулотларини (кейинги ўринларда “Намуна”) лаборатория текширувларидан/экспертизасидан ўтказиш хизматини (кейинги ўринларда - “Хизматлар”) кўрсатиш, Буюртмачи эса “Хизматлар”ни қабул қилиб олиш ва хақини тўлаш мажбуриятини олади.</p>
        <p>2.3.Лаборатория хизмати учун тўлов миқдори <a href="http://www.cabinet.karantin.uz">www.cabinet.karantin.uz</a> электрон ҳавола орқали шахсий кабинетдаги нархлар рўйхати (прейскурант) ҳамда лаборатория текширувлари учун зарур бўлган кимёвий реагентлар ва бошқа материалларнинг бозор нархи асосида шаклланади.</p>
        <p>2.4.Ушбу шартнома бўйича “Хизматлар” кўрсатиши учун Буюртмачи Агентликга “Намуна”ларни тақдим этади, Агентлик эса “Хизматлар” кўрсатади.</p>
        <p>2.5.Буюртмачи шартнома суммасини тўлаганидан сўнг, тарафлар ўртасида лаборатория “Хизматлар”ини такдим этиш бўйича икки томонлама битим тузилган деб ҳисобланади ва Агентлик ушбу шартнома буйича тўланган сумма миқдори бўйича мажбуриятларга эга бўлади. &nbsp; &nbsp; &nbsp;</p>
        <p><strong>3. Тўловни амалга ошириш</strong></p>
        <p>3.1.Буюртмачи Шартнома умумий суммасининг 100% олдиндан пул ўтказиш йўли билан амалга оширганидан сўнг, Агентлик намуналардан фойдаланган ҳолда, экспертиза методикасига асосан 2 кундан &nbsp;<br />&nbsp;25 кунгача бўлган муддатда “Хизматлар”ни кўрсатади.</p>
        <p>3.2.Агар шартнома суммаси тўланмаган бўлса, уни қабул қилиш амалга оширилмаган ҳисобланади.</p>
        <p>3.3.Шартноманинг 1.2-банди асосидаги Буюртмачи тўлиқ олдиндан тўловни амалга оширгандан сўнг, Агентлик “Хизматлар”ни кўрсатади.</p>
        <p>3.4.Тўловлар Ўзбекистан Республикасининг миллий валютаси сўмда амалга оширилади.</p>
        <p>3.5.Амалга оширилган экспертиза, унинг методикаси ва муддатлар ҳақида “cabinet.karantin.uz” шахсий кабинетига кириш орқали маълумот олиш мумкин.</p>
        <p><strong>4. Тарафларнинг мажбуриятлари</strong></p>
        <p>4.1. Агентликнинг мажбуриятлари:</p>
        <p>4.1.1.Буюртмачи олдидаги барча мажбуриятларни шартномада келтирилган шартлар ва амалдаги қонунчиликка мувофиқ тўлиқ бажарилишини таъминлаш;</p>
        <p>4.1.2.“Хизматлар” якунлангач, Буюртмачига лаборатория хулосасини “Ягона дарча” ахборот тизими орқали Буюрмачининг электрон шахсий хонасига юбориш;</p>
        <p>4.1.3.“Хизматлар”ни тақдим этганлигини тасдиқловчи электрон ҳисоб-фактурани тақдим этиш;</p>
        <p>4.1.4.Амалдаги қонунчиликка биноан Буюртмачининг шахсий маълумотларини қайта ишлаш ва уларнинг махфийлигини таъминлаш.</p>
        <p>4.1.5. Агентлик томонидан хизматлар муддатида амалга оширилмаганида, кечиктирилган ҳар бир кун учун кўрсатилши лозим бўлган хизмат суммасининг 0,5 фоизи миқдорида, лекин хизмат кўрсатилиши лозим бўлган сумманинг 50 фоизидан ошмаган миқдорда Буюртмачига пеня тўлайди. &nbsp; 4.2. Агентликнинг ҳуқуқлари:</p>
        <p>4.2.1.&nbsp;“Намуна” ларни қабул қилиш жараёнида Буюртмачи томонидан тақдим қилинган маълумотлар ҳақиқатга мос эмаслиги ёки маълумотлар тўлик тақдим килинмаганлиги аникланса, вақтинчалик ёки доимий асосда Буюртмачининг буюртмасини қабул қилишдан бош тортиш;</p>
        <p>4.2.2. Шартнома шартлари бажарилмаганлиги ёки лозим даражада бажарилмаганлиги натижасида етказилган зарар қопланишини Буюртмачидан талаб килиш.</p>
        <p>4.3. Буюртмачининг мажбуриятлари:</p>
        <p>4.3.1. Шартномани тузиш олдидан унинг шартлари билан танишиб чиқиш;</p>
        <p>4.3.2. Агентликга &nbsp; мажбуриятларини бажариши учун керакли барча маълумотларни ўз вақтида тақдим этиш;</p>
        <p>4.3.3. Мазкур Шартнома доирасида буюртма берилган “Хизматлар” учун тўловларни ўз вақтида амалга ошириш;</p>
        <p>4.3.4. Буюртмачи томонидан шартномада кўрсатилган тўлов муддатида амалга оширилмаганида, кечиктирилган ҳар бир кун учун тўланиши лозим бўлган сумманинг 0,4 фоизи миқдорида, лекин жами тўланиши лозим бўлган сумманинг 50 фоизидан ошмаган миқдорда Агентликка пеня тўлайди. &nbsp; &nbsp; &nbsp; 4.4. Буюртмачининг ҳуқуқлари:</p>
        <p>4.4.1. Агентликдан Шартнома шартлари асосида “Хизматлар”ни кўрсатишни талаб қилиш;</p>
        <p>4.4.2.“Хизматлар”ни курсатиш жараёнида Агентлик шартнома шартлари ва нормативлардан чекинишга йўл қўйган бўлса, аниқланган барча камчиликларни бартараф этишни талаб қилиш.</p>
        <p><strong>5. Хизматларни бажариш вақти ва шартлари</strong></p>
        <p>5.1.Мазкур шартноманинг 1.2, 2.4 бандларида кўзда тутилган шартлар Буюртмачи томонидан тўлиқ бажарилгандан сўнг, Агентлик шартноманинг 3.3 ва 4.1 бандига мувофиқ “Хизматлар”ни амалга оширади.</p>
        <p>5.2.Буюртмачи томонидан «Намуна»лар Агентлик манзилига етказилган кундан бошлаб шартнома тўлови Агентлик ҳисоб рақамига 7 (етти) иш кунида ўтказиб берилмаса, «Намуна»ларни Буюртмачига қайтариш ёки уларни йўқ қилиш ихтиёри Агентликнинг ўзида қолади.</p>
        <p>5.3. Буюртмачи томонидан тўловни амалга ошириш кечиктирилган ҳолда, қайта «Намуна» юборилиши, Агентлик томонидан талаб қилиниши мумкин.</p>
        <p><strong>6. Коррупцияга қарши қўшимча шартлар</strong></p>
        <p>6.1. Тарафлар оферта тузишда, офертанинг амал қилиш муддатида ва ушбу муддат тугаганидан сўнг, оферта билан боғлиқ коррупциявий ҳаракатлар содир қилмасликка келишиб оладилар.</p>
        <p>6.2. Тарафлар Адлия вазирлиги, Коррупцияга қарши курашиш агентлиги, Савдо-саноат палатасининг 2021 йил 12 ноябрдаги 1/4671, 01-19/7284, 7/01-08-9544-сон қарорига илова билан тасдиқланган “Хўжалик юритувчи субъектлар томонидан шартномалар тузишда қўллаш учун тавсивий характерга эга бўлган коррупцияга қарши қўшимча шартлар” (кейинги ўринларда - коррупцияга қарши қўшимча шартлар)га мувофиқ коррупциянинг олдини олиш чораларини тан олади ва уларга риоя этилиши бўйича ҳамкорликни таъминлайдилар.</p>
        <p>6.3.Ҳар бир тараф оферта тузилган пайтда бевосита ўзи ёки унинг ижроия органлари, мансабдор шахслари ва ходимлари томонидан шартнома билан боғлиқ муносабатлар юзасидан қонунга хилоф равишда пул, моддий қийматликлар берилмаганлигини, шартнома тузилиши эвазига норасмий пул ёки бошқа моддий қийматликлар олинишига йўл қўйилмаганлигини, таклиф этилмаганлигини, уларни беришга ваъда қилинмаганлигини, шунингдек моддий ёки ҳар қандай турдаги имтиёз, устунликлар олинмаганлигини (келажакда бу турдаги ҳаракатларни амалга ошириши мумкинлиги ҳақида таассурот қолдирилмаганлигини) кафолатлайди.&nbsp;</p>
        <p>6.4.Тарафлар, улар томонидан шартнома доирасида жалб қилинган шахсларнинг (ёрдамчи пудратчи ташкилотлар, агентлар ва тарафлар назорати остидаги бошқа шахсларнинг) юқоридаги ҳаракатларни содир этмаслиги юзасидан оқилона чоралар кўради.</p>
        <p>6.5.Тарафлар уларнинг назорати остида бўлган ва улар номидан ҳаракат қиладиган шахслар томонидан коррупцияга қарши белгиланган мажбуриятлар бузилганлиги ҳолатлари ҳақида хабар берилганлиги учун хабарчига тазйиқ ўтказилмаслигини кафолатлайдилар.</p>
        <p>6.6.Агар бир тарафга бошқа тарафнинг коррупцияга қарши қўшимча шартларнинг 4 ва 5-бандларида белгиланган мажбуриятларнинг бузилишига йўл қўйилганлиги маълум бўлиб қолса, иккинчи тарафни бу ҳақда зудлик билан хабардор қилиши ва ушбу тарафдан оқилона муддат ичида тегишли чоралар кўрилишини ва амалга оширилган ишлар юзасидан унга хабардор қилишини талаб қилиши шарт. Тарафнинг талаби бўйича иккинчи тараф томонидан қоидабузарликларни бартараф қилиш бўйича оқилона муддат ичида тегишли чоралар кўрилмаган ёки кўриб чиқиш натижалари ҳақида хабардор қилмаган тақдирда, ушбу тараф зарарни тўлиқ қоплаб беришни талаб қилишга ҳақли.</p>
        <p><strong>7. Тарафларнинг жавобгарлиги, низоларни хал қилиш тартиби</strong></p>
        <p>7.1.Мазкур шартномада келтирилган шартларни бажармаслик ёки лозим даражада бажармаганлик учун тарафлар Ўзбекистан Республикаси Қонунларига мувофик жавобгарликка тортилади.</p>
        <p>7.2.Шартномани бажариш вактида форс-мажор холатлари: давлат хокимияти органларининг харакатлари, ёнгин, сув тошқини, зилзила, бошка табиий офатлар, электр энергиясининг йўқлиги, иш ташлашлар, фуқаролик урушлари, тартибсизликлар, шунингдек юқорида санаб ўтилмаган холатлар билан чекланмаган хар кандай бошқа фавқулодда ходисалар юзага келиб, Шартнома шартлари бузилса, “Агентлик” жавобгарликдан озод этилади.</p>
        <p>7.3.Агентлик Буюртмачига унинг хизматларидан фойдаланишни таъминлайдиган умумий алоқа каналлари ёки хизматларининг сифати учун жавобгар эмас.</p>
        <p>7.4.Буюртмачининг Агентлик томонидан кўрсатилган хизматларга эътирози ёки шикоятлари “<u>info</u><u>@</u><u>karantin</u><u>.</u><u>uz</u>” электрон почтаси орқали кўриб чиқиш учун қабул қилинади.</p>
        <p>7.5.Ушбу Шартнома бўйича юзага келган келишмовчилик ва низоларни Томонлар музокаралар йўли билан хал этадилар.</p>
        <p>7.6.Низолар музокара йўли билан хал этилмаса, низо Агентлик жойлашган ҳудудий &nbsp;Тошкент туманлараро иктисодий судида кўрилади.</p>
        <p>7.7. Суднинг қарори томонлар учун мажбурий ҳисобланади</p>
        <p><strong>8. Бошқа шартлар</strong></p>
        <p>8.1.Буюртмачи Шартномани қабул килиш хамда бажариш учун зарур бўлган барча хуқук ва ваколатларга эга.</p>
        <p>8.2.Агентлик ушбу Шартноманинг исталган шартларини ўзгартириш ёки тўлдириш хуқуқини ўз веб-сайтида барча ўзгаришларни эълон қилиш орқали сақлаб қолади.</p>
        <p>8.3.Шартнома тўлов амалга оширилган кундан бошлаб, экспертиза хизматлари тўлиқ амалга оширилгунга қадар амал қилади.&nbsp;</p>
        <p>8.4.Ушбу Шартномада назарда тутилмаган бошқа масалалар Ўзбекистан Республикасининг амалдаги Қонун ҳужжатлари асосида тартибга солинади.</p>
    </>
  );
};

export default Lab;
