import { Input, Space, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
export const FormInput = ({
  name,
  control,
  label,
  children,
  rules,
  ...props
}) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    name,
    control,
    rules: rules,
  });
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {label && (
        <label>
          {label}
          {rules?.required && (
            <Text type="danger">
              <sup> *</sup>
            </Text>
          )}
        </label>
      )}
      <div style={{ position: "relative" }}>
        <Input
          status={fieldState?.error && "error"}
          {...props}
          {...field}
          {...fieldState}
        />
        {children}
      </div>
      {fieldState?.error?.message && (
        <Text type="danger">{t(fieldState.error.message)}</Text>
      )}
    </Space>
  );
};
