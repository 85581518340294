import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  position: relative;
`;
export const Popup = styled.div`
  position: absolute;
  top: 58px;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  margin-top: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    font-size: 12px;
    b {
      font-size: 13px;
      font-weight: 500;
      color: #157031;
    }
    strong {
      font-weight: 500;
    }
  }
`;
