import { Col, Row } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/formComponents/input";
import { FormTextArea } from "../../../../components/formComponents/textarea/textarea";
import { FormFileUpload } from "../../../../components/formComponents/upload";
export const CompanyForms = () => {
  const { control, watch } = useFormContext();
  const registered = watch("application.is_reregister");
  const { t } = useTranslation();

  const props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };
  const lang_props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
      pattern:
        watch("application.language") === "UZ"
          ? {
              value: /^[^\u0400-\u04FF]+$/,
              message:
                "Iltimos faqat lotin  alifbolari yordamida ma'lumot kiriting",
            }
          : {
              value: /^[ЁёА-я0-9!@#$&()%^&+_[|/?=.,"°'{}`* -\]]+$/,
              message:
                "Пожалуйста, вводите информацию, используя только кириллицу",
            },
    },
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("lab.applicant")}
          name="company.owner_fullname"
          disabled
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("inner-fcc.kontakt")}
          name="company.phone"
          disabled
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("applicantEmail")}
          name="company.email"
          disabled
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("compPin")}
          name="company.inn"
          disabled
        />
      </Col>

      <Col span={24} md={12}>
        <FormInput
          {...props}
          label={t("mineral.zipcode")}
          name="company.post_index"
          type="number"
          rules={{
            required: {
              value: true,
              message: "inner-fcc.error",
            },
            min: {
              value: 1000,
              message: "Eg: 100155",
            },
          }}
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          {...lang_props}
          label={t("mineral.mailAddress")}
          name="company.post_address"
        />
      </Col>
      <Col span={24} md={12}>
        <FormFileUpload
          {...props}
          label={t("mineral.rekvizit")}
          name="company.requisites"
        />
      </Col>
      <Col span={24} md={12}>
        <FormFileUpload
          {...props}
          label={t(
            watch("application.app_type") === "MIN"
              ? "mineral.uploadDoc"
              : "mineral.uploadDoc min"
          )}
          name="application.file"
        />
      </Col>
      <Col span={24} md={12}>
        <FormInput
          control={control}
          label={t("mineral.fax")}
          name="company.fax"
          disabled={!!watch("company.id")}
        />
      </Col>
      <Col span={24}>
        <FormTextArea
          {...lang_props}
          label={t("mineral.comments")}
          name="application.comment"
        />
      </Col>
    </Row>
  );
};
