import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HsCodeModal } from "./modal";
import { getHsCodes } from "../../../apis/innerFss";
import { REACT_QUERY_KEYS } from "../../../constants/reactQueryKeys";

export const Hscodes = ({ state, setState }) => {
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({ offset: 0, limit: 20 });
  const { data: hsCodes, isLoading: hsCodesLoading } = useQuery({
    queryFn: () =>
      getHsCodes({
        ...params,
        gtk_name: true,
        code: search ?? null,
      }),
    queryKey: [REACT_QUERY_KEYS.GET_HSCODES, search, params],
    enabled: search === "" || search?.length > 1,
    select: (res) => {
      return res.data?.map((hs) => ({
        ...hs,
        value: hs.code,
        label: hs.code,
      }));
    },
  });
  const { t } = useTranslation();
  return (
    <div>
      <HsCodeModal
        label={`${t("inner-fcc.kod")}`}
        placeholder={t("inner-fcc.tanla")}
        state={state}
        setState={setState}
        options={hsCodes}
        loading={hsCodesLoading}
        onSearch={(e) => setSearch(e)}
        search={search}
        setParams={setParams}
        params={params}
      />
    </div>
  );
};
