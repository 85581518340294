import { Modal, Rate } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { StarSvgIcon } from "./index";
import { ReactComponent as Smile5 } from "../../assets/icons/smile5.svg";
import { ReactComponent as Smile1 } from "../../assets/icons/smile1.svg";
import { ReactComponent as Smile2 } from "../../assets/icons/smile2.svg";
import { ReactComponent as Smile3 } from "../../assets/icons/smile3.svg";
import { ReactComponent as Smile4 } from "../../assets/icons/smile4.svg";

import "./notification.css";
import { toast } from "react-toastify";

const RateModal = ({ open, setOpen, id }) => {
  const { t } = useTranslation();
  console.log(id);

  const [rateEffect, setRateEffect] = useState(false);
  const [rateValue, setRateValue] = useState();

  const showRateEffect = (value) => {
    setRateValue(value);
    if (value) {
      setRateEffect(true);
      setTimeout(() => {
        setRateEffect(false);
      }, 600);
    }
  };

  const onSubmitRate = () => {
    if (rateValue) {
      setOpen(false);
      return;
    } else {
      toast.warning("Ko'rsatilgan xizmatni baholang");
    }
  };

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={875}
      className="add-fcc-modal"
    >
      <h1>{t("rate.title")}</h1>
      <div className="rating">
        <Rate
          character={<StarSvgIcon style={{}} />}
          value={rateValue}
          onChange={showRateEffect}
        />
      </div>

      <div className="rate-wrap">
        <textarea rows={7} placeholder={t("rate.comment")}></textarea>
        <p>{t("rate.feedback")}</p>
      </div>
      <div className="btn-wrap">
        <button
          disabled={!rateValue}
          className={rateValue ? "button-active" : ""}
          onClick={onSubmitRate}
        >
          {t("inner-fcc.send")}
        </button>
      </div>
      <div className={rateEffect ? "rate-effect" : "rate-effect hidden"}>
        <div className="smile">
          {rateValue == 5 && <Smile5 />}
          {rateValue == 1 && <Smile1 />}
          {rateValue == 2 && <Smile2 />}
          {rateValue == 3 && <Smile3 />}
          {rateValue == 4 && <Smile4 />}
        </div>
      </div>
    </Modal>
  );
};

export default RateModal;
