import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import "./print.css";
export const PdfControl = ({ componentRef, name = "file" }) => {
  const { t } = useTranslation();

  return (
    <div style={{ position: "fixed", zIndex: 999, top: 24, right: 24 }}>
      <ReactToPrint
        trigger={() => (
          <Button htmlType="button" icon={<PrinterOutlined />}>
            {t("invoice.print")}
          </Button>
        )}
        content={() => componentRef.current}
        documentTitle={name}
      />
    </div>
  );
};
