import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getHsCodes, getProductList } from "../../../../../../apis/innerFss";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
import { HsCodePModal } from "./modal";

export const ProductSelect = ({ ind, rul, disabled, fieldName }) => {
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({ offset: 0, limit: 20 });
  const { setValue } = useFormContext();

  const { data: prs, isLoading: prsLoading } = useQuery({
    queryFn: () =>
      getHsCodes({
        ...params,
        gtk_name: true,
        keyword: search === "" ? undefined : search,
        offset: search === "" ? params.offset : 0,
      }),

    // getProductList({
    //   name: search === "" ? undefined : search,
    //   ...params,
    //   offset: search === "" ? params.offset : 0,
    // }),
    queryKey: [REACT_QUERY_KEYS.GET_PRODUCTCODES, search, params],
    enabled: search === "" || search?.length > 1,
    select: (res) => {
      return res.data?.map((pr) => ({
        ...pr,
        value: pr.id,
        label: `${pr.family} (${pr.extra_info})`,
      }));
    },
  });

  const { t } = useTranslation();
  return (
    <>
      {disabled ? (
        <FormSelect
          {...rul}
          label={t("lab.product-name")}
          placeholder={t("inner-fcc.tanla")}
          name={`${fieldName}.${ind}.name_id`}
          options={prs}
          disabled={disabled}
          loading={prsLoading}
          notFilteredOption={true}
          onSearch={(e) => {
            setSearch(e);
          }}
        />
      ) : (
        <HsCodePModal
          {...rul}
          label={t("lab.product-name")}
          placeholder={t("inner-fcc.tanla")}
          name={`${fieldName}.${ind}.name_id`}
          options={prs}
          loading={prsLoading}
          onSearch={(e) => setSearch(e)}
          search={search}
          setParams={setParams}
          params={params}
        />
      )}

      {/* <FormSelect
        label={t("lab.product-name")}
        placeholder={t("inner-fcc.tanla")}
        name={`${fieldName}.${ind}.name_id`}
        options={prs}
        disabled={disabled}
        onChange={(e) => {
          const data = prs?.find((p) => p.value === e);
          setValue(`${fieldName}.${ind}.product_data`, data);
        }}
        loading={prsLoading}
        notFilteredOption={true}
        onSearch={(e) => {
          setSearch(e);
        }}
      /> */}
    </>
  );
};
