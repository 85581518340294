import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/layout/Layout";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";

import { ConfigProvider } from "antd";
import "./App.css";
import SelectLogin from "./components/signin/SelectLogin";
import { API_URL } from "./constants/variables";
import { LabExportAct } from "./pages/acts/export";
import { LabImportAct } from "./pages/acts/import/page";
import Comparison from "./pages/comparison/comparison";
import LabSample from "./pages/lab/Sample";
import ExpertiseExport from "./pages/lab/expertiseExport";
import { SeedExportAct } from "./pages/seed-export/pages/act";
import ActComparison from "./pages/special";
import ExportFss from "./pages/special/ExportFss";

export const baseURL = API_URL;
const App = () => {
  const { token } = useSelector((state) => state.login);
  const [menu, setMenu] = useState(true);
  const pathname = window.location.pathname;

  useEffect(() => {
    if (window.innerWidth < 992) {
      setMenu(false);
    } else {
      setMenu(true);
    }

    // eslint-disable-next-line
  }, [window.innerWidth]);

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#036323",
          },
        }}
      >
        <>
          <ToastContainer />
          <Switch>
            <Route path="/efito/act-invoice">
              <ActComparison />
            </Route>
            <Route path="/lab/import-sample">
              <LabSample />
            </Route>
            <Route path="/lab/view/export/">
              <ExpertiseExport />
            </Route>
            <Route path="/efito/import-fss/print">
              <ExportFss />
            </Route>

            {/* New pages  */}
            <Route path="/act/lab-export/:id" component={LabExportAct} />
            <Route path="/act/lab-import/:id" component={LabImportAct} />
            <Route path="/act/seed-export/:id" component={SeedExportAct} />
            {/* New pages  end*/}

            {token && (
              <Route path="/act-invoice">
                <Comparison />
              </Route>
            )}
            {token && pathname !== "/act/seed-export" ? (
              <>
                <Navbar menu={menu} setMenu={setMenu} />
                <Sidebar menu={menu} setMenu={setMenu} />
                <Layout baseURL={baseURL} menu={menu} setMenu={setMenu} />
              </>
            ) : (
              <>
                <SelectLogin />
              </>
            )}
          </Switch>
        </>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
