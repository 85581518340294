import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalDetail } from "../modal";

export const ModalButton = ({ data, getList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  const config = {
    title: t("close modal"),
    content: t("close modal desc"),
    icon: <ExclamationCircleOutlined />,
    okText: t("close"),
    cancelText: t("lab.back"),
    centered: true,
    onOk: () => {
      setIsModalOpen(false);
    },
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    modal.confirm(config);
  };

  return (
    <>
      {data ? (
        <Tooltip placement="topLeft" title={t("edit")}>
          <div
            onClick={showModal}
            className="bg-circle bg-circle-editable"
          ></div>
        </Tooltip>
      ) : (
        <button className="button-active" onClick={showModal}>
          {t("inner-fcc.single")}
        </button>
      )}
      <Modal
        open={isModalOpen}
        centered
        footer={null}
        className="my-modal"
        width={1200}
        destroyOnClose={true}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <ModalDetail
          getList={getList}
          data={data}
          open={isModalOpen}
          onCancel={handleCancel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      {contextHolder}
    </>
  );
};
