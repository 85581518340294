import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import redirectUrl from "../../utils/navigate";
import FieldDetailModal from "./FieldDetail";
import FieldEditModal from "./FieldEdit";
import InnerProductsModal from "./InnerProductsModal";
import ProcessModal from "./ProcessModal";

const FieldTableRow = ({ item, index, date, offset, allRegions, getList }) => {
  const { t } = useTranslation();

  const [openInfo, setOpenInfo] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  //   console.log(item?.used_chemical_reactives);
  return (
    <tr key={index} className={item?.editable ? "is-editable" : ""}>
      <td>{offset + index + 1}</td>
      <td>
        <span
          onClick={() => setOpenInfo(true)}
          style={{ color: "#036323", cursor: "pointer" }}
        >
          {item?.request_number}
        </span>
        <br />
        {item?.protocol_number ? `${t("lab.exp-number")}` : ""}
        <br />
        <a
          href={`${redirectUrl}act/lab-export/${item?.protocol_number}`}
          target="_blank"
          rel="noreferrer"
        >
          {item?.protocol_number ? item?.protocol_number : ""}
        </a>
      </td>
      <td>
        {item?.applicant_name}
        <br />
        {item?.applicant_tin}
      </td>
      <td>+998 {item?.representative_phone}</td>
      <td>
        {date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-
        {date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1}
        -{date.getFullYear()}
        <br />
        {date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
        {date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:
        {date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}
      </td>
      <td>
        <span
          onClick={() => setOpenAbout(true)}
          style={{ color: "#036323", cursor: "pointer" }}
        >
          {item?.status_name}
        </span>
      </td>
      <td>
        <div
          className={
            item?.editable ? "bg-circle bg-circle-editable" : "bg-circle"
          }
          onClick={() => (item?.editable ? setOpenEdit(true) : null)}
        ></div>
      </td>
      <td>
        <div className="bg" onClick={() => setOpenProducts(true)}></div>
      </td>
      <ProcessModal
        openAbout={openAbout}
        setOpenAbout={setOpenAbout}
        steps={item?.status_steps}
      />
      <FieldDetailModal
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        allRegions={allRegions}
        item={item}
        getList={getList}
      />
      <InnerProductsModal
        openProducts={openProducts}
        setOpenProducts={setOpenProducts}
        products={item?.products}
      />

      <FieldEditModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        item={item}
        allRegions={allRegions}
        getList={getList}
      />
    </tr>
  );
};

export default FieldTableRow;
