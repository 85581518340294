import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import request from "../../helpers/createRequest";

const InnerProductsModal = (props) => {
  const { setOpenProducts, openProducts, products } = props;
  const { t } = useTranslation();
  const [districts, setDistrics] = useState([]);

  return (
    <Modal
      open={openProducts}
      onOk={() => setOpenProducts(false)}
      onCancel={() => setOpenProducts(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={1060}
      className="add-fcc-modal"
    >
      <h1>{t("inner-fcc.prod")}</h1>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("lab.product-name")}</th>
              <th>{t("lab.product-type")}</th>
              <th>{t("lab.yield")}</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((pro, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{pro.name}</td>
                  <td>{pro.type}</td>
                  <td>
                    {pro.productivity_amount}
                    {pro.productivity_unit == 166 ? " Kg" : " Tn"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default InnerProductsModal;
