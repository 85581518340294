import { Empty, Pagination } from "antd";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { fetchRequest, fetchRequestMineral } from "../../helpers/createRequest";
import queryString from "query-string";
import { useSelector } from "react-redux";

const Minerals = (props) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.language.lang);

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 6;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  const [count, setCount] = useState(0);

  const [filter, setFilter] = useState(true);
  const [minerals, setMinerals] = useState([]);
  const [categories, setCategories] = useState([]);

  const [category, setCategory] = useState();

  useEffect(() => {
    if (_.isEmpty(categories)) {
      getCategories();
    }
    getMinirals();
  }, [queryParams.page, category, queryParams.name]);

  async function getCategories() {
    await fetchRequestMineral
      .get(`api/biocides/`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function getMinirals() {
    setIsLoading(true);
    await fetchRequestMineral
      .post(`api-token-auth/`, {
        username: "f585344559",
        password: "f585344559",
      })
      .then((res) => {
        // console.log(res.data.token);
        fetchRequestMineral
          .get(
            `ppp/api/registration-protocols/list/?limit=${LIMIT}&offset=${OFFSET}`,
            {
              headers: {
                Authorization: `Token ${res.data.token}`,
                "Content-Type": "application/json",
              },
              params: {
                page: queryParams.page,
                biocide_id: category,
                key_word: queryParams.name,
              },
            }
          )
          .then((res) => {
            setMinerals(res.data.results);
            setCount(res.data.count);
          })
          .catch((e) => {
            console.log(e);
          });
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  const onFilter = (itemValue, itemKey) => {
    const rootParams = { ...queryParams };
    if (itemValue) {
      rootParams[itemKey] = itemValue;
      rootParams.page = null;
    } else {
      rootParams[itemKey] = null;
    }
    props.history.push({
      search: queryString.stringify(rootParams, { skipNull: true }),
    });
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="balance-page">
      <div className="top">
        <h1>{t("agro.mineral")}</h1>
        <div className="right">
          <div style={{ display: "flex" }}>
            <div
              className="filter-icon"
              onClick={() => setFilter(!filter)}
            ></div>
          </div>
        </div>
      </div>

      <div className={`${filter ? "filter active-filter" : "filter"}`}>
        <input
          type="text"
          placeholder={t("common.name")}
          value={queryParams.name || ""}
          onChange={(e) => onFilter(e.target.value, "name")}
        />
        <select
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
          }}
        >
          <option value={""}>{t("common.category")}</option>
          {categories?.map((s, idx) => (
            <option value={s.id} key={idx}>
              {/* {`${`name_${lang}`}`} */}
              {s[`name_${lang}`]}
            </option>
          ))}
        </select>

        <button
          onClick={(e) => {
            getMinirals();
            onPageChange(1);
          }}
        >
          {t("balance.show")}
        </button>
      </div>

      {isLoading ? <Loader /> : null}

      {!isEmpty(minerals) && (
        <div className="cards-page">
          <div className="agro-inner-cards">
            {minerals.map((cli, idx) => (
              <div className="agro-inner-card" key={idx}>
                <div className="card-info">
                  <div className="card-title">
                    <h2>{cli.biocide_trade_name + " " + cli.concentration}</h2>
                  </div>
                  <div className="card-text">
                    <p>
                      {t("agro.category")}:{" "}
                      <strong>{cli.biocide[`name_${lang}`]}</strong>
                    </p>
                    <p>
                      {t("agro.active")}:{" "}
                      <strong>{cli.irritant_substance}</strong>
                    </p>

                    <p>
                      {t("agro.company")}: <strong>{cli.applicant_name}</strong>
                    </p>
                    <p>
                      {t("agro.cropType")}:{" "}
                      <strong>{cli.scopes[0][`plant_type_${lang}`]}</strong>
                    </p>
                    <p>
                      {t("agro.goal")}:{" "}
                      <strong>
                        {cli.scopes[0][`harmful_organisms_${lang}`] || "--"}
                      </strong>
                    </p>
                    <p>
                      {t("agro.usage")}:{" "}
                      <strong>{cli.scopes[0][`scope_${lang}`] || "--"}</strong>
                    </p>
                    <p>
                      {t("agro.season")}:{" "}
                      <strong>{cli.scopes[0]?.time || "--"}</strong>
                    </p>
                    <p>
                      {t("agro.expiry")}: <strong>{cli.expire_date}</strong>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {_.isEmpty(minerals) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
      {!isLoading && !_.isEmpty(minerals) && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Minerals;
