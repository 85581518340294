import { useQuery } from "@tanstack/react-query";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  getCountries,
  getDistrictsByRegionId,
  getRegionById,
  getRegions,
  getUnits,
} from "../../../apis/acts/common";
import { getLabImportById } from "../../../apis/acts/lab";
import Loader from "../../../components/loader/Loader";
import { REACT_QUERY_KEYS } from "../../../constants/reactQueryKeys";

export const Hoc = ({ children, params }) => {
  const id = params?.id ?? false;
  const form = useForm();

  useQuery({
    queryFn: getRegions,
    queryKey: [REACT_QUERY_KEYS.GET_REGIONS],
    select: (res) => res.data,
    onSuccess: (res) => {
      form.setValue("regions", res);
    },
  });

  const { data, isFetching } = useQuery({
    queryFn: () => getLabImportById(id),
    queryKey: [REACT_QUERY_KEYS.LAB_EXPORT_BY_ID, id],
    select: (res) => res.data,
    enabled: !!id,
    onSuccess: (res) => {
      form.setValue("data", res);
    },
  });

  useQuery({
    queryFn: () => getRegionById(data?.lab),
    queryKey: [REACT_QUERY_KEYS.GET_REGION_BY_ID, data?.lab],
    enabled: !!data?.lab,
    select: (res) => res.data,
    onSuccess: (res) => {
      form.setValue("region", res);
    },
  });

  useQuery({
    queryFn: () => getRegionById(data?.manufactured_region),
    queryKey: [REACT_QUERY_KEYS.GET_REGION_BY_ID, data?.manufactured_region],
    enabled: !!data?.manufactured_region,
    select: (res) => res.data,
    onSuccess: (res) => {
      form.setValue("manufactured_region", res);
    },
  });

  useQuery({
    queryFn: () => getDistrictsByRegionId(data?.manufactured_region),
    queryKey: [REACT_QUERY_KEYS.GET_DISTRICTS, data?.manufactured_region],
    enabled: !!data?.manufactured_region,
    select: (res) => res.data,
    onSuccess: (res) => {
      form.setValue(
        "manufactured_district",
        res?.find((d) => d.id === data?.manufactured_district)
      );
    },
  });
  useQuery({
    queryFn: getUnits,
    queryKey: [REACT_QUERY_KEYS.GET_UNITS],
    select: (res) => res.data,
    onSuccess: (res) => {
      form.setValue("units", res);
    },
  });
  useQuery({
    queryFn: getCountries,
    queryKey: [REACT_QUERY_KEYS.GET_COUNTRIES],
    select: (res) => res.data,
    enabled: !!data?.exporter_country,
    onSuccess: (res) => {
      const country = res?.find((c) => c.id === data?.exporter_country);
      form.setValue("country", country);
    },
  });

  if (isFetching) {
    return <Loader />;
  } else if (data) {
    return (
      <FormProvider {...form}>
        <form>{children}</form>
      </FormProvider>
    );
  } else {
    return <Loader />;
  }
};
