import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProcessModal from "./ProcessModal";
import ProductsModal from "./ProductsModal";

const TableRow = ({ item, index, date, offset }) => {
  const { t } = useTranslation();

  const [openAbout, setOpenAbout] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);

  return (
    <tr key={index}>
      <td>{offset + index + 1}</td>
      <td>
        {item?.request_number}
        <br />
        {item?.akd_number ? `${t("navbar.akd")}` : ""}
        <br />
        <a href={`${item?.link}`} target="_blank">
          {item?.akd_number ? item?.akd_number : ""}
        </a>
      </td>
      <td>
        {item?.importer_name}
        <br />
        {item?.importer_tin}
      </td>
      <td>{item?.transport_number}</td>
      <td>
        {date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-
        {date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1}
        -{date.getFullYear()}
        <br />
        {date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
        {date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:
        {date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}
      </td>
      <td>
        <span
          onClick={() => setOpenAbout(true)}
          style={{ color: "#036323", cursor: "pointer" }}
        >
          {item?.status_name}
        </span>
      </td>

      <td>
        <div className="bg" onClick={() => setOpenProducts(true)}></div>
      </td>
      <ProcessModal
        openAbout={openAbout}
        setOpenAbout={setOpenAbout}
        steps={item?.status_steps}
      />
      <ProductsModal
        openProducts={openProducts}
        setOpenProducts={setOpenProducts}
        products={item?.products}
      />
    </tr>
  );
};

export default TableRow;
