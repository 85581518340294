import { Button, Modal, Tooltip } from "antd";
import { useState } from "react";
import { ExcelModalDetail } from "./detail";
import { ReactComponent as IconExcel } from "../../../../assets/icons/excel.svg";
import { useTranslation } from "react-i18next";

export const ExcelImport = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Tooltip title={t("excel.title")}>
        <Button
          onClick={showModal}
          icon={<IconExcel />}
          style={{ display: "flex", alignItems: "center" }}
        >
          {t("excel.text")}
        </Button>
      </Tooltip>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        className="my-modal"
        width={500}
        destroyOnClose={true}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <ExcelModalDetail handleCancel={handleCancel} />
      </Modal>
    </>
  );
};
