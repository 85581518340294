import { Modal } from "antd";
import { MdClose } from "react-icons/md";
import React, { useEffect, useState } from "react";
import "./fumigation.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import request from "../../helpers/createRequest";
import axios from "axios";
import { BASE_URL } from "../../constants/variables";

const EditFumigation = (props) => {
    const { token } = useSelector((state) => state.login);
    const { t } = useTranslation();
    const lang = useSelector((state) => state.language.lang);

    const {
        pinOrTin,
        name: nameUser,
    } = useSelector((state) => state.login);

    const {
        openEdit,
        setOpenEdit,
        item,
        getList,
        setOpen
    } = props;

    const [isBtn, setIsBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isValidNumber, setIsValidNumber] = useState(true)

    const [phoneNumber, setPhoneNumber] = useState(`+998${item?.applicant_phone}`);
    const [applicantName, setApplicantName] = useState(item?.applicant_name);

    const [regionId, setRegionId] = useState(item?.region);
    const [allRegions, setAllRegions] = useState([]);
    const [districtId, setDistrictId] = useState(item?.district);
    const [allDistricts, setAllDiscticts] = useState([]);
    const [object, setObject] = useState(item?.disinfected_object);
    const [allObjects, setAllObjects] = useState([]);
    const [activity, setActivity] = useState(item?.type);

    const activityTypeOptions = [
        { value: 1, label_uz: "Eksport", label_ru: "Экспорт" },
        { value: 2, label_uz: "Import", label_ru: "Импорт" },
        { value: 3, label_uz: "Mahalliy", label_ru: "Внутренний" },
    ]

    const getRegions = () => {
        request
            .get(`/api/regions/`)
            .then((res) => {
                setAllRegions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDistricts = () => {
        if (regionId) {
            request
                .get(`/api/districts/?region=${regionId}`)
                .then((res) => {
                    setAllDiscticts(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const getObjects = () => {
        request
            .get(`/api/disinfected-objects/`)
            .then((res) => {
                setAllObjects(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const submitCertificate = async (e) => {
        e.preventDefault();

        const data = {
            organization_name: nameUser,
            organization_tin: pinOrTin,
            applicant_name: applicantName,
            applicant_phone: phoneNumber.slice(-9),
            region: regionId,
            district: districtId,
            type: activity,
            disinfected_object: object,
            request_number: item?.request_number,
            request_date: item?.request_date,
            status: item?.status + 1
        };

        setIsLoading(true)

        try {
            await axios.put(`${BASE_URL}applicant/certificate-of-disinfestation-application/list/`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            toast.success('Ariza muvaffaqqiyatli yuborildi!')
            setOpenEdit(false);
            setApplicantName('')
            setPhoneNumber("");
            setRegionId("");
            setDistrictId("");
            setIsBtn(false);
            setActivity('')
            setObject('')
            getList();
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getRegions();
        getObjects()
    }, []);

    useEffect(() => {
        getDistricts();

        // eslint-disable-next-line
    }, [regionId]);

    const checkBtn = () => {
        if (
            regionId &&
            districtId &&
            regionId &&
            activity &&
            object
        ) {
            setIsBtn(true);
        }
    };
    useEffect(() => {
        checkBtn();
        // eslint-disable-next-line
    }, [regionId, districtId, activity, object]);

    function isValidString(input) {
        var regex = /^[+][0-9]+$/;

        if (phoneNumber.length === 13) {
            setIsValidNumber(regex.test(input))
        } else {
            setIsValidNumber(false)
        }
    }

    useEffect(() => {
        isValidString(phoneNumber)
        // eslint-disable-next-line
    }, [phoneNumber])

    return (
        <Modal
            open={openEdit}
            onOk={() => setOpenEdit(false)}
            onCancel={() => setOpenEdit(false)}
            centered
            title={null}
            footer={null}
            closeIcon={<MdClose className="icon" />}
            width={1060}
            className="add-fcc-modal"
        >
            <h1>{t("inner-fcc.izm")}</h1>
            <form onSubmit={submitCertificate}>
                <div className="roow">
                    <div>
                        <label>{t("fumig.applicant")}</label>
                        <input type="text" value={nameUser} readOnly />
                    </div>
                    <div>
                        <label>{t("inner-fcc.inn")}</label>
                        <input type="text" value={pinOrTin} readOnly />
                    </div>
                </div>
                <div className="roow">
                    <div style={{ position: "relative" }}>
                        <label>{t("inner-fcc.phone-num")}</label>
                        <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                        {
                            isValidNumber ? null : <span style={{ color: 'red', fontSize: '14px', position: 'absolute', bottom: '-10px', zIndex: '1', backgroundColor: '#fff', left: '10px' }}>Telefon raqamni kiritishda xatolik!</span>
                        }
                    </div>
                    <div>
                        <label>{t("fumig.orderer")}</label>
                        <input
                            type="text"
                            value={applicantName}
                            onChange={(e) => setApplicantName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="roow">
                    <div>
                        <label>{t("fumig.region")}</label>
                        <select
                            value={regionId}
                            onChange={(e) => setRegionId(e.target.value)}
                            disabled={!!item?.fumigator}
                        >
                            <option hidden>{t("inner-fcc.tanla")}</option>
                            {allRegions?.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name_local}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div>
                        <label>{t("fumig.district")}</label>
                        <select
                            value={districtId}
                            onChange={(e) => setDistrictId(e.target.value)}
                        >
                            <option hidden>{t("inner-fcc.tanla")}</option>
                            {allDistricts?.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name_local}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div className="roow">
                    <div>
                        <label>{t("inner-fcc.export_import")}</label>
                        <select
                            value={activity}
                            onChange={(e) => setActivity(e.target.value)}
                        >
                            <option hidden>{t("inner-fcc.tanla")}</option>
                            {activityTypeOptions?.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>
                                        {item[`label_${lang}`]}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div>
                        <label>{t('inner-fcc.object_product')}</label>
                        <select value={object} onChange={(e) => setObject(e.target.value)}>
                            <option hidden>{t("inner-fcc.tanla")}</option>
                            {allObjects?.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item[`name_${lang}`]}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div className="btn-wrap">
                    <button
                        type="submit"
                        className={isBtn && isValidNumber && !isLoading ? "button-active" : ""}
                        disabled={!isBtn && !isValidNumber && isLoading}
                    >
                        {t("inner-fcc.resend")}
                    </button>
                </div>

                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p style={{ color: '#000', fontSize: '18px', cursor: 'pointer' }} onClick={() => setOpen(true)}>
                        <b>Izoh:</b> <i style={{ color: 'blue' }}>{t("fumig.price-list")}</i>
                    </p>
                </div>
            </form>
        </Modal>
    );
};

export default EditFumigation;
