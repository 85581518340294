import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./warning.css";
import { useTranslation } from "react-i18next";
import WarningIcon from "../../assets/icons/warningIcon.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { language } from "../../redux/actions/langAction";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const LabWarning = (props) => {
  //   const dispatch = useDispatch();
  const { t } = useTranslation();
  const { warningOpen, setWarningOpen, setWarningAccepted } = props;

  //   useEffect(() => {

  //     if (!pop_status) {
  //       setOpen(true);
  //       localStorage.setItem("pop_status", 1);
  //     }
  //     if (pop_status == 1) {
  //       setOpen(true);
  //     }
  //     setTimeout(() => {
  //       setIsBtn(true);
  //     }, 5000);
  //   }, []);

  const closeWarning = () => {
    setWarningOpen(false);
    setWarningAccepted(true);
  };

  //   const renderTime = ({ remainingTime }) => {
  //     if (remainingTime === 0) {
  //       return <div className="timer">Too lale...</div>;
  //     }

  //     return (
  //       <div className="timer">
  //         <div className="text">Remaining</div>
  //         <div className="value">{remainingTime}</div>
  //         <div className="text">seconds</div>
  //       </div>
  //     );
  //   };

  //   if (!open) return null;

  return (
    <Modal
      open={warningOpen}
      centered
      title={null}
      footer={null}
      //   closeIcon={null}
      closable={false}
      width={1060}
      className="add-fcc-modal"
    >
      <h1 className="warning">
        <img src={WarningIcon} />
      </h1>

      <div className="modal-inner warning">
        <div className="warning-wrap common">
          <p>
            <strong>Eslatma:</strong> O‘zbekiston Respublikasi Vazirlar
            Mahkamasining 2021-yil 22-yanvardagi “O‘simliklar karantini sohasida
            monitoring yuritish va fitosanitar dala nazoratini amalga oshirish
            tartibi to‘g‘risidagi nizomni tasdiqlash haqida”gi 32-son qaroriga
            muvofiq, 2021-yilning 1-may sanasidan boshlab O‘zbekiston
            Respublikasi Moliya vazirligi bilan kelishilgan holda mamlakatimizga
            import bo‘luvchi hamda eksport qilinuvchi meva-sabzavot va o‘simlik
            mahsulotlarini laboratoriya ekspertizasidan o‘tkazish xizmati rasman
            pullik etib belgilandi. Unga ko‘ra, laboratoriya xizmatlari narxida
            har bir namuna uchun 88 563 so‘m miqdorida to‘lov joriy etilib,
            ushbu narxga kimyoviy reaktiv va bir martalik ishlatiladigan
            mahsulotlar narxi alohida hisoblab qo‘shiladi. To‘lov oferta
            shartnoma asosida amalga oshiriladi.{" "}
            <strong>
              100 % to‘lov amalga oshirilgandan so‘ng laboratoriya tahlillari
              amalga oshiriladi.
            </strong>
          </p>
          <p>
            <i>
              <strong>Eslatma:</strong> https://karantin.uz/ saytidan
              “laboratoriya tekshiruvlari/ekspertizasi xizmati”ni belgilab,
              agentlik nomiga “O‘simliklar karantini va himoyasi agentligi”ni
              tanlab oferta shartnomasi rasmiylashtiriladi.
            </i>
          </p>
        </div>
      </div>

      <div className="btn-wrap warning">
        <button
          type="button"
          className={"button-active btn-red"}
          //   disabled={!isBtn}
          onClick={closeWarning}
        >
          {t("common.read")}
        </button>
      </div>
    </Modal>
  );
};

export default LabWarning;
