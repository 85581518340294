import React, { useEffect, useRef, useState } from "react";
import "./expertise.css";
// import logo from "../../assets/images/logo.svg";
import labIcon from "../../assets/images/lab-icon.png";
import logo from "../../assets/images/main-logo.webp";
import queryString from "query-string";
import ReactToPrint from "react-to-print";
import request, { requestPublic } from "../../helpers/createRequest";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { withRouter } from "react-router-dom";

const ExpertiseExport = (props) => {
  const componentRef = useRef();

  const queryParams = queryString.parse(window.location.search);
  const number = queryParams.number;

  const [expertise, setExpertise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [allRegions, setAllRegions] = useState([]);
  const [allDistricts, setAllDistrics] = useState([]);

  useEffect(() => {
    if (number) {
      fetchData();
    } else {
      setIsLoading(false);
      toast.error("Xatolik!");
    }
    // getCountries();

    getRegions();
  }, [number]);

  useEffect(() => {
    if (expertise?.manufactured_region) {
      getDistricts(expertise?.manufactured_region);
    }
  }, [expertise]);

  const getRegions = () => {
    request
      .get(`api/regions`)
      .then((res) => {
        setAllRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDistricts = (regionId) => {
    if (regionId) {
      request
        .get(`api/districts/?region=${regionId}`)
        .then((res) => {
          setAllDistrics(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchData = async () => {
    await requestPublic
      .get(`applicant/lab/protocol/${number}/detail/`)
      .then((res) => {
        if (res.data) {
          setExpertise(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.warning("Natija topilmadi!");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status == 451) {
          toast.error("Ushbu oferta raqamiga joriy xizmat ko'rsatilmaydi!");
        } else {
          toast.error("Xatolik!");
        }
      });
  };

  const displayUnit = (unit) => {
    return unit == 15 ? "кг" : "тн";
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        expertise && (
          <div className="file-container">
            <div className="down-btn1">
              <ReactToPrint
                trigger={() => <button className="btn-icon">Chop etish</button>}
                content={() => componentRef.current}
                pageStyle="@page { margin: 15px !important;}"
              />
              {/* <span className="tooltip">{t("invoice.print")}</span> */}
            </div>
            <page
              size="A4"
              style={{ width: "21cm", height: "29.7cm", fontFamily: "serif" }}
              ref={componentRef}
            >
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
                <div
                  style={{
                    width: "34%",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  ЖИЗЗАХ ВИЛОЯТИ ЎСИМЛИКЛАР КАРАНТИНИ ВА ҲИМОЯСИ БОШҚАРМАСИ “ФИТОСАНИТАРИЯ ЛАБОРАТОРИЯСИ”
                </div>
                <div style={{ width: "14%" }}>
                  <img
                    src={logo}
                    style={{ width: "100%", marginTop: "0px", height: "auto" }}
                  />
                </div>
                <div
                  style={{
                    width: "34%",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  “PHYTOSANITARY LABORATORY” OF THE PLANT PROTECTION AND QUARANTINE DEPARTMENT OF JIZZAKH REGION
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "32%", textAlign: "center" }}>
                  Жиззах шаҳри, M.Ulug'bek МФЙ, Зомин к
                </div>
                <div style={{ width: "32%", textAlign: "center" }}>
                  Zamin Street, M.Ulugbek MFY, Jizzakh City.
                </div>
              </div>

              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  alignItems: "center",
                  marginBottom: "25px",
                  marginTop: "15px",
                  lineHeight: "17px",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                ></div>
                <div
                  style={{
                    width: "35%",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  ЭКСПЕРТИЗА ХУЛОСАСИ ЗАКЛЮЧЕНИЕ ЭКСПЕРТИЗЫ CONCLUSIONS OF
                  EXPERTISE {<br></br>}
                  (Экспорт){<br></br>} (Экспорт){<br></br>} (Export)
                </div>
                <div
                  style={{
                    width: "30%",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  <img src={labIcon} style={{ width: "100%" }} />
                </div>
              </div>
              <table className="lab-print-table">
                <tbody>
                  <tr>
                    <td rowSpan={3} colSpan={3}>
                      Ушбу экспертиза хулосаси берилди/ Заключения экспертизы
                      выдано/ This expert opinion is issued:{" "}
                      {expertise?.applicant_name}
                    </td>
                    <td
                      colSpan={2}
                      // style={{ maxWidth: "200px" }}
                      style={{ width: "40%" }}
                    >
                      Экспертиза рақами / Номер экспертизы/ Expertise number{" "}
                      {<br></br>}
                      {<br></br>}
                      {expertise?.number}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                    // style={{ maxWidth: "200px" }}
                    >
                      Берилди /Выдано/ Issued {<br></br>}{" "}
                      {expertise?.given_date}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                    // style={{ maxWidth: "200px" }}
                    >
                      Саҳифа / Страница/ Page {<br></br>} 1 /1
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      Маҳсулот ҳақида маълумот /Информация о продукции /Product
                      information
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={2} colSpan={3}>
                      Маҳсулот номи, миқдори ва ўлчов бирлиги /Наименование,
                      количество и ед-измер. продукции /Name, quantity and unit
                      of the product
                      <br />
                      {expertise?.products
                        ?.map((item, idx) => {
                          return (
                            item.name +
                            "-" +
                            item.hs_code +
                            " " +
                            item.productivity_amount +
                            " " +
                            displayUnit(item.poductivity_unit)
                          );
                        })
                        ?.join(", ")}
                    </td>
                    <td colSpan={2}>
                      Маҳсулот етиштирилган давлат ва ҳудуд/ Страна и регион
                      происхождения продукции/ Country and region of origin{" "}
                      {<br></br>}
                      {
                        allRegions?.filter(
                          (reg) => reg.id == expertise?.manufactured_region
                        )[0]?.name_local
                      }
                      ,{" "}
                      {
                        allDistricts?.filter(
                          (reg) => reg.id == expertise?.manufactured_district
                        )[0]?.name_local
                      }
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Давлат инспектор Ф.И.Ш./ Ф.И.О.Государственного
                      инспектора/ Full name State inspector {<br></br>}{" "}
                      {expertise?.inspector}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5} style={{ borderBottom: "none" }}>
                      Танлаб олинган намуна ҳақида маълумот / Информация об
                      отборе проб/ Sampling information
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="lab-print-table">
                <tbody>
                  <tr>
                    <td>
                      Ёрлиқ рақами №/ номер ярлыка №/ label number №:{<br></br>}{" "}
                      {expertise?.shortcut_number}
                    </td>
                    <td>
                      Намуна сони / Количество образцов / Number of samples{" "}
                      {<br></br>} {expertise?.number_of_sample}
                    </td>
                    <td>
                      Намуна танлаб олинган санаси / Дата отбора образцов /
                      Sampling date {<br></br>} {expertise?.sampling_date}
                    </td>
                    <td>
                      МФЛ Рўйхатга олинган № /ЦФЛ Регистрационный № /CРL
                      Registration number {<br></br>} №{" "}
                      {expertise?.notification_number}
                    </td>
                    <td>
                      МФЛ га қабул қилинган сана/ Дата поступления в ЦФЛ/ Date
                      of admission to the central quarantine laboratory{" "}
                      {<br></br>} {expertise?.shortcut_given_date}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      Танлаб олиш услуби / Метод отбора /Sampling method: ГОСТ
                      12430-2019: Ўсимликлар карантини Карантин фитосанитария
                      текшируви ва лаборатория тадқиқотлари учун карантин
                      остидаги маҳсулотлардан намуна олиш усуллари ва меъёрлари
                      /Методы и нормы отбора образцов подкарантинной продукции
                      при карантинном фитосанитарном досмотре и лабораторных
                      исследованиях /Methods and standards for sampling
                      regulated products during quarantine phytosanitary
                      inspection and laboratory research
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      Экспертиза натижалари / Результаты экспертизы/ Expertise
                      results
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5} style={{ borderBottom: "none" }}>
                      Норматив хужжатлар / НД на методы испытаний / ND for test
                      methods:{" "}
                      {expertise?.implemented_lab_test_methods
                        ?.map((item, idx) => item.normative_document)
                        ?.join(", ")}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="lab-print-table">
                <tbody>
                  <tr>
                    <td colSpan={5}>
                      Қўшимча маълумот /Дополнительная информация /Additional
                      Information
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ height: "95px" }}>
                      Лаборатория хулосаси / Заключение лаборатории / Laboratory
                      conclusion {expertise?.conclusion}
                    </td>
                    <td colSpan={2} style={{ width: "40%" }}>
                      <img src={`../../images/${expertise?.stamp_name}.jpg`} alt="image" style={{ width: '100%' }} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      Субподряд экспертизаси / Экспертиза проведённая по
                      субподряду/ Subcontracted expertise
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      Экспертиза натижалари фақат олинган намуналарга тегишли /
                      Результаты экспертизы применимы только к полученным
                      образцам/ The results of the examination are applicable
                      only to the received samples.
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Марказий фитосанитария лаборатория мудири/ Заведующий
                      Центральной фитосанитарнной лаборатории/ Head of the
                      Central Phytosanitary Laboratory
                    </td>
                    <td colSpan={3} style={{ width: "60%" }}>
                      Ф.И.О {<br></br>} {expertise?.head_of_lab} {<br></br>}
                      {<br></br>}
                      Имзо/Подпись/Signature
                    </td>
                  </tr>
                </tbody>
              </table>
            </page>
          </div>
        )
      )}
    </>
  );
};

export default withRouter(ExpertiseExport);
