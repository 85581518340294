export const BOX_TYPE = [
  {
    value: 1,
    label: "packaging",
  },
  {
    value: 2,
    label: "no-packaging",
  },
];
