import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { checkSertificateExportFcc } from "../../../../../../apis/innerFss";
import { FormInput } from "../../../../../../components/formComponents/input";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
import { Container, Popup } from "./style";
import { Spin } from "antd";
import { useState } from "react";

export const ExportFcc = (props) => {
  const { t } = useTranslation();
  const { setValue, watch, getValues, setError } = useFormContext();
  const [focused, setFocused] = useState(false);
  const { data, isFetching } = useQuery({
    queryFn: () => checkSertificateExportFcc(getValues("export_fss_number")),
    queryKey: [REACT_QUERY_KEYS.CHECK_EXPORT_FCC, watch("export_fss_number")],
    enabled: watch("export_fss_number")?.length === 11,
    onError: () => {
      setValue(
        "export_fss_number",
        getValues("export_fss_number")?.slice(0, 10)
      );
      setError("export_fss_number", {
        type: "manual",
        message: "fcc_error",
      });
    },
  });
  return (
    <Container
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <FormInput
        {...props}
        label={t("export_fss_number")}
        placeholder={t("export_fss_number")}
        name="export_fss_number"
        type="number"
        min="1"
        rules={{
          required: {
            value: true,
            message: "inner-fcc.error",
          },
          maxLength: {
            value: 11,
            message: "fccLengthError",
          },
          minLength: {
            value: 11,
            message: "fccLengthError",
          },
        }}
      />
      {isFetching && (
        <Popup style={{ minWidth: "250px", alignItems: "center" }}>
          <Spin />
        </Popup>
      )}
      {data?.data && focused && (
        <Popup>
          <span>
            {t("exporter_applicant")}: <b>{data.data.applicant}</b>
          </span>
          <span>
            {t("inner-fcc.tnum")}: <strong>{data.data.transport_number}</strong>
          </span>
        </Popup>
      )}
    </Container>
  );
};
