import { USER_LOGIN, USER_LOGOUT } from "../types/Types"

export const userLogin = (data) => {

    window.localStorage.setItem('user_data', JSON.stringify(data))

    return {
        type: USER_LOGIN,
        payload: data
    }
}

export const userLogout = () => {

    window.localStorage.removeItem('user_data')
    window.localStorage.removeItem('pin_data')

    return {
        type: USER_LOGOUT
    }
}