import { Empty, Pagination } from "antd";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { fetchRequest } from "../../helpers/createRequest";
import queryString from "query-string";
import "./inner.css";

const Analysis = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(true);

  const [analysis, setAnalysis] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState();

  useEffect(() => {
    if (_.isEmpty(regions)) {
      getRegions();
    }
    getAnalysis();
  }, [region]);

  async function getRegions() {
    await fetchRequest
      .get(`common/regions/`)
      .then((res) => {
        setRegions(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function getAnalysis() {
    setIsLoading(true);
    await fetchRequest
      .get(`agro/tahlil/`, {
        params: {
          region: region,
        },
      })
      .then((res) => {
        setAnalysis(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  return (
    <div className="balance-page">
      <div className="top">
        <h1>{t("agro.labs")}</h1>
        <div className="right">
          <div style={{ display: "flex" }}>
            <div
              className="filter-icon"
              onClick={() => setFilter(!filter)}
            ></div>
          </div>
        </div>
      </div>

      <div className={`${filter ? "filter active-filter" : "filter"}`}>
        {/* <input
          type="text"
          placeholder={`${t("common.name")} ${t("common.or")} ${t(
            "common.inn"
          )}`}
          value={queryParams.name || ""}
          onChange={(e) => onFilter(e.target.value, "name")}
        /> */}
        <select
          value={region}
          onChange={(e) => {
            setRegion(e.target.value);
            // console.log(e.target.children);
          }}
        >
          <option value={""}>{t("common.region")}</option>
          {regions?.map((s, idx) => (
            <option value={s.id} key={idx}>
              {s.title}
            </option>
          ))}
        </select>
        <button onClick={getAnalysis}>{t("balance.show")}</button>
      </div>

      {isLoading ? <Loader /> : null}

      {!isEmpty(analysis) && (
        <div className="cards-page">
          <div className="agro-inner-cards">
            {analysis.map((cli, idx) => (
              <div className="agro-inner-card" key={idx}>
                <div className="card-info">
                  <div className="card-title">
                    <h2>{cli?.region?.title}</h2>
                    <p>{cli.title}</p>
                  </div>
                  <div className="card-text">
                    <p>
                      {t("agro.address")}: <strong>{cli.address}</strong>
                    </p>
                    {/* <p>
                      ИНН: <strong>{cli.tin_number}</strong>
                    </p> */}
                    <p>
                      {t("agro.owner")}: <strong>{cli.owner}</strong>
                    </p>
                    <p>
                      {t("agro.region")}: <strong>{cli.region.title}</strong>
                    </p>
                    <p>
                      {t("agro.tel")}:{" "}
                      <strong>
                        <a href={`tel:+998${cli.phone}`}>{cli.phone}</a>
                      </strong>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {_.isEmpty(analysis) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
      {/* {!isLoading && !_.isEmpty(clinics) && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )} */}
    </div>
  );
};

export default Analysis;
