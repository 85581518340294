import { Empty, Pagination } from "antd";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { fetchRequest } from "../../helpers/createRequest";
import queryString from "query-string";
import "./inner.css";

const Biolabs = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 6;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  const [count, setCount] = useState(0);

  const [filter, setFilter] = useState(true);
  const [biolabs, setBiolabs] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState();

  useEffect(() => {
    if (_.isEmpty(regions)) {
      getRegions();
    }
    getBiolabs();
  }, [queryParams.page, region, queryParams.name]);

  async function getRegions() {
    await fetchRequest
      .get(`common/regions/`)
      .then((res) => {
        setRegions(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function getBiolabs() {
    setIsLoading(true);
    await fetchRequest
      .get(`agro/biolabs/?page_size=${LIMIT}`, {
        params: {
          page: queryParams.page,
          region: region,
          search: queryParams.name,
        },
      })
      .then((res) => {
        setBiolabs(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const onFilter = (itemValue, itemKey) => {
    const rootParams = { ...queryParams };
    if (itemValue) {
      rootParams[itemKey] = itemValue;
      rootParams.page = null;
    } else {
      rootParams[itemKey] = null;
    }
    props.history.push({
      search: queryString.stringify(rootParams, { skipNull: true }),
    });
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="balance-page">
      <div className="top">
        <h1>{t("agro.biolabs")}</h1>
        <div className="right">
          <div style={{ display: "flex" }}>
            <div
              className="filter-icon"
              onClick={() => setFilter(!filter)}
            ></div>
          </div>
        </div>
      </div>

      <div className={`${filter ? "filter active-filter" : "filter"}`}>
        <input
          type="text"
          placeholder={`${t("common.name")} ${t("common.or")} ${t(
            "common.inn"
          )}`}
          value={queryParams.name || ""}
          onChange={(e) => onFilter(e.target.value, "name")}
        />
        <select
          value={region}
          onChange={(e) => {
            setRegion(e.target.value);
            // console.log(e.target.children);
          }}
        >
          <option value={""}>{t("common.region")}</option>
          {regions?.map((s, idx) => (
            <option value={s.id} key={idx}>
              {s.title}
            </option>
          ))}
        </select>
        <button
          onClick={(e) => {
            getBiolabs();
            onPageChange(1);
          }}
        >
          {t("balance.show")}
        </button>
      </div>

      {isLoading ? <Loader /> : null}

      {!isEmpty(biolabs) && (
        <div className="cards-page">
          <div className="agro-inner-cards">
            {biolabs.map((bio, idx) => (
              <div className="agro-inner-card" key={idx}>
                <div className="card-info">
                  <div className="card-title">
                    <h2>{bio.title}</h2>
                  </div>
                  <div className="card-text">
                    <p>
                      {t("agro.address")}: <strong>{bio.address}</strong>
                    </p>
                    <p>
                      {t("agro.inn")}: <strong>{bio.tin_number}</strong>
                    </p>
                    <p>
                      {t("agro.owner")}: <strong>{bio.owner}</strong>
                    </p>
                    <p>
                      {t("agro.region")}: <strong>{bio.region.title}</strong>
                    </p>
                    <p>
                      {t("agro.tel")}:{" "}
                      <strong>
                        <a href={`tel:+998${bio.phone}`}>{bio.phone}</a>
                      </strong>
                    </p>
                    <br />
                    <p>
                      {t("agro.gold")}: <strong>{bio.oltin_kuz}</strong>
                    </p>
                    <p>
                      {t("agro.trix")}: <strong>{bio.trixogramma}</strong>
                    </p>
                    <p>
                      {t("agro.brakon")}: <strong>{bio.brakon}</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {_.isEmpty(biolabs) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
      {!isLoading && !_.isEmpty(biolabs) && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Biolabs;
