import React from "react";
import "./doc.css";

export const SprutDoc = () => {
  return (
    <div>
      <strong
        style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        1. Umumiy qoidalar
      </strong>
      <ol>
        <li>
          O‘zbekiston Respublikasi Fuqarolik kodeksining 370-moddasiga muvofiq
          agar quyida ko‘rsatilgan shartlar qabul qilinib, xizmatlar uchun
          to‘lov amalga oshirilsa, qabul qiluvchi yuridik yoki jismoniy shaxs
          Ariza beruvchiga
        </li>
        <li>
          Oferta (keyingi o‘rinlarda – Shartnoma) to‘liq va so‘zsiz qabul qilish
          (taklif ma’qullanish) paytida Agentlik tomonidan Ariza beruvchidan
          xorijiy davlatlarga eksport qilishga va iste’mol maqsadlarida
          undirilgan yoki undirishga mo‘ljallangan don, dukkakli hamda moyli
          ekinlarning urug‘larini (keyingi o‘rinlarda – urug‘lar) “Iste’mol
          maqsadlaridaundirilgan yoki undirishga mo‘ljallangan urug‘larni
          eksporti uchun sertifikat” (Certificate for the export of sprouts or
          seeds intended for the production of sprouts for human consumption)ni
          (keyingi o‘rinlarda – sertifikat) olish uchun elektron ariza (keyingi
          o‘rinlarda – ariza) elektron hujjat ko‘rinishida qabul qilinadi va
          sertifikat olish to‘g‘risidagi ariza ko‘rib chiqilganligi uchun yig‘im
          undiriladi.
        </li>
        <li>
          “Elektron hujjat aylanishi to‘g‘risida”gi O‘zbekiston Respublikasi
          Qonuniga muvofiq elektron hujjat shaklidagi ariza qog‘oz hujjatga teng
          bo‘lib, Vazirlar Mahkamasining 2024-yil 15-apreldagi 201-sonli qarori
          bilan tasdiqlangan “Eksportga mo‘ljallangan va iste’mol maqsadlarida
          undiriladigan don, dukkakli hamda moyli ekinlar urug‘larini
          sertifikatlashtirish tartibi to‘g‘risida” Nizom”ga muvofiq ma’lumotlar
          batafsil bayon etilishi, shuningdek, Ariza beruvchining elektron
          raqamli imzosi bilan tasdiqlangan bo‘lishi lozim.
        </li>
        <li>
          Ariza beruvchi Shartnomaning shartlari bilan tanishib chiqqach,
          “cabinet.karantin.uz” manzili bo‘yicha elektron ariza yuboradi.
        </li>
      </ol>
      <p>Arizaga quyidagi hujjatlarni ilova qiladi:</p>
      <p>
        xalqaro tashkilotlarning oziq-ovqat mahsulotlari xavfsizligini
        boshqarish tizimi talablari yoki xalqaro tan olingan standartlar
        urug‘larni eksportga tayyorlovchi hamda eksport qiluvchi korxonalarning
        ishlab chiqarish jarayoniga joriy qilinganligini tasdiqlovchi
        sertifikatlar nusxalari;
      </p>
      <p>
        sinov laboratoriyalari uchun urug‘lardan namuna olish dalolatnomasi
        nusxasi;
      </p>
      <p>
        sinov laboratoriyalarining urug‘larni oziq-ovqat mahsulotlari
        xavfsizligi talablariga (xususan, import qiluvchi davlatning talabiga)
        muvofiqligini tasdiqlovchi xulosasi nusxasi.
      </p>
      <ol start="5">
        <li>
          Ariza beruvchi Shartnomada belgilangan to‘lov miqdorini to‘lagandan
          so‘ng uning arizasi faollashtiriladi.
        </li>
        <li>
          Ariza beruvchi Shartnomani belgilangan tartibda qabul qilib, uning
          matnida keltirib o‘tilgan barcha shartlar bilan tanishib
          chiqqanligini, rozi bo‘lganligini hamda to‘liq va so‘zsiz qabul
          qilganligini tasdiqlaydi.
        </li>
        <li>
          Ariza beruvchi ushbu hujjatning 2-bandida ko‘rsatilgan tartibda uni
          qabul qiladi va unda belgilangan shartlar asosida Shartnoma
          tuzilishiga rozilik bildiradi.
        </li>
        <li>Shartnoma chaqirib olinmaydi.</li>
        <li>
          O‘zbekiston Respublikasi Fuqarolik kodeksining 367-375-moddalari va
          “Elektron hujjat aylanishi to‘g‘risida”gi, “Elektron raqamli imzo
          to‘g‘risida”gi O‘zbekiston Respublikasi Qonunlariga muvofiq
          Shartnomada Ariza beruvchining muhri va / yoki imzosi talab etilmaydi
          hamda shunga asosan o‘z yuridik kuchini saqlab qoladi.
        </li>
      </ol>
      <strong
        style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        2. Shartnoma predmeti
      </strong>
      <ol start="10">
        <li>
          O‘zbekiston Respublikasi Vazirlar Mahkamasining 2024-yil 15-apreldagi
          201-sonli“Eksportga mo‘ljallangan va iste’mol maqsadlarida
          undiriladigan don, dukkakli hamda moyli ekinlar urug‘larini
          sertifikatlashtirish tartibi to‘g‘risidagi nizomni tasdiqlash
          haqida”gi qaroriga muvofiq Agentlik arizani ko‘rib chiqishi uchun
          Ariza beruvchi quyidagi tartibda yig‘im summasini to‘laydi:
        </li>
      </ol>
      <table className="doc-table">
        <tbody>
          <tr>
            <td width="47">
              <p>
                <strong
                  style={{
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                    marginBottom: "8px",
                  }}
                >
                  T/r
                </strong>
              </p>
            </td>
            <td width="302">
              <p>
                <strong
                  style={{
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                    marginBottom: "8px",
                  }}
                >
                  Xizmat turi
                </strong>
              </p>
            </td>
            <td width="331">
              <p>
                <strong
                  style={{
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                    marginBottom: "8px",
                  }}
                >
                  Bir birlik uchun yig‘im summasi
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="47">
              <p>1.</p>
            </td>
            <td width="302">
              <p>
                “Iste’mol maqsadlarida
                <strong
                  style={{
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                    marginBottom: "8px",
                  }}
                ></strong>
                undirilgan yoki undirishga mo‘ljallangan urug‘larni eksporti
                uchun sertifikat” (Certificate for the export of sprouts or
                seeds intended for the production of sprouts for human
                consumption) olish uchun arizani ko‘rib chiqish
              </p>
            </td>
            <td width="331">
              <p>
                Bazaviy hisoblash miqdorining <br />1 (bir) baravari
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <ol start="11">
        <li>
          To‘lov tartibi: yig‘im miqdori 100% oldindan to‘lanadi. Ariza ko‘rib
          chiqish uchun yig‘im Agentlikning hisobvarag‘iga o‘tkaziladi.
        </li>
        <li>
          Ariza kelib tushgan kundan boshlab besh kun ichida yig‘im to‘lanmasa,
          buyurtmachining elektron arizasi bekor qilinadi va uni qabul qilish
          amalga oshirilmagan hisoblanadi.
        </li>
      </ol>
      <strong
        style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        3. Taraflarning majburiyatlari
      </strong>
      <ol start="13">
        <li>
          Agentlik arizani ko‘rib chiqib, sertifikat berishni yoki berishni rad
          etish to‘g‘risida qarorni elektron ariza olingan kundan boshlab 3
          (uch) ish kuni ichida AAT orqali majburiyatini oladi. Ariza beruvchi
          berilgan arizasidan voz kechgan yoki ariza ko‘rib chiqilgandan so‘ng
          sertifikatni berish rad etilgan taqdirda to‘langan yig‘im summasi
          qaytarilmaydi.
        </li>
      </ol>
      <p>
        Sertifikat berishni rad etish asoslari O‘zbekiston Respublikasi Vazirlar
        Mahkamasining <br />
        2024-yil 15-apreldagi “Eksportga mo‘ljallangan va iste’mol maqsadlarida
        undiriladigan don, dukkakli hamda moyli ekinlar urug‘larini
        sertifikatlashtirish tartibi to‘g‘risidagi nizomni tasdiqlash haqida”gi
        201-sonli qarorining 22-bandida belgilangan.
      </p>
      <p>
        Rad etish to‘g‘risidagi xabarnoma Ariza beruvchiga elektron shaklda, rad
        etish sabablari, qonun hujjatlarining aniq normalari va ariza beruvchi
        ko‘rsatilgan sabablarni bartaraf etib, takroran ko‘rib chiqish uchun
        hujjatlarni taqdim etishi mumkin bo‘lgan muddat ko‘rsatilgan holda
        “cabinet.karantin.uz” axborot tizimi orqali yuboriladi. Ariza beruvchi
        rad etish sabablarini bartaraf etgan holda hujjatlarni takroran ko‘rib
        chiqish uchun taqdim etishi mumkin bo‘lgan muddat sertifikat berish rad
        etilganligi haqidagi xabarnoma olingan kundan e’tiboran 10 (o‘n) ish
        kunidan kam bo‘lmasligi va 30 (o‘ttiz) ish kunidan ortiq bo‘lmasligi
        kerak.
      </p>
      <p>
        Ariza beruvchi sertifikatni berishni rad etish uchun asos bo‘lgan
        sabablarni belgilangan muddatda bartaraf etgan taqdirda, hujjatlar
        takroran ko‘rib chiqish, sertifikat berish yoki uni berishni rad etish
        ariza beruvchidan rad etish sabablari bartaraf etilganligini
        tasdiqlovchi tegishli hujjatlar olingan kundan e’tiboran 2 (ikki) ish
        kuni ichida takroran ko‘rib chiqiladi. Ariza takroran ko‘rib
        chiqilganligi uchun yig‘im undirilmaydi.
      </p>
      <ol start="14">
        <li>
          Agentlik arizadagi ma’lumotlarni oshkor qilmaslik majburiyatini oladi.
          Amaldagi qonunchilikka muvofiq asosli ravishda ma’lumotni oshkor
          qilish holatlarida majburiyat buzilgan deb hisoblanmaydi.
        </li>
      </ol>
      <strong
        style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        4. Korrupsiyaga qarshi qo‘shimcha shartlar
      </strong>
      <ol start="15">
        <li>
          Taraflar Shartnomatuzishda, ofertaning amal qilish muddatida va ushbu
          muddat tugaganidan so‘ng, oferta bilan bog‘liq korrupsiyaviy
          harakatlar sodir qilmaslikka kelishib oladilar.
        </li>
        <li>
          Taraflar Adliya vazirligi, Korrupsiyaga qarshi kurashish agentligi,
          Savdo-sanoat palatasining 2021-yil <br />
          12-noyabrdagi 1/4671, 01-19/7284, 7/01-08-9544-son qaroriga ilova
          bilan tasdiqlangan “Xo‘jalik yurituvchi subyektlar tomonidan
          shartnomalar tuzishda qo‘llash uchun tavsiyaviy xarakterga ega bo‘lgan
          korrupsiyaga qarshi qo‘shimcha shartlar” (keyingi o‘rinlarda –
          korrupsiyaga qarshi qo‘shimcha shartlar)ga muvofiq korrupsiyaning
          oldini olish choralarini tan oladi va ularga rioya etilishi bo‘yicha
          hamkorlikni ta’minlaydilar.
        </li>
        <li>
          Har bir taraf Shartnoma tuzilgan paytda bevosita o‘zi yoki uning
          ijroiya organlari, mansabdor shaxslari <br />
          va xodimlari tomonidan shartnoma bilan bog‘liq munosabatlar yuzasidan
          qonunga xilof ravishda pul, moddiy qiymatliklar berilmaganligini,
          shartnoma tuzilishi evaziga norasmiy pul yoki boshqa moddiy
          qiymatliklar olinishiga yo‘l qo‘yilmaganligini, taklif
          etilmaganligini, ularni berishga va’da qilinmaganligini, shuningdek,
          moddiy yoki har qanday turdagi imtiyoz, ustunliklar olinmaganligini
          (kelajakda bu turdagi harakatlarni amalga oshirishi mumkinligi haqida
          taassurot qoldirilmaganligini) kafolatlaydi.
        </li>
        <li>
          Taraflar, ular tomonidan shartnoma doirasida jalb qilingan
          shaxslarning (yordamchi pudratchi tashkilotlar, agentlar va taraflar
          nazorati ostidagi boshqa shaxslarning) yuqoridagi harakatlarni sodir
          etmasligi yuzasidan oqilona choralar ko‘radi.
        </li>
        <li>
          Taraflar ularning nazorati ostida bo‘lgan va ular nomidan harakat
          qiladigan shaxslar tomonidan korrupsiyaga qarshi belgilangan
          majburiyatlar buzilganligi holatlari haqida xabar berilganligi uchun
          ularga tazyiq o‘tkazilmasligini kafolatlaydilar.
        </li>
        <li>
          Agar bir tarafga boshqa tarafning korrupsiyaga qarshi qo‘shimcha
          shartlarning 4- va 5-bandlarida belgilangan majburiyatlarning
          buzilishiga yo‘l qo‘yilganligi ma’lum bo‘lib qolsa, ikkinchi tarafni
          bu haqda zudlik bilan xabardor qilishi va ushbu tarafdan oqilona
          muddat ichida tegishli choralar ko‘rilishini va amalga oshirilgan
          ishlar yuzasidan unga xabardor qilishini talab qilishi shart.
          Tarafning talabi bo‘yicha ikkinchi taraf tomonidan qoidabuzarliklarni
          bartaraf qilish bo‘yicha oqilona muddat ichida tegishli choralar
          ko‘rilmagan yoki ko‘rib chiqish natijalari haqida xabardor qilmagan
          taqdirda, ushbu taraf zararni to‘liq qoplab berishni talab qilishga
          haqli.
        </li>
      </ol>
      <strong
        style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        5. Javobgarlik, nizolarni hal etish tartibi
      </strong>
      <ol start="21">
        <li>
          Taraflar O‘zbekiston Respublikasi qonunchiligiga muvofiq javobgar
          bo‘ladilar.
        </li>
        <li>
          Shartnomani bajarish vaqtida fors-major holatlari: davlat hokimiyati
          organlarining harakatlari, yong‘in, suv toshqini, zilzila, boshqa
          tabiiy ofatlar, elektr energiyasining yo‘qligi, ish tashlashlar,
          fuqarolik urushlari, tartibsizliklar, shuningdek, yuqorida sanab
          o‘tilmagan holatlar bilan cheklanmagan har qanday boshqa favqulodda
          hodisalar yuzaga kelib, Shartnoma shartlari bajarmasligi taraflar
          javobgarlikdan ozod etiladi.
        </li>
        <li>Agentlik quyidagilar uchun javobgar emas:</li>
      </ol>
      <p>
        - Ariza beruvchiga o‘z xizmatlaridan foydalanishni ta’minlaydigan umumiy
        aloqa kanallari yoki xizmatlarining sifati uchun;
      </p>
      <p>- Ariza beruvchi ommaviy tarzda taqdim etilgan ma’lumotlar uchun.</p>
      <ol start="24">
        <li>
          Ariza beruvchining Agentlik tomonidan ko‘rsatilgan xizmatlarga
          e’tirozi yoki shikoyatlari
          <a href="mailto:info@karantin.uz%20">info@karantin.uz</a>
          elektron pochtasi orqali ko‘rib chiqish uchun qabul qilinadi.
        </li>
      </ol>
      <ol start="25">
        <li>
          Nizoli vaziyatlarni ko‘rib chiqishda Agentlik Ariza beruvchidan nizoga
          doir barcha tegishli hujjatlarni talab qilishga haqli.
        </li>
        <li>
          Taraflar nizo va kelishmovchiliklar yuzaga kelganda, sudgacha
          nizolarni hal etish tartibini qo‘llash majburiyatini oladilar. Agar
          nizoni sudgacha hal qilishning imkoni bo‘lmasa, taraflar sudga
          murojaat qilish huquqiga egadirlar.
        </li>
      </ol>
      <strong
        style={{
          textAlign: "center",
          display: "block",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        6. Alohida shartlar
      </strong>
      <ol start="27">
        <li>
          Ariza beruvchi Shartnomani qabul qilish hamda bajarish uchun zarur
          bo‘lgan barcha huquq va vakolatlarga ega.
        </li>
        <li>
          Agentlik ushbu Shartnomaning istalgan shartlarini o‘zgartirish yoki
          to‘ldirish huquqini o‘z veb- saytida barcha o‘zgarishlarni e’lon
          qilish orqali saqlab qoladi.
        </li>
        <li>
          Shartnoma to‘lov amalga oshirilgan kundan boshlab joriy yilning
          31-dekabrigacha amal qiladi.
        </li>
        <li>
          Ushbu Shartnomada nazarda tutilmagan barcha masalalar O‘zbekiston
          Respublikasi qonunchiligi me’yorlari asosida tartibga solinadi.
        </li>
      </ol>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
