import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { sprutAdd, sprutEdit } from "../../../../apis/sprut";
import { formatPhoneNumber, getUser } from "../../../../utils/user";
import { product_box_type } from "./forms/usemyForm";
import { REACT_QUERY_KEYS } from "../../../../constants/reactQueryKeys";

export const useModal = (setOpen, data) => {
  const { user } = getUser();
  const queryClient = useQueryClient();

  const form = useForm({
    mode: "onChange",
    defaultValues: !!data
      ? {
          ...data,
          applicant_phone: ` ${data.applicant_phone}`,
          products: data?.products?.map((pr) => ({
            ...pr,
            collected_date: dayjs(pr.collected_date),
            package_type: pr.package_unit_code,
          })),
        }
      : {
          applicant_name: user?.name,
          applicant_tin: user?.pin_or_tin,
          applicant_address: user?.address,
        },
  });
  const { mutate, isLoading } = useMutation(
    async (info) => await (!!data ? sprutEdit(info) : sprutAdd(info)),
    {
      onSuccess: (res) => {
        void queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_KEYS.GET_SEED_EXPORT],
        });
        setOpen(false);
        toast.info(res.data.comment);
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.detail ?? error?.response?.data ?? "Error"
        );
      },
    }
  );
  const onSubmit = (post) => {
    const { products, ...rest } = post;
    const pr = products?.map((p) => {
      const { package_type, ...pr } = p;
      return {
        ...pr,
        collected_date: dayjs(pr.collected_date).format("YYYY-MM-DD"),
        package_unit_code: package_type,
        package_unit_name: product_box_type?.find(
          (f) => f?.value === package_type
        )?.label,
      };
    });

    mutate({
      ...rest,
      applicant_phone: formatPhoneNumber(rest.applicant_phone)?.slice(0, 9),
      departure_date: dayjs(rest.departure_date).format("YYYY-MM-DD"),
      products: pr,
      status: 1,
    });
  };

  return {
    onSubmit,
    isLoading,
    form,
  };
};
