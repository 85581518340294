import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const ProcessModal = (props) => {
  const { t } = useTranslation();
  const { openAbout, setOpenAbout, steps } = props;

  return (
    <Modal
      open={openAbout}
      onOk={() => setOpenAbout(false)}
      onCancel={() => setOpenAbout(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={1060}
      className="add-fcc-modal"
    >
      <h1>{t("inner-fcc.sos-ot")}</h1>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.sos-ot")}</th>
              <th>{t("inner-fcc.detal")}</th>
              <th>{t("inner-fcc.otp")}</th>
              <th>{t("inner-fcc.vrem")}</th>
            </tr>
          </thead>
          <tbody>
            {steps?.map((item1, index1) => {
              const date = new Date(item1.added_at);

              return (
                <tr key={index1}>
                  <td>{index1 + 1}</td>
                  <td>{item1.status_name}</td>
                  <td>{item1.description}</td>
                  <td>
                    {item1.sender_name}
                    <br />
                    {item1.sender_phone}
                  </td>
                  <td>
                    {date.getDate() < 10
                      ? "0" + date.getDate()
                      : date.getDate()}
                    -
                    {date.getMonth() + 1 < 10
                      ? "0" + (date.getMonth() + 1)
                      : date.getMonth() + 1}
                    -{date.getFullYear()}
                    <br />
                    {date.getHours() < 10
                      ? "0" + date.getHours()
                      : date.getHours()}
                    :
                    {date.getMinutes() < 10
                      ? "0" + date.getMinutes()
                      : date.getMinutes()}
                    :
                    {date.getSeconds() < 10
                      ? "0" + date.getSeconds()
                      : date.getSeconds()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ProcessModal;
