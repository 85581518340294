import { Col, Row } from "antd";
import React from "react";
import { One } from "./one";
import { Two } from "./two";

export const Product = ({ isOne }) => {
  return (
    <Col span={24}>
      <Row gutter={[16, 16]}>{isOne ? <One /> : <Two />}</Row>
    </Col>
  );
};
