import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChemicalInfo from "./chemicalInfo";
import Info from "./info";

const TableRow = ({ item, index, date, offset }) => {
  const { t } = useTranslation();
  const [openChemicInfo, setOpenChemicInfo] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  console.log(item?.used_chemical_reactives);
  return (
    <tr key={index}>
      <td>{offset + index + 1}</td>
      <td>
        <a href={`${item?.get_absolute_url}`} target="_blank">
          {item?.number}
        </a>
      </td>
      <td>
        {date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-
        {date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1}
        -{date.getFullYear()}
      </td>

      <td>
        <a className="more" onClick={(e) => setOpenInfo(true)}>
          {t("inner-fcc.react-product")}
        </a>
      </td>
      <td>{item?.lab}</td>
      <td>{item?.payment_amount}</td>

      <Info
        open={openInfo}
        setOpen={setOpenInfo}
        disposables={item?.used_disposables}
        reactives={item?.used_chemical_reactives}
      />
    </tr>
  );
};

export default TableRow;
