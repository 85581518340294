import styled from "styled-components";

export const StampWrapper = styled.div`
  width: 320px;
  padding: 8px;
  background-color: #fff;
  .stamp {
    width: 100%;
    border: 2px solid red;
    color: red;
    font-weight: 600 !important;
    border-radius: 4px;
    padding: 10px 8px;
    font-family: sans-serif;
    text-align: center;
    line-height: 1.2;
    text-transform: uppercase !important;

    h4 {
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: 1.1px;
      font-size: 12px !important;
    }
    .status {
      p {
        font-size: 11.5px !important;
      }
      span {
        display: inline-block;
        margin-top: 4px;
        padding: 4px 4px 0;
        border-top: 2px solid red;
        font-size: 10px !important;
      }
    }
  }
  &.quarantine_organisms_not_exist {
    .stamp {
      border-color: #0111b4 !important;
      color: #0111b4 !important;
      .status {
        span {
          border-color: #0111b4 !important;
        }
      }
    }
  }
`;
