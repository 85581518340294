import { useTranslation } from "react-i18next";
import { getCountries } from "../../apis/acts/common";
import { REACT_QUERY_KEYS } from "../../constants/reactQueryKeys";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ProcessModal from "./ProcessModal";
import InnerProductsModal from "./InnerProductsModal";
import { AddOrEditComponent } from "./components/add";

const OneRow = (props) => {
  const [openAbout, setOpenAbout] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const { t } = useTranslation();
  const { data: countries } = useQuery({
    queryFn: getCountries,
    queryKey: [REACT_QUERY_KEYS.GET_COUNTRIES],
    select: (res) => {
      return res.data?.map((c) => ({
        ...c,
        value: c.id,
        label: c.name_en,
      }));
    },
  });

  return (
    <tr className={props?.editable ? "is-editable" : ""}>
      <td>{props.index + 1}</td>
      <td>
        {props?.sprout_certificate_number ? (
          <a
            href={`https://cabinet.karantin.uz/act/seed-export/${props?.sprout_certificate_number}`}
            target="_blank"
            rel="noreferrer"
          >
            {props?.request_number}
          </a>
        ) : (
          <>
            {props?.request_number}
            <br />
            {props?.lot_number}
            <br />
          </>
        )}
      </td>
      <td>
        {props.applicant_name}
        <br />
        {props.applicant_tin}
      </td>
      <td>+998 {props?.applicant_phone}</td>
      <td>{props.request_date}</td>
      <td>
        <span
          onClick={() => setOpenAbout(true)}
          style={{ color: "#036323", cursor: "pointer" }}
        >
          {props?.status_name}
        </span>
      </td>
      <td>
        {countries?.find((c) => c.value === props.importer_country).label}
      </td>
      <td>
        {props?.editable ? (
          <AddOrEditComponent data={props} />
        ) : (
          <div
            className={
              props?.editable ? "bg-circle bg-circle-editable" : "bg-circle"
            }
          ></div>
        )}
      </td>
      <td>
        <td>
          <div className="bg" onClick={() => setOpenProducts(true)}></div>
        </td>{" "}
      </td>
      <ProcessModal
        openAbout={openAbout}
        setOpenAbout={setOpenAbout}
        steps={props?.status_steps}
      />
      <InnerProductsModal
        openProducts={openProducts}
        setOpenProducts={setOpenProducts}
        products={props?.products}
      />
    </tr>
  );
};

export default OneRow;
