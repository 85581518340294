import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Flex,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Pagination,
} from "antd";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FormInput } from "../../../../../../components/formComponents/input";
import { Container } from "./style";
const { Text, Title } = Typography;
export const CompanyModal = ({
  name,
  control,
  label,
  options,
  onSearch,
  setParams,
  params,
  search,
  count,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const form = useFormContext();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setError("");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    onSearch(value);
  };

  const onChange = (page) => {
    setParams({
      ...params,
      offset: (page - 1) * 20,
    });
  };
  return (
    <>
      <Space onClick={showModal} direction="vertical" style={{ width: "100%" }}>
        {label && (
          <label>
            {label}
            {props.rules?.required && (
              <Text type="danger">
                <sup> *</sup>
              </Text>
            )}
          </label>
        )}
        <div style={{ position: "relative" }}>
          <FormInput
            {...props}
            readOnly={true}
            control={control}
            name={name}
            placeholder={props.placeholder}
            suffix={<IoIosArrowDown />}
          >
            {form.watch(name) && (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#fff",
                  height: "30px",
                  width: "80%",
                  padding: "4px 11px",
                  top: 1,
                  left: 4,
                  zIndex: 9,
                }}
              >
                {options?.find((o) => o.value === form.watch(name))?.label}
              </div>
            )}
          </FormInput>
        </div>
      </Space>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        destroyOnClose={true}
        className="my-modal"
        width={900}
        onCancel={handleCancel}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title style={{ marginBottom: 0 }} level={3}>
              {label}
            </Title>
          </Col>
          <Col span={24}>
            <Space
              style={{
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Space direction="vertical" style={{ width: "280px" }}>
                <Input
                  value={search}
                  placeholder={t("select min text", { count: 4 })}
                  onChange={handleInputChange}
                />
                {error && <Text type="danger">{error}</Text>}
              </Space>
            </Space>
          </Col>
          <Col span={24} style={{ minHeight: "50vh" }} className="main-content">
            {props.hsCodesLoading ? (
              <Flex
                style={{
                  width: "100%",
                  height: "50vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin indicator={<LoadingOutlined spin />} size="large" />
              </Flex>
            ) : (
              <Container>
                <table>
                  <tbody>
                    <tr className="head">
                      <td>
                        <b>№</b>
                      </td>
                      <td>
                        <b>{t("box company")}</b>
                      </td>

                      {/* <td>
                        <b>{t("addMan.address")}</b>
                      </td>
                      <td>
                        <b>{t("addMan.address")}</b>
                      </td> */}
                    </tr>
                    {options?.length > 0 ? (
                      options.map((items, index) => (
                        <tr
                          key={items.value}
                          onClick={() => {
                            form.setValue(name, items.value);
                            handleCancel();
                          }}
                        >
                          <td>
                            {(search === "" ? params.offset : 0) + (index + 1)}
                          </td>
                          <td>{items.label}</td>
                          {/* <td>{items.address ?? "-"}</td>
                          <td>{items.packaged_products_type ?? "-"}</td> */}
                        </tr>
                      ))
                    ) : (
                      <tr className="head">
                        <td colSpan={3}>
                          <Flex
                            style={{
                              width: "100%",
                              height: "70vh",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Empty description={t("balance.noData")} />
                          </Flex>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Container>
            )}
          </Col>
          <Col span={24}>
            <Flex align="center" justify="center" style={{ height: "60px" }}>
              <Pagination
                align="center"
                // defaultCurrent={1}
                defaultPageSize={20}
                total={count ?? 20}
                current={params.offset / params.limit + 1}
                onChange={onChange}
                showSizeChanger={false}
              />

              {/* <Button
                onClick={() => {
                  setParams({
                    ...params,
                    offset: params.offset > 20 ? params.offset - 20 : 0,
                  });
                }}
                icon={<MdKeyboardArrowLeft />}
              />
              <Button
                onClick={() => {
                  setParams({
                    ...params,
                    offset: params.offset + 20,
                  });
                }}
                icon={<MdKeyboardArrowRight />}
              /> */}
            </Flex>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
