import { Col } from "antd";
import React, { useState } from "react";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { useTranslation } from "react-i18next";
import { getAllCompaniesBox } from "../../../../../../apis/innerFss";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
import { useQuery } from "@tanstack/react-query";
import { CompanyModal } from "./modal";

export const CompaniesSelect = ({ props }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({ offset: 0, limit: 20 });

  const { data: companiies, isFetching: companiiesLoading } = useQuery({
    queryFn: () =>
      getAllCompaniesBox(
        search === ""
          ? {
              ...params,
            }
          : {
              name: search === "" ? undefined : search,
            }
      ),
    queryKey: [REACT_QUERY_KEYS.GET_COMPANIES, search, params],
    enabled: search === "" || search?.length > 3,
    select: (res) => {
      const data = res.data?.results?.map((d) => ({
        ...d,
        value: d.id,
        label: `${d.name} (${d.reg_number})`,
      }));
      return { data, count: res?.data?.count };
    },
  });
  return (
    <Col span={24} md={12}>
      {/* <FormSelect
        {...props}
        placeholder={t("select min text", { count: 4 })}
        label={t("box company")}
        name="packaging_company"
        options={companiies}
        loading={companiiesLoading}
        // onChange={(e) => {
        //   const data = companiies?.find((p) => p.value === e);
        //   setValue(`${fieldName}.${ind}.product_data`, data);
        // }}
        notFilteredOption={true}
        onSearch={(e) => {
          setSearch(e);
        }}
      /> */}

      <CompanyModal
        {...props}
        placeholder={t("inner-fcc.tanla")}
        label={t("box company")}
        name="packaging_company"
        options={companiies?.data}
        count={companiies?.count}
        loading={companiiesLoading}
        onSearch={(e) => setSearch(e)}
        search={search}
        setParams={setParams}
        params={params}
      />
    </Col>
  );
};
