import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import LabIcon from "../../../../../assets/images/lab-icon.png";
import { PrintPaperComponent } from "../../../../../components/ui/PaperPrint";
import { QRCodeComponets } from "../../../components/qrcode-generator/QRCode";
import { Spacer, Stack } from "../../style";
import { ActHeader } from "../header";

export const PageOne = () => {
  const [isLong, setIslong] = useState("");
  const { watch } = useFormContext();
  const tableRef = useRef(null);
  const data = watch("data");
  const region = watch("region");
  const country = watch("country");
  const units = watch("units");
  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        const tableHeight = tableRef.current.clientHeight;
        if (tableHeight > 925) {
          setIslong("smaller very-small");
        } else if (tableHeight > 856) {
          setIslong("smaller");
        } else if (tableHeight > 726) {
          setIslong("small");
        } else {
          setIslong("");
        }
      }
    };

    handleResize(); // Komponent yuklanganda bir marta ishga tushirish
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <PrintPaperComponent hasBg={false} page="1/3" qrCode={true}>
      <ActHeader uz={region?.lab_uzbek_name} en={region?.lab_english_name} />

      <Stack style={{ justifyContent: "space-between", padding: "16px 0 8px" }}>
        <div
          style={{
            width: "160px",
            textAlign: "left",
          }}
        >
          <QRCodeComponets size={80} style={{ textAlign: "left" }} />
        </div>
        <Spacer style={{ maxWidth: "240px", gap: "2px" }}>
          <h2 style={{ marginBottom: "0" }}>EKSPERTIZA XULOSASI</h2>
          <h2 style={{ marginBottom: "0" }}>ЗАКЛЮЧЕНИЕ ЭКСПЕРТИЗЫ</h2>
          <h2 style={{ marginBottom: "0" }}>CONCLUSIONS OF EXPERTISE</h2>
          <h3 style={{ marginBottom: "0", textAlign: "center" }}>
            Import / Импорт / Import
          </h3>
        </Spacer>

        <img
          style={{ objectFit: "contain", width: "160px" }}
          src={LabIcon}
          alt="Labaratoriya icons"
        />
      </Stack>

      <div>
        <Stack style={{ justifyContent: "space-between", padding: "8px 0" }}>
          <h3>{region?.address_uz}</h3>
          <h3>{region?.address_en}</h3>
        </Stack>

        <table ref={tableRef} className={isLong}>
          <tbody>
            <tr>
              <td rowSpan={3}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Ushbu ekspertiza xulosasi berildi / Заключения экспертизы
                    выдано / This expert opinion is issued
                  </h3>
                  <p>{data?.applicant_name}</p>
                </Spacer>
              </td>
              <td colSpan={2} rowSpan={3}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Eksportyor davlat, hudud va tashkilot nomi, manzil, telefon
                    raqami / Наименование экспортирующей страны, региона и
                    организации, адрес, контакты / Name of exporter country,
                    region and organization, address, contacts
                  </h3>
                  <p>
                    {country?.name_local}, {data?.exporter_name},{" "}
                    {data?.exporter_address}
                  </p>
                </Spacer>
              </td>

              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Ekspertiza raqami / Номер экспертизы / Expertise number
                  </h3>
                  <p>{data?.number}</p>
                </Spacer>
              </td>
            </tr>

            <tr>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Berildi / Выдано / Issued
                  </h3>
                  <p>{data?.given_date}</p>
                </Spacer>
              </td>
            </tr>
            <tr>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Karantin ruxsatnomasi raqami va sanasi/ Номер и дата
                    карантинного разрешения / Quarantine permit number and date
                  </h3>
                  <p>
                    {data?.ikr_number}/{data?.ikr_given_date}
                  </p>
                </Spacer>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  Mahsulot haqida ma'lumot / Информация о продукции / Product
                  information
                </h3>
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                style={{
                  minWidth: "400px !important",
                  width: "400px !important",
                }}
              >
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Mahsulot nomi, TIFTN Kodi, miqdori va o‘lchov birligi /
                    Наименование, код ТН ВЭД количество и ед-измер. продукции /
                    Name, HS code, quantity and unit of the product
                  </h3>
                  <div>
                    {data?.products?.slice(0, 20)?.map((p, i) => (
                      <p key={i}>
                        {i + 1}. {p?.name} ({p?.hs_code}) - {p.quantity}{" "}
                        {units?.find((u) => u?.id === p?.unit)?.name_local};
                      </p>
                    ))}
                  </div>
                </Spacer>
              </td>
              <td style={{ width: "230px" }}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Mahsulot yetishtirilgan davlat va hudud / Страна и регион
                    происхождения продукции / Country and region of origin
                  </h3>
                  <p>{country?.name_local}</p>
                </Spacer>
              </td>
            </tr>

            <tr>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    TIF nazorat maskani / контрольный пункт ВЭД /FEA
                  </h3>
                  <p>{data?.fea}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Transport turi / Вид транспорта / Type of transport:
                  </h3>
                  <div>
                    <p>{data?.transport_method}</p>
                  </div>
                </Spacer>
              </td>
              <td colSpan={2}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    * Davlat inspektor F.I.Sh. / Ф.И.О.Государственного
                    инспектора / Full name State inspector
                  </h3>
                  <p>{data?.inspector}</p>
                </Spacer>
              </td>
            </tr>

            <tr>
              <td colSpan={4}>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  Tanlab olingan namuna haqida maʼlumot / Информация об отборе
                  проб/ Sampling information
                </h3>
              </td>
            </tr>

            <tr>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Namuna soni / Количество образцов / Number of samples
                  </h3>
                  <p>{data?.number_of_sample}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    Namuna tanlab olingan sanasi / Дата отбора образцов /
                    Sampling date
                  </h3>
                  <p>{data?.sampling_date}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    MFL ga qabul qilingan sana / Дата поступления в ЦФЛ / Date
                    of admission to CPL
                  </h3>
                  <p>{data?.shortcut_given_date}</p>
                </Spacer>
              </td>
              <td>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    MFL ro‘yxatga olingan raqam va sana / Регистрационный номер
                    и дата ЦФЛ / CPL registration number and date
                  </h3>
                  <p>
                    {data?.shortcut_number} / {data?.shortcut_given_date}
                  </p>
                </Spacer>
              </td>
            </tr>

            <tr>
              <td colSpan={4}>
                <Spacer>
                  <h3 style={{ fontWeight: "bold" }}>
                    * Namunalarni tanlab olish akkreditatsiya doirasiga taluqli
                    emas / Отбор образцов не аккредитованный деятельности /
                    Sampling is not an accredited activity / Tanlab olish uslubi
                    / Метод отбора / Sampling method
                  </h3>
                  <p>
                    ГОСТ 12430-2019: O‘simliklar karantini Karantin
                    fitosanitariya tekshiruvi va laboratoriya tadqiqotlari uchun
                    karantin ostidagi mahsulotlardan namuna olish usullari va
                    meʼyorlari / Методы и нормы отбора образцов подкарантинной
                    продукции при карантинном фитосанитарном досмотре и
                    лабораторных исследованиях / Methods and standards for
                    sampling regulated products during quarantine phytosanitary
                    inspection and laboratory research
                  </p>
                </Spacer>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </PrintPaperComponent>
  );
};
