import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import indev from "../../assets/icons/indev.svg";
import queryString from "query-string";
// import { FiSearch } from "react-icons/fi";
// import moment from "moment";
import { MdClose } from "react-icons/md";
import request from "../../helpers/createRequest";
import { Empty, Pagination, Spin } from "antd";
import _ from "lodash";
import Loader from "../../components/loader/Loader";
import AddField from "./flieldForm";
import FieldTableRow from "./FieldTableRow";

const Field = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 10;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  //   const [filter, setFilter] = useState(false);
  const [allLists, setAllLists] = useState([]);
  const [count, setCount] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [allRegions, setAllRegions] = useState([]);

  useEffect(() => {
    getList();
  }, [queryParams.page]);

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = () => {
    request
      .get(`api/regions`)
      .then((res) => {
        setAllRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function getList() {
    setIsLoading(true);
    await request
      .get(`applicant/lab-application/list/?limit=${LIMIT}&offset=${OFFSET}&application_type=1`)
      .then((res) => {
        setAllLists(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const [searchNumber, setSearchNumber] = useState("");
  const searchList = (e) => {
    e.preventDefault();
    setAllLists([]);
    setIsLoading(true);
    request
      .get(`applicant/lab-application/list/?number=${searchNumber}`)
      .then((res) => {
        setAllLists(res.data.results);
        setIsLoading(false);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="inner-fcc-page">
      <AddField
        open={openForm}
        setOpen={setOpenForm}
        getList={getList}
        allRegions={allRegions}
      // serviceArrs={services}
      // detail={detail}
      />
      <div className="top">
        <h1>{t("lab.field")}</h1>
        <div className="right">
          <form className="input-wrap" onSubmit={searchList}>
            <div></div>
            <input
              type="text"
              placeholder={t("inner-fcc.search")}
              value={searchNumber}
              onChange={(e) => setSearchNumber(e.target.value)}
            />
            <MdClose
              className={searchNumber ? "icoon icoon-visible" : "icoon"}
              onClick={() => {
                setSearchNumber("");
                getList();
              }}
            />
          </form>
          <div className="btn-wrapper" style={{ display: "flex" }}>
            {/* <div className="filter-icon" onClick={() => setFilter(false)}></div> */}
            <div className="btn-wrap">
              <button
                // type="submit"
                className={"button-active"}
                // disabled={invoices.length === 7}
                onClick={(e) => setOpenForm(true)}
              >
                {t("inner-fcc.single")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={filter ? "filter active-filter" : "filter"}>
        <input type="date" />
        <input type="date" />
        <select>
          <option hidden>Выберите город/область</option>
          <option>Buxoro</option>
        </select>
        <button>
          <FiSearch className="icon" />
          Поиск
        </button>
      </div> */}

      {isLoading ? <Loader /> : null}
      {/* <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.summary")}</th>
              <th>{t("inner-fcc.give")}</th>
           
              <th>{t("inner-fcc.used")}</th>
              <th>{t("inner-fcc.lab")}</th>
              <th>{t("inner-fcc.pay")}</th>
            </tr>
          </thead>
          <tbody>
            {allLists?.map((item, index) => {
              const date = new Date(item.given_date);
              return (
                <TableRow
                  item={item}
                  index={index}
                  date={date}
                  key={index}
                  offset={OFFSET}
                />
              );
            })}
          </tbody>
        </table>
      </div> */}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("lab.zay-ser")}</th>
              <th>{t("inner-fcc.naim")}</th>
              <th>{t("inner-fcc.kontakt")}</th>
              <th>{t("inner-fcc.data-pol")}</th>
              <th>{t("inner-fcc.sos-ot")}</th>
              <th>{t("inner-fcc.otp-zan")}</th>
              <th>{t("inner-fcc.prod")}</th>
            </tr>
          </thead>
          <tbody>
            {allLists?.map((item, index) => {
              const date = new Date(item.added_at);
              return (
                <FieldTableRow
                  item={item}
                  index={index}
                  date={date}
                  key={index}
                  offset={OFFSET}
                  allRegions={allRegions}
                  getList={getList}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {_.isEmpty(allLists) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}

      {!_.isEmpty(allLists) && !isLoading && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Field;
