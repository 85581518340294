import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const Price = ({ open, setOpen, handle, data }) => {
  const { t } = useTranslation();
  //   console.log(data);
  let nf = new Intl.NumberFormat("en-US");

  return (
    <Modal
      open={open}
      onOk={() => handle()}
      onCancel={() => handle()}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={900}
      className="add-fcc-modal"
    >
      <h1>{t("fumig.price-list")}</h1>

      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("inner-fcc.name")}</th>
              <th>{t("fss.description")}</th>
              <th>{t("inner-fcc.price")}</th>
              <th>{t("lab.unit")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              if (item?.is_active) {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.name || ""}</td>

                    <td>{item?.description || ""}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {nf.format(Number(item?.price).toFixed(0)) +
                        " " +
                        t("invoice.sum") || ""}
                    </td>
                    <td>
                      {item?.unit ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item?.unit,
                          }}
                        ></span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default Price;
