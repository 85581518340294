export const getUser = () => {
  const oneIdInfo = JSON.parse(localStorage.getItem("applicant") || "null");
  const user = JSON.parse(
    localStorage.getItem("user_data") === "null"
      ? "null"
      : localStorage.getItem("user_data")
  );

  return { user, oneIdInfo };
};

export function formatPhoneNumber(phoneNumber) {
  let formattedNumber = phoneNumber.replace("+998", "");
  formattedNumber = formattedNumber.replace(/\s+/g, "");

  return formattedNumber;
}
