import "./login.css";
import logoImg from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Login from "../login/Login";
import queryString from "query-string";
import Loader from "../loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { language } from "../../redux/actions/langAction";

const SelectLogin = () => {
  const { t } = useTranslation();
  const queryParams = queryString.parse(window.location.search);
  let code = queryParams.code;

  const [isLoading, setIsLoading] = useState(true);
  const [foreigner, setForeigner] = useState(false);
  const [citizen, setCitizen] = useState(false);
  const [content, setContent] = useState(null);

  const { lang: lan } = useSelector((state) => state.language);

  const dispatch = useDispatch();
  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(language(lang));
  };

  useEffect(() => {
    returnPage();
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const returnPage = () => {
    console.log(citizen);
    if (citizen || code) {
      setContent(<Login />);
      return;
    }
    if (foreigner) {
      window.open("https://foreign.karantin.uz/login", "_self");
      return;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !content ? (
        <div className="select-login">
          <div className="wrapper-card">
            <div className="card-content-wrap">
              <div className="langs-outer">
                <div className="langs" style={{ maxWidth: "80px" }}>
                  <div
                    className={lan === "uz" ? "active-lan" : ""}
                    onClick={() => changeLan("uz")}
                  >
                    UZ
                  </div>
                  <div
                    className={lan === "ru" ? "active-lan" : ""}
                    onClick={() => changeLan("ru")}
                  >
                    RU
                  </div>
                  {/* <div
                    className={lan === "en" ? "active-lan" : ""}
                    onClick={() => changeLan("en")}
                  >
                    EN
                  </div> */}
                </div>
              </div>
              <div className="logo-content">
                <img src={logoImg} />
                <p>{t("login.agency")}</p>
              </div>
              <div className="options-wrap">
                <label>
                  <input
                    type="radio"
                    name="radio"
                    onChange={(e) => setCitizen(e.target.checked)}
                  />
                  <span>{t("login.citizenUz")}</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio"
                    onChange={(e) => setForeigner(e.target.checked)}
                  />
                  <span>{t("login.foreign")}</span>
                </label>
              </div>
              <div className="btn-wrapper">
                <button
                  type="button"
                  className="login-btn"
                  onClick={returnPage}
                >
                  {t("login.continue")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        content
      )}
    </>
  );
};

export default SelectLogin;
