import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  exchangeMoney,
  getAllOfertas,
  refreshBalance,
  refreshExchangeableBalance,
} from "../../../apis/innerFss";
import { REACT_QUERY_KEYS } from "../../../constants/reactQueryKeys";
import { toast } from "react-toastify";

export const useTransfer = ({ getPaymentLists }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const form = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const { data, isLoading, refetch } = useQuery({
    queryFn: getAllOfertas,
    queryKey: [REACT_QUERY_KEYS.GET_OFERTAS],
    select: (res) => {
      return res.data;
    },
  });
  const sender = data?.find(
    (f) => f.number === form.watch("sending_invoice_number")
  );
  const receiver = data?.find(
    (f) => f.number === form.watch("receiving_invoice_number")
  );

  const lang = useSelector((state) => state.language.lang);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.reset({});
  };
  const props = {
    control: form.control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };

  const { mutate: refresh } = useMutation(async () => {
    return await refreshBalance();
  });

  const { mutate: ExchangeableBalance } = useMutation(
    async () => {
      return await refreshExchangeableBalance();
    },
    {
      onSuccess: () => {
        refetch();
        getPaymentLists();
        handleCancel();
      },
    }
  );

  const { mutate, isLoading: mutateLoading } = useMutation(
    async (info) => {
      return await exchangeMoney({
        ...info,
        amount: +info.amount,
      });
    },
    {
      onSuccess: (res) => {
        toast.success(res?.data);
        refresh();
        ExchangeableBalance();
        form.reset({});
      },
    }
  );
  const onSubmit = (post) => {
    mutate(post);
  };
  return {
    form,
    isModalOpen,
    props,
    handleCancel,
    showModal,
    lang,
    receiver,
    sender,
    isLoading,
    data,
    setIsModalOpen,
    onSubmit,
    mutateLoading,
  };
};
