import request from "../helpers/createRequest";

export const getAllRegions = async () => {
  return await request("/api/regions/");
};
export const checkSertificateExportFcc = async (id) => {
  return await request("check_certificate/export_fss/" + id);
};
export const getAllOfertas = async () => {
  return await request("applicant/invoice/list/?service_type=exchangeable");
};
export const exchangeMoney = async (data) => {
  return await request.post("applicant/invoice-payment/list/", data);
};
export const refreshBalance = async () => {
  return await request.patch("applicant/invoice/list/");
};
export const refreshExchangeableBalance = async () => {
  return await request.patch(
    "applicant/invoice/list/?service_type=exchangeable"
  );
};
export const getDistrictsById = async (region) => {
  return await request(`/api/districts/?region=${region}`);
};
export const getFeaPointsById = async (region) => {
  return await request(`/api/points/?region=${region}`);
};
export const getFeaDistrictById = async (region) => {
  return await request(`/api/districts/?region=${region}`);
};
export const getMarketsById = async (dist) => {
  return await request(`/api/markets/?district=${dist}`);
};
export const getHsCodes = async (params) => {
  return await request("api/hs-codes/", { params });
};
export const getProductList = async (params) => {
  return await request("/api/products-for-local-fss/", { params });
};

export const innerFccCreate = async (data) => {
  return await request.post("/applicant/local-fss/list/", data);
};
export const innerFccUpdate = async (data) => {
  return await request.put("/applicant/local-fss/list/", data);
};

export const getAllCompaniesBox = async (params) => {
  return await request("/api/packaging-companies/", { params });
};

export const ImageCreate = async (data) => {
  return await request.post("api/common-file-upload/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
