import React from "react";
import redirectUrl from "../../../utils/navigate";

const Fum = () => {
  return (
    <>
      <p>
        <strong>1. Умумий қоидалар</strong>
      </p>
      <p>
        1.1.Ўзбекистон Республикаси Фуқаролик кодексининг 370-моддасига мувофиқ
        агар қуйида кўрсатилган шартлар қабул қилиниб, хизматлар учун тўлов
        амалга оширилса, қабул қилувчи юридик ёки жисмоний шахс Буюртмачига
        айланади.
      </p>
      <p>
        1.2.Буюртмачи томонидан ариза берилганда ҳамда мазкур оферта шартномасига кўра, зарарсизлантириш бўйича хизматларнинг шакллантирилган қийматининг камида 30 фоизи тўланганида, Офёрта тўлиқ ва сўзсиз қабул қилинган ҳисобланади.
      </p>
      <p>
        1.3.Буюртмачи оферта шартномаси (кейинги ўринларда - Шартнома) шартлари билан танишганидан сўнг, шартнома асосида шакллантирилган хизматлар учун суммани муддатларида тўлиқ тўлайди.
      </p>
      <p>
        1.4.Шартномада кўрсатилган зарарсизлантириш ишлари амалга
        оширилганлигини тасдиқловчи далолатнома Буюртмачи томонидан қабул
        қилиниб олинади.&nbsp;
      </p>
      <p>1.5.Шартнома чақириб олинмайди.</p>
      <p>
        1.6.Ўзбекистон Республикаси Фукаролик кодексининг 367-375-моддалари ва
        «Электрон ҳужжат айланиши тўғрисида»ги, «Электрон рақамли имзо
        тўғрисида»ги Ўзбекистон Республикаси Қонунларига мувофик Шартномада
        Буюртмачининг муҳри ва /ёки имзоси талаб этилмайди ҳамда шунга асосан ўз
        юридик кучини сақлаб қолади.
      </p>
      <p>
        <strong>2. Шартнома предмети</strong>
      </p>
      <p>
        2.1.Ўзбекистон Республикасининг «Ўсимликлар карантини тўғрисида»ги
        қонунига мувофиқ карантин остидаги маҳсулотларни етиштириш, тайёрлаш,
        саклаш, ташиш, кайта ишлаш ҳамда реализация қилиш билан шуғулланувчи
        давлат ва хўжалик бошқаруви органлари, юридик ва жисмоний шахслар:
      </p>
      <p>
        Агентлик назорати остида текширишга (кўздан кечиришга) ва ўсимликлар
        карантини объектларига карши курашишга доир ишларни, шунингдек ушбу
        карантин объектлари тарқалишининг олдини олишга доир тадбирларни амалга
        ошириши;
      </p>
      <p>
        хорижий мамлакатлардан ва Ўзбекистон Республикасининг ўсимликлар
        карантини остида деб эълон қилинган ҳудудларидан карантин остидаги
        маҳсулотни олиб киришда ўсимликлар карантини коидаларига риоя килиши;
      </p>
      <p>
        тегишли юклар сакланадиган омборхоналарда ҳар йили камида бир марта
        профилактик фумигация ишларини белгиланган тартибда амалга ошириши шарт.
      </p>
      <p>
        2.2.Зарарсизлантириш (фумигация) хизмати учун тўлов миқдори {" "}<a href={`${redirectUrl}fumigation?price=1`} target="_blank">www.cabinet.karantin.uz</a>{" "} электрон ҳавола орқали шахсий кабинетдаги нархлар рўйхати (прейскурант) ҳамда объект ҳажми (кв. метр) асосида шаклланади.
      </p>
      <p>
        2.3.Буюртмачи мазкур оферта шартномасининг электрон ҳаволаси орқали шакллантирилган сумманинг 30 фоизини олдиндан тўлаш мажбуриятини олади, қолган 70 фоиз суммани зарарсизлантириш ишлари ўтказилганлиги ҳақидаги далолатномасини олгандан сўнг 5 банк иш куни ичида, унга тақдим қилинган ҳисоб-фактура асосида ижрочининг хисоб рақамига тўлиқ тўлайди.
      </p>
      <p>
        2.4.Агар мазкур оферта шартномаси асосида шаклланган сумманинг 30 фоизи
        тўланмаган бўлса, уни қабул қилиш амалга оширилмаган ҳисобланади.
      </p>
      <p>
        2.5.Буюртмачи шаклланган суммани тўлиқ тўлаганидан сўнг, тарафлар ўртасида зарарсизлантириш хизматларини такдим этиш бўйича икки томонлама битим тузилган ва ижро этилган деб ҳисобланади.
      </p>
      <p>
        <strong>3.Тарафларнинг мажбуриятлари</strong>
      </p>
      <p>
        3.1.Агентлик, шартнома асосида шаклланган сумманинг 30 фоизи тўланган кундан бошлаб, 5 иш куни ичида махсулотни зарарсизлантириш мажбуриятини олади. Агар Буюртмачи ушбу шартномага мувофиқ хизматлардан воз кечган такдирда, тўлов суммаси тўлиқ ёки қисман ўзаро ҳисоб-китобларни солиштириш далолатномаси бўйича кўрсатилган хизматларга мутаносиб равишда қайтарилади.
      </p>
      <p>
        3.2.Агентлик буюртмачидан олинган маълумотларни ошкор қилмаслик
        мажбуриятини олади ва амалдаги Қонун талабларига мувофиқ асосли равишда
        маълумотни ошкор қилиш ҳолатларида мажбурият бузилган деб ҳисобланмайди.
      </p>
      <p>
        3.3.Зарарсизлантириш хизмати якунлангач, Агентлик Буюртмачига хизматни такдим этилганлигини тасдиқловчи электрон ёки қоғоз шаклдаги ҳисобварақ-фактура ва далолатнома такдим этиши шарт.
      </p>
      <p>
        3.4.Буюртмачи томонидан шартномада кўрсатилган тўлов, муддатида амалга
        оширилмаганида, кечиктирилган ҳар бир кун учун тўланиши лозим бўлган
        сумманинг 0,4 фоизи миқдорида, лекин жами тўланиши лозим бўлган
        сумманинг 50 фоизидан ошмаган миқдорда Агентликка пеня тўлайди.
      </p>
      <p>
        3.5.Агентлик томонидан зарарсизлантириш ишлари муддатида амалга
        оширилмаганида, кечиктирилган ҳар бир кун учун кўрсатилши лозим бўлган
        хизмат суммасининг 0,5 фоизи миқдорида, лекин жами хизмат кўрсатилиши
        лозим бўлган сумманинг 50 фоизидан ошмаган миқдорда Буюртмачига пеня
        тўлайди. &nbsp; &nbsp;
      </p>
      <p>
        <strong>4. Коррупцияга қарши қўшимча шартлар</strong>
      </p>
      <p>
        4.1.Тарафлар офер шартномасини тузишда, офертанинг амал қилиш муддатида
        ва ушбу муддат тугаганидан сўнг, оферта билан боғлиқ коррупциявий
        ҳаракатлар содир қилмаслик мажбуриятини оладилар.
      </p>
      <p>
        4.2.Тарафлар Адлия вазирлиги, Коррупцияга қарши курашиш агентлиги,
        Савдо-саноат палатасининг 2021 йил 12 ноябрдаги 1/4671, 01-19/7284,
        7/01-08-9544-сон қарорига илова билан тасдиқланган “Хўжалик юритувчи
        субъектлар томонидан шартномалар тузишда қўллаш учун тавсиявий
        характерга эга бўлган коррупцияга қарши қўшимча шартлар” (кейинги
        ўринларда - коррупцияга қарши қўшимча шартлар)га мувофиқ коррупциянинг
        олдини олиш чораларини тан олади ва уларга риоя этилиши бўйича
        ҳамкорликни таъминлайдилар.
      </p>
      <p>
        4.3.Ҳар бир тараф оферта тузилган пайтда бевосита ўзи ёки вакили ҳамда ижрочи орган мансабдор шахслари ва ходимлари томонидан, шартнома билан боғлиқ муносабатлар юзасидан Қонунга хилоф равишда пул, моддий қийматликлар берилмаганлигини, шартнома тузилиши эвазига норасмий пул ёки бошқа моддий қийматликлар олинишига йўл қўйилмаганлигини, таклиф этилмаганлигини, уларни беришга ваъда қилинмаганлигини, шунингдек моддий ёки ҳар қандай турдаги имтиёз, устунликлар олинмаганлигини (келажакда бу турдаги ҳаракатларни амалга ошириши мумкинлиги ҳақида таассурот қолдирилмаганлигини) кафолатлайди. 
      </p>
      <p>
        4.4.Тарафлар, улар томонидан шартнома доирасида жалб қилинган
        шахсларнинг (ёрдамчи пудратчи ташкилотлар, агентлар ва тарафлар назорати
        остидаги бошқа шахсларнинг) юқоридаги ҳаракатларни содир этмаслиги
        юзасидан оқилона чоралар кўради.
      </p>
      <p>
        4.5.Тарафлар, уларнинг назорати остида бўлган ва улар номидан ҳаракат
        қиладиган шахслар томонидан коррупцияга қарши белгиланган мажбуриятлар
        бузилганлиги ҳолатлари ҳақида хабар берилганлиги учун хабар берувчига
        тазйиқ ўтказилмаслигини кафолатлайдилар.
      </p>
      <p>
        4.6.Агар бир тарафга бошқа тарафнинг коррупцияга қарши қўшимча
        шартларнинг 4 ва 5-бандларида белгиланган мажбуриятларнинг бузилишига
        йўл қўйилганлиги маълум бўлиб қолса, иккинчи тарафни бу ҳақда зудлик
        билан хабардор қилиши ва ушбу тарафдан оқилона муддат ичида тегишли
        чоралар кўрилишини ва амалга оширилган ишлар юзасидан унга хабар
        қилишини талаб қилиши шарт. Тарафнинг талаби бўйича иккинчи тараф
        томонидан қоидабузарликларни бартараф қилиш бўйича оқилона муддат ичида
        тегишли чоралар кўрилмаган ёки кўриб чиқиш натижалари ҳақида хабардор
        қилмаган тақдирда, ушбу тараф зарарни тўлиқ қоплаб беришни талаб қилишга
        ҳақли. &nbsp; &nbsp; &nbsp; &nbsp;
      </p>
      <p>
        <strong>5.Жавобгарлик, низоларни ҳал этиш тартиби</strong>
      </p>
      <p>
        5.1.Тарафлар Ўзбекистон Республикаси қонунчилигига мувофиқ жавобгар
        бўладилар.
      </p>
      <p>
        5.2.Шартномани бажариш вақтида форс-мажор ҳолатлари: давлат ҳокимияти
        органларининг ҳаракатлари, ёнғин, сув тошқини, зилзила, бошқа табиий
        офатлар, электр энергиясининг йўқлиги, иш ташлашлар, фукаролик урушлари,
        тартибсизликлар, шунингдек Агентликнинг ўзига боғлиқ бўлмаган ҳолатларда
        ҳар қандай бошқа фавқулодда ҳодисалар юзага келиб шартнома шартлари
        бузилса, Агентлик жавобгарликдан озод этилади.
      </p>
      <p>
        5.3.Агентлик Буюртмачига унинг хизматларидан фойдаланишни таъминлайдиган
        умумий алоқа каналлари ёки хизматларининг сифати учун жавобгар эмас.
      </p>
      <p>
        5.4.Буюртмачининг Агентлик томонидан кўрсатилган хизматларга эътирози
        ёки шикоятлари <a href="mailto:info@karantin.uz">info@karantin.uz</a>
        .&nbsp;электрон почтаси орқали кўриб чиқиш учун қабул қилинади.
      </p>
      <p>
        5.5.Низоли вазиятларни кўриб чиқишда Агентлик Буюртмачидан низога доир
        барча тегишли ҳужжатларни талаб қилишга ҳақли.
      </p>
      <p>
        5.6.Тарафлар низо ва келишмовчиликлар юзага келганда судгача низоларни
        ҳал этиш тартибини қўллаш мажбуриятини оладилар. Агар низони судгача ҳал
        қилишнинг имкони бўлмаса, тарафлар судга мурожаат қилиш ҳуқуқига
        эгадирлар.
      </p>
      <p>
        <strong>6.Алоҳида шартлар</strong>
      </p>
      <p>
        6.1.Буюртмачи Шартномани қабул қилиш ҳамда бажариш учун зарур бўлган
        барча ҳуқуқ ва ваколатларга эга.
      </p>
      <p>
        6.2.Агентлик ушбу Шартноманинг исталган шартларини ўзгартириш ёки
        тўлдириш ҳуқуқини ўз веб-сайтида барча ўзгаришларни эълон килиш оркали
        саклаб колади.
      </p>
      <p>
        6.3. Шартнома тўлов амалга оширилган кундан бошлаб ижро этилгунигача
        амал қилади.
      </p>
      <p>
        6.4.Ушбу Шартномада назарда тутилмаган барча масалалар Ўзбекистон
        Республикаси конунчилиги меъёрлари асосида тартибга солинади.
      </p>
    </>
  );
};

export default Fum;
