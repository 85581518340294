import { Modal, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
// import "./modal.css";
import queryString from "query-string";
import _ from "lodash";
import { fetchRequest } from "../../helpers/createRequest";
import Loader from "../../components/loader/Loader";

const CountryDetailModal = ({ open, setOpen, id, title, ...props }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({});

  useEffect(() => {
    async function getProductByID() {
      //   setIsLoading(true);
      await fetchRequest
        .get(`export/country/?country=${id}`)
        .then((res) => {
          console.log(res.data.results);
          setData(res.data.results);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
    getProductByID();
  }, []);

  return (
    <Modal
      open={open}
      onOk={setOpen}
      onCancel={setOpen}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={475}
      className="add-fcc-modal"
    >
      {isLoading && <Loader />}
      <h1>{title}</h1>
      <div
        className="detail-modal"
        dangerouslySetInnerHTML={{
          __html: data[0]?.afr_products,
        }}
      ></div>
    </Modal>
  );
};

export default CountryDetailModal;
