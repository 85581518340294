import { Empty, Pagination } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import request from "../../helpers/createRequest";
import moment from "moment";
import queryString from "query-string";
import { useSelector } from "react-redux";
import TableRow from "./TableRow";
import "./balance.css";
import { TransferButton } from "./transfer";

const Balance = (props) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.language.lang);

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 10;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  const today = moment();

  const [count, setCount] = useState(0);
  const [payments, setPayments] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    getPaymentLists();
  }, [queryParams.page]);

  async function getPaymentLists() {
    setIsLoading(true);
    await request
      .get(`/applicant/invoice-payment/list/?limit=${LIMIT}&offset=${OFFSET}`, {
        params: {
          service_type: queryParams.service_type,
          added_at_gte: queryParams.added_at_gte,
          added_at_lte: queryParams.added_at_lte,
        },
      })
      .then((res) => {
        // console.log(res.data.results);
        setPayments(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  async function getServices() {
    await request.get(`/api/services/?lang=${lang}`).then((res) => {
      var result = Object.keys(res.data).map((key) => {
        return { id: Number(key), value: res.data[key] };
      });
      setServices(result);
    });
  }

  const onFilter = (itemValue, itemKey) => {
    const rootParams = { ...queryParams };
    if (itemValue) {
      rootParams[itemKey] = itemValue;
    } else {
      rootParams[itemKey] = null;
    }
    props.history.push({
      search: queryString.stringify(rootParams, { skipNull: true }),
    });
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="balance-page">
      <div className="top">
        <h1>{t("navbar.balans")}</h1>
        <div className="right">
          {/* <div style={{ display: "flex" }}>
            <TransferButton
              services={services}
              getPaymentLists={getPaymentLists}
            />
          </div> */}
        </div>
      </div>

      <div className="filter active-filter">
        <input
          type="date"
          max={today.format("YYYY-MM-DD")}
          value={queryParams.added_at_gte || ""}
          onChange={(e) => onFilter(e.target.value, "added_at_gte")}
        />
        <input
          type="date"
          min={queryParams.added_at_gte}
          max={today.format("YYYY-MM-DD")}
          value={queryParams.added_at_lte || ""}
          onChange={(e) => onFilter(e.target.value, "added_at_lte")}
        />
        <select
          value={queryParams.service_type || ""}
          onChange={(e) => onFilter(e.target.value, "service_type")}
        >
          <option value={""}>Все</option>
          {services?.map((s, idx) => (
            <option value={s.id} key={idx}>
              {s.value}
            </option>
          ))}
        </select>
        <button onClick={getPaymentLists}>{t("balance.show")}</button>
      </div>

      {isLoading ? <Loader /> : null}

      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("balance.invoice-num")}</th>
              <th>{t("balance.order-num")}</th>
              <th>{t("balance.sum")}</th>
              <th>{t("balance.description")}</th>
              <th>{t("balance.service")}</th>
              <th>{t("balance.date-pay")}</th>
            </tr>
          </thead>
          <tbody>
            {payments?.map((item, index) => {
              const date = new Date(item.updated_at);
              return (
                <TableRow key={index} index={index} item={item} date={date} />
              );
            })}
          </tbody>
        </table>
      </div>
      {_.isEmpty(payments) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
      {!isLoading && !_.isEmpty(payments) && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Balance;
