import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getCountries } from "../../../../../apis/acts/common";
import { getAllRegions } from "../../../../../apis/innerFss";
import { REACT_QUERY_KEYS } from "../../../../../constants/reactQueryKeys";
export const product_box_type = [
  { value: "952", label: "Polipropilen qop" },
  { value: "951", label: "Polipropilen to'rli setka" },
  { value: "012", label: "Qop" },
];
export const useMyForm = () => {
  const { control, watch } = useFormContext();
  const { t } = useTranslation();
  const props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };
  const transport_type = [
    { value: 1, label: "Temir yo‘l/Railway" },
    { value: 2, label: "Suv/Vessel" },
    { value: 3, label: "Avia/Aircraft" },
    { value: 4, label: "Avto/Road vehicle" },
  ];
  const transport_condition_type = [
    { value: "Tabiiy shaklida/Ambient", label: "Tabiiy shaklida/Ambient" },
    { value: "Sovutilgan/Chilled", label: "Sovutilgan/Chilled" },
    { value: "Muzlatilgan/Frozen", label: "Muzlatilgan/Frozen" },
  ];

  const { data: regions, isLoading: regionsLoading } = useQuery({
    queryFn: getAllRegions,
    queryKey: [REACT_QUERY_KEYS.GET_REGIONS],
    select: (res) => {
      return res.data?.map((region) => ({
        ...region,
        value: region.id,
        label: region.name_local,
      }));
    },
  });
  const { data: countries, isLoading: countriesLoading } = useQuery({
    queryFn: getCountries,
    queryKey: [REACT_QUERY_KEYS.GET_COUNTRIES],
    select: (res) => {
      return res.data?.map((c) => ({
        ...c,
        value: c.id,
        label: c.name_en,
      }));
    },
  });

  return {
    t,
    props,
    regionsLoading,
    regions,
    countriesLoading,
    countries,
    transport_type,
    transport_condition_type,
    watch,
  };
};
