import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import mineral from "../../assets/icons/mineral.svg";
import clinics from "../../assets/icons/clinics.svg";
import biolabs from "../../assets/icons/biolabs.svg";
import packaging from "../../assets/icons/package.svg";
import exportCountry from "../../assets/icons/export-country.svg";
import recs from "../../assets/icons/recs.svg";
import labs from "../../assets/icons/labs.svg";
import product from "../../assets/icons/product.svg";
import WOW from "wow.js";
import "./main.css";

const Agro = () => {
  const { t } = useTranslation();

  const AGRO_MENU = [
    {
      title: t("agro.mineral"),
      img: mineral,
      path: "/agrohelp/1",
    },
    {
      title: t("agro.clinics"),
      img: clinics,
      path: "/agrohelp/2",
    },
    {
      title: t("agro.biolabs"),
      img: biolabs,
      path: "/agrohelp/3",
    },
    {
      title: t("agro.package"),
      img: packaging,
      path: "/agrohelp/4",
    },
    {
      title: t("agro.export-country"),
      img: exportCountry,
      path: "/agrohelp/5",
    },
    {
      title: t("agro.export-product"),
      img: product,
      path: "/agrohelp/8",
    },
    {
      title: t("agro.recs"),
      img: recs,
      path: "/agrohelp/6",
    },
    {
      title: t("agro.labs"),
      img: labs,
      path: "/agrohelp/7",
    },
  ];

  useEffect(() => {
    new WOW().init();
  }, []);

  return (
    <div className="inner-fcc-page">
      <div className="top-kr">
        <h1>{t("navbar.agro")}</h1>
      </div>

      <div className="agro-main">
        <div className="agro-cards">
          {AGRO_MENU?.map((el, idx) => (
            <Link to={el.path} key={idx}>
              <div
                className="agro-card wow fadeInLeft"
                data-wow-duration={`${0.1 * (idx + 1)}s`}
                data-wow-delay={`${0.1 * (idx + 1)}s`}
              >
                <div className="img-card">
                  <img src={el.img}></img>
                </div>
                <p>{el.title}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Agro;
