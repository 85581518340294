import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const Info = ({ open, setOpen, data }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={557}
      className="add-fcc-modal"
    >
      <h1>{t("balance.info")}</h1>
      <div className="payment-info">
        <p>{data}</p>
      </div>
    </Modal>
  );
};

export default Info;
