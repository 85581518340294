import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { applicationCreate, applicationUpdate } from "../../../apis/guvohnoma";
import { REACT_QUERY_KEYS } from "../../../constants/reactQueryKeys";
import { language } from "../../../redux/actions/langAction";
import { getUser } from "../../../utils/user";
import { APP_LANG, FORM_TYPE } from "./const";

export const useModal = ({ setIsModalOpen, isModalOpen, data }) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const user = getUser();
  const { lang } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const products = data?.product_register_apps?.[0];
  const queryClient = useQueryClient();

  const types = FORM_TYPE?.map((type) => ({
    value: type,
    label: t(type === "CHEM" ? "navbar.chemicals" : "navbar.minerals"),
  }));
  const app_lang = APP_LANG?.map((type) => ({
    value: type,
    label: t(type === "UZ" ? "O'zbekcha" : "Русский"),
  }));
  const steps = data
    ? [
        {
          title: t("re-register"),
          description: t("re-register desc"),
          target: () => ref3.current,
          nextButtonProps: {
            children: t("lab.continue"),
          },
          prevButtonProps: {
            children: t("lab.back"),
          },
        },
      ]
    : [
        {
          title: t("select app-type"),
          description: t("select app-type desc"),
          target: () => ref1.current,
          nextButtonProps: {
            children: t("lab.continue"),
          },
          prevButtonProps: {
            children: t("lab.back"),
          },
        },
        {
          title: t("select app-lang"),
          description: t("select app-lang desc"),
          target: () => ref2.current,
          nextButtonProps: {
            children: t("lab.continue"),
          },
          prevButtonProps: {
            children: t("lab.back"),
          },
        },
        {
          title: t("re-register"),
          description: t("re-register desc"),
          target: () => ref3.current,
          nextButtonProps: {
            children: t("lab.continue"),
          },
          prevButtonProps: {
            children: t("lab.back"),
          },
        },
      ];

  const form = useForm({
    mode: "onChange",
    defaultValues: data
      ? {
          company: {
            ...data.company,
            owner_fullname: user.oneIdInfo.director,
            phone: user.user.mob_phone_no,
            email: user.user.email,
            inn: user.user.pin_or_tin,
          },
          product: {
            ...products,
            substances: products?.substances?.map((s) => s.id),
            for_crop: products?.for_crop?.map((s) => s.id),
            manufacturer: products?.manufacturer?.id,
            product_type: products?.product_type?.id,
            product_shape: products?.product_shape?.id,
          },
          application: {
            application_id: data.id,
            is_reregister: data.is_reregister,
            app_type:
              data?.product_type === "Chemical" ? FORM_TYPE[0] : FORM_TYPE[1],
            file: data?.file,
            comment: data?.comment,
            language: data.language,
            old_cert_number: data?.old_cert_number,
            old_certificate: data?.old_certificate,
          },
        }
      : {
          company: {
            owner_fullname: user.oneIdInfo.director,
            phone: user.user.mob_phone_no,
            email: user.user.email,
            inn: user.user.pin_or_tin,
          },
          product: {
            valid_after_open_unit: "MONTH",
            valid_period_unit: "MONTH",
          },
          application: {
            is_reregister: true,
            app_type: FORM_TYPE[0],
            language: lang === "uz" ? APP_LANG[0] : APP_LANG[1],
          },
        },
  });

  const onSubmit = (post) => {
    const info = {
      "company.company_id": post?.company?.id,
      "application.application_id": data?.id,
      "product.product_id": products?.id,

      "application.language": post.application.language,
      "application.comment": post.application.comment,
      "application.app_type": post.application.app_type,
      "application.is_reregister": post.application.is_reregister,
      "application.file": post.application.file?.file,

      "application.old_cert_number": !post.application.is_reregister
        ? undefined
        : post.application.old_cert_number,
      "application.old_certificate": !post.application.is_reregister
        ? undefined
        : post.application.old_certificate?.file,

      "company.phone": post.company.phone,
      "company.email": post.company.email,
      "company.inn": post.company.inn,
      "company.owner_fullname": post.company.owner_fullname,
      "company.post_index": post.company.post_index,
      "company.fax": post.company.fax,
      "company.post_address": post.company.post_address,
      "company.requisites": post.company.requisites?.file,

      "product.file": post.product?.file?.file,

      "product.man_consent_letter": post.application.is_reregister
        ? undefined
        : post.product?.man_consent_letter?.file,
      "product.man_certificate": post.application.is_reregister
        ? undefined
        : post.product?.man_certificate?.file,
      "product.man_certificate_translated": post.application.is_reregister
        ? undefined
        : post.product?.man_certificate_translated?.file,
      "product.license": post.application.is_reregister
        ? undefined
        : post.product?.license?.file,

      "product.tech_passport": post.application.is_reregister
        ? undefined
        : post.product?.tech_passport?.file,
      "product.lab_conclusion": post.application.is_reregister
        ? undefined
        : post.product?.lab_conclusion?.file,
      "product.quality_certificate": post.application.is_reregister
        ? undefined
        : post.product?.quality_certificate?.file,
      "product.package_label": post.application.is_reregister
        ? undefined
        : post.product?.package_label?.file,

      "product.name": post.product?.name,
      "product.generic_name": post.product?.generic_name,
      "product.substances":
        post.application?.app_type === "CHEM"
          ? post.product?.substances?.join(",")
          : undefined,
      "product.substances_string":
        post.application?.app_type === "CHEM"
          ? undefined
          : post.product?.substances_string,
      "product.for_crop":
        post.application?.app_type === "CHEM" || post.application.is_reregister
          ? post.product?.for_crop.join(",")
          : 83,
      "product.product_shape_id": post.product?.product_shape,
      "product.spend_rate": post.product?.spend_rate,
      "product.concentration": post.product?.concentration,
      "product.harmful_org": post.product?.harmful_org,
      "product.use_method": post.product?.use_method,
      "product.use_period": post.product?.use_period,
      "product.use_repeat": post.product?.use_repeat,
      "product.valid_after_open": post.product?.valid_after_open,
      "product.valid_after_open_unit": post.product?.valid_after_open_unit,
      "product.valid_period": post.product?.valid_period,
      "product.valid_period_unit": post.product?.valid_period_unit,
      "product.before_harvest_day": post.product?.before_harvest_day,
      "product.valid_saving_info": post.product?.valid_saving_info,
      "product.package_volume": post.product?.package_volume,
      "product.package_volume_type": post.product?.package_volume_type,
      "product.manufacturer_id": post.product?.manufacturer,
      "product.product_type_id": post.product?.product_type,
      "product.chemical_info":
        post.application?.app_type === "CHEM" && !post.application.is_reregister
          ? undefined
          : post.product?.chemical_info?.file,
    };
    mutate(info);
  };

  const { mutate, isLoading } = useMutation(
    async (info) => {
      return data
        ? await applicationUpdate(info)
        : await applicationCreate(info);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_KEYS.MINERAL_APP_LIST],
        });
        toast.success(t(data ? "success edit toast" : "success create toast"));
        setIsModalOpen(false);
      },
      onError: (er) => {
        const errors = Object.entries(er?.response?.data);
        errors?.forEach((i) => {
          const field_name = i?.[0];
          const error = i?.[1]?.[0];
          toast.error(error);
          form.setFocus(field_name);
          form.setError(field_name, {
            type: "manual",
            message: error,
          });
        });
      },
    }
  );

  const changeLang = (lang) => {
    const l = lang === "UZ" ? "uz" : "ru";
    i18next.changeLanguage(l);
    dispatch(language(l));
  };

  useEffect(() => {
    if (isModalOpen) {
      if (!localStorage.getItem("tour")) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [isModalOpen]);
  useEffect(() => {
    if (data) {
      changeLang(data.language);
    }
  }, [data]);

  return {
    onSubmit,
    isLoading,
    open,
    types,
    changeLang,
    app_lang,
    steps,
    ref1,
    ref2,
    ref3,
    setOpen,
    form,
  };
};
