import { FileImageFilled, UploadOutlined } from "@ant-design/icons";
import { Button, Space, Typography, Upload } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

const { Text, Title } = Typography;
export const FormFileUploadExcel = ({
  name,
  control,
  maxCount = 1,
  multiple = false,
  label,
  rules,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    field: { ref, ...fd },
    fieldState,
  } = useController({
    name,
    control,
    rules: rules,
  });
  return (
    <Space
      direction="vertical"
      className="excel"
      style={{
        width: "100%",
        ".anticon": {
          color: "#000 !important",
        },
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "22px 24px",
          borderRadius: "10px",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px dashed #036323",
          textAlign: "center",
        }}
      >
        <Title
          style={{
            margin: 0,
            fontSize: "16px",
            fontWeight: 500,
          }}
          level={4}
        >
          {t("excel.upload_file")}
        </Title>
        <p
          style={{
            paddingTop: "4px",
            paddingBottom: "10px",
            color: "#8393A6",
            margin: 0,
            fontSize: "12px",
          }}
        >
          {t("excel.upload_file_desc")}
        </p>
        <Upload
          multiple={multiple}
          accept=".xls,.xlsx"
          {...props}
          {...fd}
          {...fieldState}
          maxCount={maxCount}
          beforeUpload={(e) => {
            fd.onChange(e);
            return false;
          }}
        >
          <Button
            ref={ref}
            style={
              fieldState?.error ? { borderColor: "red", color: "red" } : {}
            }
            icon={<UploadOutlined />}
          >
            {t("inner-fcc.upload")}
          </Button>
        </Upload>
        {typeof fd.value === "string" && (
          <a
            href={fd.value}
            target="_blank"
            style={{ color: "#036323", fontWeight: "500" }}
            className="file-link"
            rel="noreferrer"
          >
            <Space style={{ gap: "4px", padding: "0 4px" }}>
              <FileImageFilled /> {t("seeFile")}
            </Space>
          </a>
        )}
        {fieldState?.error?.message && (
          <Text type="danger">{t(fieldState.error.message)}</Text>
        )}
      </div>
    </Space>
  );
};
