import React from "react";
import './doc.css'

const Akd = () => {
  return (
    <>
      <p>
        <strong>1. Умумий қоидалар</strong>
      </p>
      <p>
        1.1.Ўзбекистон Республикаси Фуқаролик кодексининг 370-моддасига мувофиқ
        агар қуйида кўрсатилган шартлар қабул қилиниб, хизматлар учун тўлов
        амалга оширилса, қабул қилувчи юридик ёки жисмоний шахс Ариза берувчига
        айланади.
      </p>
      <p>
        1.2.Ариза берувчи томонидан Оферта шартномасини тўлиқ ва сўзсиз қабул
        қилиш (таклиф маъқулланиш) пайтида юкнинг келиши ҳамда Транспорт
        бирлигини очиш тўғрисидаги далолатнома электрон ҳужжат кўринишида
        расмийлаштирган ҳолда карантин кўриги ўтказиш учун йиғим
        ундирилади.&nbsp;
      </p>
      <p>
        1.3.Юкнинг келиши ва транспорт бирлигини очиш тўғрисидаги
        далолатноманинг расмийлаштирилиши Ариза берувчининг{" "}
        <a href="http://singlewindow.uz">http://singlewindow.uz</a>{" "}
        веб-сайтидаги шахсий саҳифасига электрон ҳужжат шаклида юборилади ва
        мижоз мазкур далолатномани электрон рақамли имзоси орқали тасдиқлайди.
      </p>
      <p>
        1.4.Ариза берувчи Шартномани белгиланган тартибда қабул қилиб, шартнома
        матнида келтириб ўтилган шаклдаги барча шартлар билан танишиб
        чиққанлигини, рози бўлганлигини ҳамда тўлиқ ва сўзсиз қабул қилганлигини
        тасдиқлайди.
      </p>
      <p>
        1.5.Ариза берувчи ушбу ҳужжатнинг 2-бандида кўрсатилган тартибда
        Шартномани қабул қилади ва унда белгиланган шартлар асосида Шартнома
        тузилишига розилик билдиради.
      </p>
      <p>1.6.Шартнома чақириб олинмайди.</p>
      <p>
        1.7.Ўзбекистон Республикаси Фуқаролик кодексининг 367-375-моддалари ва
        «Электрон ҳужжат айланиши тўғрисида»ги, «Электрон рақамли имзо
        тўғрисида»ги Ўзбекистон Республикаси Қонунларига мувофиқ Шартномада
        Аризачининг муҳри ва / ёки имзоси талаб этилмайди ҳамда шунга асосан ўз
        юридик кучини сақлаб қолади.
      </p>
      <p>
        <strong>2. Шартнома предмети</strong>
      </p>
      <p>
        2.1.Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2018 йил 29 январдаги
        № 65-сонли «Ўзбекистон Республикаси ҳудудини ўсимликлар карантинидаги
        зарарли организмлардан ҳимоя қилиш ва Ўсимликлар карантини соҳасида
        рухсат бериш тартиб-таомилларидан ўтиш тартиби тўғрисидаги низомларни
        тасдиқлаш ҳақида»ги қарорига мувофиқ, Ариза берувчи карантин кўриги
        ўтказиш учун қуйидаги тартибда йиғим суммасини тўлайди:
      </p>
      <table className="doc-table">
        <thead>
          <tr>
            <th>Т/р</th>
            <th>Номи</th>
            <th>Бир бирлик учун йиғим суммаси</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>Ҳар бир транспорт бирлигидаги карантин остидаги маҳсулотлар карантин кўригидан ўтказиш; Транспорт бирлигини очиш далолатномаси расмишлаштириш.</td>
            <td>Базавий ҳисоблаш миқдорининг бир баравари миқдорида.</td>
          </tr>
        </tbody>
        
      </table>
      <p>
        2.2.&nbsp;Тўлов тартиби: ҳар бир транспорт бирлиги учун йиғим миқдори
        100% олдиндан тўланади.
      </p>
      <p>
        Агар йиғим белгиланган кундан бошлаб беш кун ичида тўланмаса,
        Буюртмачининг аризаси бекор қилинади ва уни қабул қилиш&nbsp;амалга
        оширилмаган&nbsp;ҳисобланади.
      </p>
      <p>
        2.3. Тўлиқ ҳисоб-китоб далолатнома расмийлаштириш санасидаги базавий
        ҳисоблаш миқдоридан келиб чиқиб, йиғим ҳисобланади.
      </p>
      <p>
        <strong>&nbsp; 3. Тарафларнинг мажбуриятлари</strong>
      </p>
      <p>
        3.1.Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2018 йил 29 январдаги
        «Ўзбекистон Республикаси ҳудудини ўсимликлар карантинидаги зарарли
        организмлардан ҳимоя қилиш ва Ўсимликлар карантини соҳасида рухсат бериш
        тартиб-таомилларидан ўтиш тартиби тўғрисидаги низомларни тасдиқлаш
        ҳақида»ги № 65-сонли қарорига мувофиқ карантин остидаги маҳсулот
        шартноманинг 3-бандида назарда тутилган тартибда транспорт бирлигини
        очиш ва тўловни тўлаш тўғрисидаги далолатнома Ариза берувчи томонидан
        тасдиқланганидан сўнг, Агентлик белгиланган тартибда карантин кўригини
        ўтказади.
      </p>
      <p>
        3.2.Карантин кўригини ўтказишда белгиланган тартибда намуналар
        лаборатория текширувлари учун олинади. Олинган намуналарда ўсимликлар
        карантини кўриги ёки лаборатория текшируви натижасида ўсимликлар
        карантини назоратидаги маҳсулотларда ўсимликлар карантинидаги ва бошқа
        зарарли организмлар мавжуд эмаслиги аниқланса, ушбу юкдан фойдаланиш
        учун Агентлик томонидан Ўзбекистон Республикаси Вазирлар Маҳкамасининг
        2018 йил 29 январдаги № 65-сонли қарори билан тасдиқланган шаклда
        ўсимликлар карантини кўриги далолатномаси расмийлаштириб берилади.
      </p>
      <p>
        3.3.Юк ва транспорт воситаларида карантиндаги зарарли организмлар
        борлиги аниқланса, уларнинг намуналари тасдиқлаш учун тегишли ўсимликлар
        карантини лабораторияларига юборилади. Агар юк ва транспорт воситаларида
        карантин остидаги зарарли организмлар мавжуд бўлса ва уларни
        зарарсизлантиришнинг иложи бўлмаса, Агентлик юклар учун Ўсимликлар
        карантини кўриги далолатномасини беришдан рад этади ҳамда ушбу юк
        эскпортёр давлатга қайтарилади ёки тегишли тартибда йўқ қилинади.
      </p>
      <p>
        <strong>4.Коррупцияга қарши қўшимча шартлар</strong>
      </p>
      <p>
        4.1.Тарафлар оферта тузишда, офертанинг амал қилиш муддатида ва ушбу
        муддат тугаганидан сўнг, оферта билан боғлиқ коррупциявий ҳаракатлар
        содир қилмасликка келишиб оладилар.
      </p>
      <p>
        4.2.Тарафлар Адлия вазирлиги, Коррупцияга қарши курашиш агентлиги,
        Савдо-саноат палатасининг 2021 йил 12 ноябрдаги 1/4671, 01-19/7284,
        7/01-08-9544-сон қарорига илова билан тасдиқланган “Хўжалик юритувчи
        субъектлар томонидан шартномалар тузишда қўллаш учун тавсивий характерга
        эга бўлган коррупцияга қарши қўшимча шартлар” (кейинги ўринларда -
        коррупцияга қарши қўшимча шартлар)га мувофиқ коррупциянинг олдини олиш
        чораларини тан олади ва уларга риоя этилиши бўйича ҳамкорликни
        таъминлайдилар.
      </p>
      <p>
        4.3.Ҳар бир тараф оферта тузилган пайтда бевосита ўзи ёки унинг ижроия
        органлари, мансабдор шахслари ва ходимлари томонидан шартнома билан
        боғлиқ муносабатлар юзасидан қонунга хилоф равишда пул, моддий
        қийматликлар берилмаганлигини, шартнома тузилиши эвазига норасмий пул
        ёки бошқа моддий қийматликлар олинишига йўл қўйилмаганлигини, таклиф
        этилмаганлигини, уларни беришга ваъда қилинмаганлигини, шунингдек моддий
        ёки ҳар қандай турдаги имтиёз, устунликлар олинмаганлигини (келажакда бу
        турдаги ҳаракатларни амалга ошириши мумкинлиги ҳақида таассурот
        қолдирилмаганлигини) кафолатлайди.&nbsp;
      </p>
      <p>
        4.4.Тарафлар, улар томонидан шартнома доирасида жалб қилинган
        шахсларнинг (ёрдамчи пудратчи ташкилотлар, агентлар ва тарафлар назорати
        остидаги бошқа шахсларнинг) юқоридаги ҳаракатларни содир этмаслиги
        юзасидан оқилона чоралар кўради.
      </p>
      <p>
        4.5.Тарафлар уларнинг назорати остида бўлган ва улар номидан ҳаракат
        қиладиган шахслар томонидан коррупцияга қарши белгиланган мажбуриятлар
        бузилганлиги ҳолатлари ҳақида хабар берилганлиги учун уларга тазйиқ
        ўтказилмаслигини кафолатлайдилар.
      </p>
      <p>
        4.6.Агар бир тарафга бошқа тарафнинг коррупцияга қарши қўшимча
        шартларнинг 4 ва 5-бандларида белгиланган мажбуриятларнинг бузилишига
        йўл қўйилганлиги маълум бўлиб қолса, иккинчи тарафни бу ҳақда зудлик
        билан хабардор қилиши ва ушбу тарафдан оқилона муддат ичида тегишли
        чоралар кўрилишини ва амалга оширилган ишлар юзасидан унга хабардор
        қилишини талаб қилиши шарт. Тарафнинг талаби бўйича иккинчи тараф
        томонидан қоидабузарликларни бартараф қилиш бўйича оқилона муддат ичида
        тегишли чоралар кўрилмаган ёки кўриб чиқиш натижалари ҳақида хабардор
        қилмаган тақдирда, ушбу тараф зарарни тўлиқ қоплаб беришни талаб қилишга
        ҳақли.
      </p>
      <p>
        <strong>5.Жавобгарлик, низоларни ҳал этиш тартиби</strong>
      </p>
      <p>
        5.1.Тарафлар Ўзбекистон Республикаси қонунчилигига мувофиқ жавобгар
        бўладилар.
      </p>
      <p>
        5.2.Шартномани бажариш вақтида форс-мажор ҳолатлари: давлат ҳокимияти
        органларининг ҳаракатлари, ёнғин, сув тошқини, зилзила, бошқа табиий
        офатлар, электр энергиясининг йўқлиги, иш ташлашлар, фуқаролик урушлари,
        тартибсизликлар, шунингдек юқорида санаб ўтилмаган ҳолатлар билан
        чекланмаган ҳар қандай бошқа фавқулодда ҳодисалар юзага келиб, Шартнома
        шартлари бузилса, Агентлик жавобгарликдан озод этилади.
      </p>
      <p>5.3.Агентлик қуйидагилар учун жавобгар эмас:</p>
      <p>
        - Ариза берувчига ўз хизматларидан фойдаланишни таъминлайдиган умумий
        алоқа каналлари ёки хизматларининг сифати учун;
      </p>
      <p>
        - Ариза берувчи томонидан “<u>http</u>
        <u>://</u>
        <u>singlewindow</u>
        <u>.</u>
        <u>uz</u>” сайтида оммавий тарзда тақдим этилган маълумотлар учун.
      </p>
      <p>
        5.3.Ариза берувчининг Агентлик томонидан кўрсатилган хизматларга
        эътирози ёки шикоятлари “<u>info</u>@karantin”&nbsp;электрон почтаси
        орқали кўриб чиқиш учун қабул қилинади.
      </p>
      <p>
        5.4.Низоли вазиятларни кўриб чиқишда Агентлик ариза берувчидан низога
        доир барча тегишли ҳужжатларни талаб қилишга ҳақли.
      </p>
      <p>
        5.5.Тарафлар низо ва келишмовчиликлар юзага келганда, судгача низоларни
        ҳал этиш тартибини қўллаш мажбуриятини оладилар. Агар низони судгача ҳал
        қилишнинг имкони бўлмаса, тарафлар судга мурожаат қилиш ҳуқуқига
        эгадирлар.
      </p>
      <p>
        <strong>6. Алоҳида шартлар</strong>
      </p>
      <p>
        6.1.Буюртмачи Шартномани қабул қилиш ва бажариш учун зарур бўлган барча
        хуқуқ ва ваколатларга эга.
      </p>
      <p>
        6.2.Агентлик ушбу Шартноманинг исталган шартларини ўзгартириш ёки
        тўлдириш ҳуқуқини ўз&nbsp;
        <br />
        &nbsp;веб-сайтида барча ўзгаришларни эълон қилиш орқали сақлаб қолади.
        <br />
        6.3.Ушбу Шартномада назарда тутилмаган барча масалалар Ўзбекистон
        Республикаси қонунчилиги меъёрлари асосида тартибга солинади.
      </p>
    </>
  );
};

export default Akd;
