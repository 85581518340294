import { Button, Col, Flex, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Prdetail } from "./components/prdetail";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

export const Product = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "products",
  });
  useEffect(() => {
    if (fields?.length === 0) {
      append(-1);
    }
  }, []);
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title style={{ marginBottom: 0 }} level={4}>
          {t("inner-fcc.prod")}
        </Title>
      </Col>
      {fields.map((field, index) => (
        <Prdetail key={field.id} field={field} index={index} />
      ))}
      <Col span={24} styles={{ marginBottom: "16px" }}>
        <Flex align="center" justify="flex-end">
          <Flex align="center" gap="16px">
            {fields?.length > 0 && (
              <Button danger onClick={() => remove(-1)}>
                -
              </Button>
            )}
            <Button onClick={() => append(-1)}>+</Button>
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
};
