import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { getUser } from "../../../../utils/user";

export const ModalHoc = ({ children }) => {
  const { t } = useTranslation();
  const { user } = getUser();
  const warning = () => {
    Modal.warning({
      title: t("invoice.entityError"),
      content: t("yuridik error desc"),
      okType: "default",
    });
  };

  if (user?.pin_or_tin?.length === 9) {
    return <>{children}</>;
  } else {
    return (
      <>
        <Button
          style={{ boxShadow: "none" }}
          size="large"
          type="primary"
          onClick={warning}
        >
          {t("inner-fcc.single")}
        </Button>
      </>
    );
  }
};
