import { Button, Col, Modal, Row, Space } from "antd";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { Forms } from "./forms/forms";
import { Product } from "./product";
import "./style.css";
import { useModal } from "./useAdd";
import { EditOutlined } from "@ant-design/icons";
export const AddOrEditComponent = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { onSubmit, form, isLoading } = useModal(setOpen, data);
  const { t } = useTranslation();
  return (
    <div className="element">
      {data ? (
        <Button shape="circle" type="primary" onClick={() => setOpen(true)}>
          <EditOutlined />
        </Button>
      ) : (
        <button className="button-active" onClick={() => setOpen(true)}>
          {t("inner-fcc.single")}
        </button>
      )}

      <Modal
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        centered
        destroyOnClose={true}
        title={null}
        footer={null}
        closeIcon={<MdClose className="icon" />}
        width={1200}
        className="my-modal"
      >
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h1>{t("inner-fcc.single")}</h1>

            <Row gutter={16} className="main-content">
              <Col span={24}>
                <Forms />
              </Col>
              <Col span={24}>
                <Product />
              </Col>
            </Row>
            <Space
              style={{
                display: "flex",
                paddingTop: "16px",
                justifyContent: "center",
              }}
            >
              <Button
                size="large"
                style={{ boxShadow: "none", minWidth: "200px" }}
                onClick={() => setOpen(false)}
              >
                {t("inner-fcc.cancel")}
              </Button>
              <Button
                style={{ boxShadow: "none", minWidth: "200px" }}
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t("inner-fcc.send")}
              </Button>
            </Space>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};
