import styled from "styled-components";
export const Container = styled.div`
  border-radius: 8px;
  background-color: #fff;
  width: 21cm;
  height: 28.7cm;
  padding: 1.5cm;
  margin: auto;
  position: relative;
  user-select: none;

  .img-placeholder {
    width: 80%;
    opacity: 0.05;
    position: absolute;
    top: 16%;
    left: 10%;
  }

  li {
    padding-top: 2px;
    padding-bottom: 2px;
    list-style-type: circle;
    width: 100%;
  }
  .active-text {
    font-weight: bold;
    text-decoration: underline;
    list-style-type: disc;
  }
  .page-count {
    position: absolute;
    bottom: 1cm;
    left: 1.5cm;
    width: calc(100% - 3cm);
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #999;
    padding: 2px;
    /* display: none; */
    display: flex;
    .date-footer {
      display: none;
    }
    p {
      font-size: 10px;
      max-width: 240px;
      padding-bottom: 2px;
    }
    @media print {
      bottom: 0.65cm;
      display: flex;
      .date-footer {
        display: inline;
      }
    }
  }
  .page-footer {
    position: absolute;
    bottom: 8px;
    left: 1.45cm;
    width: calc(100% - 2.9cm);
    align-items: flex-end;
    justify-content: space-between;
    padding: 4px;
    /* display: none; */
    display: block;
    p {
      font-size: 10px;
    }
    @media print {
      bottom: -4px;
      display: block;
    }
  }
  .diagonalCross2 {
    background: linear-gradient(
      to top right,
      #fff calc(50% - 1px),
      #d9d9d9,
      #fff calc(50% + 1px)
    );
  }
  .diagonalCross {
    background: linear-gradient(
      to top left,
      #fff calc(50% - 1px),
      #d9d9d9,
      #fff calc(50% + 1px)
    );
  }
`;
