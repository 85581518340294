import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { Modal } from "antd";
import { language } from "../../../redux/actions/langAction";
import WarningIcon from "../../../assets/icons/green-warningIcon.svg";

export const NewsModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { lang: lan } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(language(lang));
  };
  return (
    <Modal
      open={open}
      centered
      title={null}
      footer={null}
      closable={false}
      width={780}
      className="add-fcc-modal custom info"
    >
      <div className="langs-outer">
        <div className="langs" style={{ maxWidth: "70px", marginLeft: "auto" }}>
          <div
            className={lan === "uz" ? "active-lan" : ""}
            onClick={() => changeLan("uz")}
          >
            UZ
          </div>
          <div
            className={lan === "ru" ? "active-lan" : ""}
            onClick={() => changeLan("ru")}
          >
            RU
          </div>
        </div>
      </div>

      <h1 style={{ textAlign: "center" }} className="warning">
        <img src={WarningIcon} />
      </h1>

      <div className="modal-inner warning">
        <div className="warning-wrap common">
          <p>
            <Trans
              i18nKey="warning.text_news"
              components={{
                CustomLink: <a href="#"></a>,
              }}
            />

            {/* {t("warning.text_news")} */}
          </p>
        </div>
      </div>

      <div style={{ textAlign: "center" }} className="btn-wrap warning">
        <button
          style={{ marginLeft: "auto", marginRight: "auto" }}
          type="button"
          className={"button-active btn-red"}
          onClick={() => setOpen(false)}
        >
          {t("common.read")}
        </button>
      </div>
    </Modal>
  );
};
