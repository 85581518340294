import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getHsCodes } from "../../../../../../apis/innerFss";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
import { HsCodeModal } from "./modal";

export const Hscodes = ({ ind, rul, disabled, fieldName }) => {
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({ offset: 0, limit: 20 });
  const { data: hsCodes, isLoading: hsCodesLoading } = useQuery({
    queryFn: () =>
      getHsCodes({
        ...params,
        gtk_name: true,
        keyword: search ?? null,
      }),
    queryKey: [REACT_QUERY_KEYS.GET_HSCODES, search, params],
    enabled: search === "" || search?.length > 1,
    select: (res) => {
      return res.data?.map((hs) => ({
        ...hs,
        value: hs.code,
        label: hs.code,
      }));
    },
  });
  const { t } = useTranslation();
  return (
    <div>
      {disabled ? (
        <FormSelect
          {...rul}
          disabled={disabled}
          label={`${t("inner-fcc.kod")}`}
          placeholder={t("inner-fcc.tanla")}
          name={`${fieldName}.${ind}.hs_code`}
          options={hsCodes}
          loading={hsCodesLoading}
          onSearch={(e) => setSearch(e)}
        />
      ) : (
        <HsCodeModal
          {...rul}
          label={`${t("inner-fcc.kod")}`}
          placeholder={t("inner-fcc.tanla")}
          name={`${fieldName}.${ind}.hs_code`}
          options={hsCodes}
          loading={hsCodesLoading}
          onSearch={(e) => setSearch(e)}
          search={search}
          setParams={setParams}
          params={params}
        />
      )}
    </div>
  );
};
