import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../../components/formComponents/input";
const { Title, Text } = Typography;

export const AddButton = ({ append, index, fieldName }) => {
  const element_name = `${fieldName}.${index}.transport_number`;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const {
    watch,
    setError,
    formState: { errors },
    setValue,
  } = useFormContext();
  const field = watch(`${fieldName}.${index}`);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const addRow = () => {
    append({
      quantity: undefined,
      unit_code: undefined,
    });
  };

  function isValidString(input) {
    const regex = /^[a-zA-Z0-9]+$/;
    if (regex.test(input) && input?.length > 4) {
      setError(element_name, {
        type: "custom",
        message: "Faqat raqam va lotin harflardan foydalaning",
      });
    }
  }
  useEffect(() => {
    if (watch(element_name)?.length > 0) {
      isValidString(watch(element_name));
    }
    // eslint-disable-next-line
  }, [watch(element_name)]);

  return (
    <>
      <Space direction="vertical" style={{ width: "100%", textAlign: "left" }}>
        <Button
          block
          danger={!!errors?.[fieldName]?.[index]?.transport_number?.message}
          type="primary"
          onClick={() => setIsModalOpen(true)}
          icon={<PlusCircleOutlined />}
          disabled={
            fieldName === "productsOne"
              ? !(
                  field?.name &&
                  field?.quantity &&
                  field?.unit_code &&
                  field?.hs_code
                )
              : !(field?.quantity && field?.unit_code && field?.name_id)
          }
        >
          {t("lab.extra-info")}
        </Button>
        {errors?.[fieldName]?.[index]?.transport_number?.message && (
          <Text type="danger">
            {t(errors?.[fieldName]?.[index]?.transport_number?.message)}
          </Text>
        )}
      </Space>

      <Modal
        open={isModalOpen}
        centered
        className="my-modal"
        footer={null}
        destroyOnClose={true}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <Title level={3}>{t("lab.extra-info")}</Title>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <FormInput
              style={{
                textTransform: "uppercase",
              }}
              label={t("lab.extra-info")}
              placeholder="01A000AA"
              name={element_name}
            />
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space direction="horizontal" size="middle" align="center">
              <Button
                onClick={() => {
                  setValue(element_name, "");
                  addRow();
                }}
              >
                Mavjud emas
              </Button>
              <Button
                type="primary"
                onClick={addRow}
                disabled={
                  watch(element_name)?.length < 4 ||
                  !!errors?.products?.[index]?.transport_number
                }
              >
                Qo'shish
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
