import { useRef } from "react";
import { PdfControl } from "../../../../components/ui/pdfControl";
import { PageOne } from "./components/pageOne";
import { Container, Wrapper } from "./style";
import { PageTwo } from "./components/pageTwo";
import { PageThree } from "./components/pageThree";
import { useQuery } from "@tanstack/react-query";
import { getSprutById } from "../../../../apis/sprut";
import { REACT_QUERY_KEYS } from "../../../../constants/reactQueryKeys";
import { FormProvider, useForm } from "react-hook-form";
import Loader from "../../../../components/loader/Loader";

export const SeedExportAct = ({ match }) => {
  const componentRef = useRef();
  const id = match?.params.id ?? false;
  const form = useForm();
  const { isFetching } = useQuery({
    queryFn: () => getSprutById(id),
    queryKey: [REACT_QUERY_KEYS.GET_SEED_EXPORT_BY_ID],
    select: (res) => res.data,
    enabled: !!id,
    onSuccess: (res) => {
      console.log(res);
      form.setValue("data", res);
    },
  });
  if (isFetching) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }
  return (
    <FormProvider {...form}>
      <Wrapper>
        <PdfControl
          name="CERTIFICATE FOR THE IMPORT OF SPROUTS OR SEEDS INTENDED FOR THE PRODUCTION OF SPROUTS"
          componentRef={componentRef}
        />

        <Container ref={componentRef}>
          <PageOne />
          <PageTwo />
          <PageThree />
        </Container>
      </Wrapper>
    </FormProvider>
  );
};
