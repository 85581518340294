import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getAllCompaniesBox,
  getAllRegions,
  getDistrictsById,
  getFeaDistrictById,
  getFeaPointsById,
  getMarketsById,
} from "../../../../apis/innerFss";
import { REACT_QUERY_KEYS } from "../../../../constants/reactQueryKeys";

export const useMyForm = () => {
  const { control, watch, setValue } = useFormContext();
  const { t } = useTranslation();
  const props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };
  const typeOption = [
    { value: 3, label: t("inner-fcc.inner") },
    { value: 1, label: t("inner-fcc.outer") },
  ];

  const { data: regions, isLoading: regionsLoading } = useQuery({
    queryFn: getAllRegions,
    queryKey: [REACT_QUERY_KEYS.GET_REGIONS],
    select: (res) => {
      return res.data?.map((region) => ({
        ...region,
        value: region.id,
        label: region.name_local,
      }));
    },
  });

  const { data: districts, isFetching: districtsLoading } = useQuery({
    queryFn: () => getDistrictsById(watch("sender_region")),
    queryKey: [REACT_QUERY_KEYS.GET_DISTRICTS, watch("sender_region")],
    enabled: !!watch("sender_region"),
    select: (res) => {
      return res.data?.map((dist) => ({
        ...dist,
        value: dist.id,
        label: dist.name_local,
      }));
    },
  });

  const { data: manfDistricts, isFetching: manDistrictsLoading } = useQuery({
    queryFn: () => getDistrictsById(watch("manufactured_region")),
    queryKey: [REACT_QUERY_KEYS.GET_MANDISTRICTS, watch("manufactured_region")],
    enabled: !!watch("manufactured_region"),
    select: (res) => {
      return res.data?.map((dist) => ({
        ...dist,
        value: dist.id,
        label: dist.name_local,
      }));
    },
  });

  const { data: feaPoints, isFetching: feaPointsLoading } = useQuery({
    queryFn: () => getFeaPointsById(watch("region_in_b_point")),
    queryKey: [REACT_QUERY_KEYS.GET_FEAPOINTS, watch("region_in_b_point")],
    enabled: !!watch("region_in_b_point"),
    select: (res) => {
      return res.data?.map((dist) => ({
        ...dist,
        value: dist.id,
        label: dist.name_local,
      }));
    },
  });
  const { data: feaPointsDistrict, isFetching: feaPointsDistrictLoading } =
    useQuery({
      queryFn: () => getFeaDistrictById(watch("region_in_b_point")),
      queryKey: [
        REACT_QUERY_KEYS.GET_FEAPOINTS_DIST,
        watch("region_in_b_point"),
      ],
      enabled: !!watch("region_in_b_point"),
      select: (res) => {
        return res.data?.map((dist) => ({
          ...dist,
          value: dist.id,
          label: dist.name_local,
        }));
      },
    });
  const { data: markets, isFetching: marketsLoading } = useQuery({
    queryFn: () => getMarketsById(watch("district_in_b_point")),
    queryKey: [
      REACT_QUERY_KEYS.GET_FEAPOINTS_DIST,
      watch("district_in_b_point"),
    ],
    enabled: !!watch("district_in_b_point"),
    select: (res) => {
      return res.data?.map((dist) => ({
        ...dist,
        value: dist.id,
        label: dist.name,
      }));
    },
  });

  return {
    t,
    props,
    regionsLoading,
    regions,
    districts,
    districtsLoading,
    typeOption,
    feaPoints,
    feaPointsLoading,
    feaPointsDistrict,
    feaPointsDistrictLoading,
    marketsLoading,
    markets,
    manfDistricts,
    manDistrictsLoading,
    watch,
    setValue,
  };
};
