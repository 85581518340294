import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Info from "./info";

const TableRow = ({ item, index, date }) => {
  const { t } = useTranslation();

  const [openInfo, setOpenInfo] = useState(false);

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{item?.invoice_number}</td>
      <td>{item?.order_number}</td>
      <td>{item?.payment_amount}</td>
      <td>
        <a className="more" onClick={(e) => setOpenInfo(true)}>
          {t("balance.more")}
        </a>
      </td>
      <td>{item?.service_name}</td>
      <td>
        {date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-
        {date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1}
        -{date.getFullYear()}
        <br />
        {date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
        {date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:
        {date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}
      </td>
      <Info open={openInfo} setOpen={setOpenInfo} data={item?.payer_name} />
    </tr>
  );
};

export default TableRow;
