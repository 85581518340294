import { FileImageFilled, UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Space, Typography, Upload } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ImageCreate } from "../../../apis/innerFss";
import { toast } from "react-toastify";
import { API_URL } from "../../../constants/variables";
const { Text } = Typography;
export const FormFileUploadInline = ({
  name,
  control,
  maxCount = 1,
  multiple = false,
  label,
  rules,
  links,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    field: { ref, ...fd },
    fieldState,
  } = useController({
    name,
    control,
    rules: rules,
  });

  const { mutate } = useMutation(
    async (info) => {
      return await ImageCreate({ file: info });
    },
    {
      onSuccess: (res) => {
        if (res.data?.id) {
          fd.onChange(res.data?.id);
        }
      },
      onError: (er) => {
        toast.error(t("error"));
      },
    }
  );

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        ".anticon": {
          color: "#000 !important",
        },
      }}
    >
      {label && (
        <label>
          {label}
          {rules?.required && (
            <Text type="danger">
              <sup> *</sup>
            </Text>
          )}
        </label>
      )}
      <Upload
        multiple={multiple}
        {...props}
        {...fd}
        {...fieldState}
        accept=".jpg,.jpeg,.png,.pdf,.zip"
        maxCount={maxCount}
        beforeUpload={(e) => {
          mutate(e);
          return false;
        }}
      >
        <Button
          ref={ref}
          style={fieldState?.error ? { borderColor: "red", color: "red" } : {}}
          icon={<UploadOutlined />}
        >
          {t("inner-fcc.upload")}
        </Button>
      </Upload>
      {((typeof fd.value === "string" && fd.value === undefined) ||
        (links?.length > 5 && links !== undefined)) && (
        <a
          href={fd.value?.length < 4 ? fd.value : API_URL + links}
          target="_blank"
          style={{ color: "#036323", fontWeight: "500" }}
          className="file-link"
          rel="noreferrer"
        >
          <Space style={{ gap: "4px", padding: "0 4px" }}>
            <FileImageFilled /> {t("seeFile")}
          </Space>
        </a>
      )}
      {fieldState?.error?.message && (
        <Text type="danger">{t(fieldState.error.message)}</Text>
      )}
    </Space>
  );
};
