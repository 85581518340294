import { requestPublic } from "../../helpers/createRequest";

export const getRegions = async () => {
  return await requestPublic(`api/regions/`);
};
export const getUnits = async () => {
  return await requestPublic(`api/units/`);
};
export const getDistrictsByRegionId = async (id) => {
  return await requestPublic(`api/districts/?region=${id}`);
};
export const getRegionById = async (id) => {
  return await requestPublic(`api/regions/?region=${id}`);
};
export const getCountries = async () => {
  return await requestPublic(`api/countries/`);
};
