import { useQuery } from "@tanstack/react-query";
import { Col, Row } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getAllRegions,
  getDistrictsById,
} from "../../../../../../apis/innerFss";
import { FormDateInput } from "../../../../../../components/formComponents/dateInput";
import { FormInput } from "../../../../../../components/formComponents/input";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
import { Hscodes } from "./hcodes/hscodes";
import { product_box_type } from "../../forms/usemyForm";

export const Prdetail = ({ field, index }) => {
  const { t } = useTranslation();
  const { control, watch, getValues } = useFormContext();
  const props = {
    control: control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };
  const { data: regions, isLoading: regionsLoading } = useQuery({
    queryFn: getAllRegions,
    queryKey: [REACT_QUERY_KEYS.GET_REGIONS],
    select: (res) => {
      return res.data?.map((region) => ({
        ...region,
        value: region.id,
        label: region.name_local,
      }));
    },
  });
  const { data: districts, isLoading: districtsLoading } = useQuery({
    queryFn: () =>
      getDistrictsById(getValues(`products.${index}.origin_region`)),
    queryKey: [
      REACT_QUERY_KEYS.GET_DISTRICTS,
      watch(`products.${index}.origin_region`),
    ],
    enabled: !!getValues(`products.${index}.origin_region`),
    select: (res) => {
      return res.data?.map((region) => ({
        ...region,
        value: region.id,
        label: region.name_local,
      }));
    },
  });
  return (
    <Col
      key={field.id}
      span={24}
      style={{
        padding: "8px",
        borderRadius: "8px",
        border: "1px solid #f1f1f1",
      }}
    >
      <Row gutter={[16, 8]} align="bottom">
        <Col span={24} md={6}>
          <FormInput
            control={control}
            rules={props.rules}
            label={t("lab.product-name")}
            name={`products.${index}.name`}
          />
        </Col>
        <Col span={24} md={6}>
          <Hscodes
            fieldName="products"
            rul={{
              ...props.control,
              rules: {
                required: {
                  value: true,
                  message: "inner-fcc.error",
                },
              },
            }}
            ind={index}
          />
        </Col>

        <Col span={24} md={6}>
          <FormInput
            control={control}
            rules={{ ...props.rules }}
            type="number"
            min={0}
            label={t("packaging quant")}
            name={`products.${index}.package_quantity`}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
          />
        </Col>
        <Col span={24} md={6}>
          <FormSelect
            control={control}
            rules={props.rules}
            label={t("type box")}
            name={`products.${index}.package_type`}
            options={product_box_type}
          />
        </Col>
        <Col span={24} md={6}>
          <FormInput
            control={control}
            rules={{ ...props.rules }}
            type="number"
            min={0}
            step={0.001}
            label={t("packaging weight quant")}
            name={`products.${index}.package_net_weight`}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
          />
        </Col>

        <Col span={24} md={6}>
          <FormInput
            control={control}
            rules={{ ...props.rules }}
            type="number"
            min={0}
            step={0.001}
            label={t("total_net_weight")}
            name={`products.${index}.total_net_weight`}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
          />
        </Col>

        <Col span={24} md={12}>
          <FormDateInput
            {...props}
            label={t("collected_date")}
            placeholder={t("collected_date")}
            name={`products.${index}.collected_date`}
          />
        </Col>
        <Col span={24} md={6}>
          <FormInput
            {...props}
            label={t("lot_number")}
            placeholder={t("lot_number")}
            name={`products.${index}.lot_number`}
            rules={{
              required: {
                value: true,
                message: "inner-fcc.error",
              },
              maxLength: { value: 32, message: "max: 32" },
            }}
          />
        </Col>
        <Col span={24} md={6}>
          <FormInput
            {...props}
            type="number"
            label={t("local_fss_number")}
            placeholder={t("local_fss_number")}
            name={`products.${index}.local_fss_number`}
            rules={{
              required: {
                value: true,
                message: "inner-fcc.error",
              },
              minLength: { value: 11, message: t("length", { value: 11 }) },
              maxLength: { value: 11, message: t("length", { value: 11 }) },
            }}
          />
        </Col>

        <Col span={24} md={6}>
          <FormSelect
            control={control}
            rules={props.rules}
            label={t("origin_region")}
            name={`products.${index}.origin_region`}
            options={regions}
            loading={regionsLoading}
          />
        </Col>
        <Col span={24} md={6}>
          <FormSelect
            control={control}
            rules={props.rules}
            label={t("origin_district")}
            name={`products.${index}.origin_district`}
            options={districts}
            loading={districtsLoading}
          />
        </Col>

        <Col span={24} md={12}>
          <FormInput
            {...props}
            label={t("fss.origin")}
            placeholder={t("fss.origin")}
            name={`products.${index}.origin_place`}
          />
        </Col>
      </Row>
    </Col>
  );
};
