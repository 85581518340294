import { Empty, Pagination } from "antd";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { fetchRequest } from "../../helpers/createRequest";
import queryString from "query-string";
import "./inner.css";
import CountryDetailModal from "./CountryDetailModal";

const ExportCountry = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 6;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;

  const [isOpen, setIsOpen] = useState(false);
  const [productID, setProductID] = useState(null);
  const [title, setTitle] = useState("");

  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState();

  const [filter, setFilter] = useState(true);

  useEffect(() => {
    getCountries();
  }, [queryParams.name]);

  async function getCountries() {
    setIsLoading(true);
    await fetchRequest
      .get(`common/countries/`, {
        params: {
          search: queryParams.name,
        },
      })
      .then((res) => {
        setCountries(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const onProductClick = (id, title) => {
    setProductID(id);
    setTitle(title);
    setIsOpen(true);
  };
  const onProductClose = () => {
    setProductID(null);
    setTitle("");
    setIsOpen(false);
  };

  const onFilter = (itemValue, itemKey) => {
    const rootParams = { ...queryParams };
    if (itemValue) {
      rootParams[itemKey] = itemValue;
      rootParams.page = null;
    } else {
      rootParams[itemKey] = null;
    }
    props.history.push({
      search: queryString.stringify(rootParams, { skipNull: true }),
    });
  };

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="balance-page">
      <div className="top">
        <h1>{t("agro.export-country")}</h1>
        <div className="right">
          <div style={{ display: "flex" }}>
            <div
              className="filter-icon"
              onClick={() => setFilter(!filter)}
            ></div>
          </div>
        </div>
      </div>

      <div className={`${filter ? "filter active-filter" : "filter"}`}>
        <input
          type="text"
          placeholder={t("common.name")}
          value={queryParams.name || ""}
          onChange={(e) => onFilter(e.target.value, "name")}
        />
        {/* <select
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
            // console.log(e.target.children);
          }}
        >
          <option value={""}>{t("common.country")}</option>
          {regions?.map((s, idx) => (
            <option value={s.id} key={idx}>
              {s.title}
            </option>
          ))}
        </select> */}
        <button
          onClick={(e) => {
            getCountries();
            onPageChange(1);
          }}
        >
          {t("balance.show")}
        </button>
      </div>

      {isLoading ? <Loader /> : null}

      {!isEmpty(countries) && (
        <div className="cards-page">
          <div className="agro-inner-cards">
            {countries.map((cli, idx) => (
              <div
                className="agro-inner-card sm flag"
                key={idx}
                onClick={(e) => onProductClick(cli.id, cli.title)}
              >
                <div
                  className="bg-img"
                  style={{ backgroundImage: `url(${cli.flag})` }}
                ></div>
                {/* <div className="card-info">
                <div className="card-title">
                  <h2>{cli.title}</h2>
                </div>
                <div className="card-text"></div>
              </div> */}

                {/* <img src={cli.flag}></img> */}
                <h3 style={{ textAlign: "center" }}>{cli.title}</h3>
              </div>
            ))}
          </div>
        </div>
      )}
      {productID && (
        <CountryDetailModal
          open={isOpen}
          setOpen={onProductClose}
          id={productID}
          title={title}
        />
      )}

      {_.isEmpty(countries) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
    </div>
  );
};

export default ExportCountry;
