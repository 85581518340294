import {
  Button,
  Col,
  Empty,
  Flex,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { Container } from "./style";
import { LoadingOutlined } from "@ant-design/icons";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FormInput } from "../../../../../../../components/formComponents/input";
const { Text, Title } = Typography;

export const HsCodeModal = ({
  name,
  control,
  multiple,
  label,
  rules,
  options,
  onSearch,
  setParams,
  params,
  search,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const form = useFormContext();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setError("");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const cyrillicRegex = /^[\u0400-\u04FF0-9]*$/;

    if (cyrillicRegex.test(value)) {
      setError("");
      onSearch(value);
    } else {
      setError(t("errorcyrillicOrNumber"));
    }
  };

  return (
    <>
      <Space onClick={showModal} direction="vertical" style={{ width: "100%" }}>
        {label && (
          <label>
            {label}
            {rules?.required && (
              <Text type="danger">
                <sup> *</sup>
              </Text>
            )}
          </label>
        )}
        <FormInput
          readOnly={true}
          control={control}
          rules={props.rules}
          name={name}
          placeholder={props.placeholder}
          suffix={<IoIosArrowDown />}
        />
      </Space>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        destroyOnClose={true}
        className="my-modal"
        width={900}
        onCancel={handleCancel}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title style={{ marginBottom: 0 }} level={3}>
              {label}
            </Title>
          </Col>
          <Col span={24}>
            <Space
              style={{
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Space direction="vertical" style={{ width: "280px" }}>
                <Input
                  value={search}
                  placeholder={t("hscode or name")}
                  onChange={handleInputChange}
                />
                {error && <Text type="danger">{error}</Text>}
              </Space>
            </Space>
          </Col>
          <Col span={24} style={{ minHeight: "70vh" }} className="main-content">
            {props.hsCodesLoading ? (
              <Flex
                style={{
                  width: "100%",
                  height: "70vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin indicator={<LoadingOutlined spin />} size="large" />
              </Flex>
            ) : (
              <Container>
                <table>
                  <tbody>
                    <tr className="head">
                      <td>
                        <b>№</b>
                      </td>
                      <td>
                        <b>{t("inner-fcc.kod")}</b>
                      </td>

                      <td>
                        <b>{t("fss.description")}</b>
                      </td>
                    </tr>
                    {options?.length > 0 ? (
                      options.map((items, index) => (
                        <tr
                          key={items.value}
                          onClick={() => {
                            form.setValue(name, items.value);
                            handleCancel();
                          }}
                        >
                          <td>{params.offset + (index + 1)}</td>
                          <td>{items.label}</td>
                          <td>{items.gtk_name_ru ?? "-"}</td>
                        </tr>
                      ))
                    ) : (
                      <tr className="head">
                        <td colSpan={3}>
                          <Flex
                            style={{
                              width: "100%",
                              height: "70vh",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Empty description={t("balance.noData")} />
                          </Flex>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Container>
            )}
          </Col>
          <Col span={24}>
            <Flex align="center" justify="center" gap="8px">
              <Button
                onClick={() => {
                  setParams({
                    ...params,
                    offset: params.offset > 20 ? params.offset - 20 : 0,
                  });
                }}
                icon={<MdKeyboardArrowLeft />}
              />
              <Button
                onClick={() => {
                  setParams({
                    ...params,
                    offset: params.offset + 20,
                  });
                }}
                icon={<MdKeyboardArrowRight />}
              />
            </Flex>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
