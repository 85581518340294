import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import request from "../../helpers/createRequest";
import { Empty, Pagination } from "antd";
import _ from "lodash";
import Loader from "../../components/loader/Loader";
import { Rate } from "antd";
import "./notification.css";
import { ReactComponent as StarIcon } from "../../assets/icons/star-rate.svg";
import Icon from "@ant-design/icons";
import RateModal from "./RateModal";

export const StarSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3774 4.36196C14.4503 2.10168 17.5503 2.10167 18.6232 4.36196L20.4775 8.26832C20.9036 9.16588 21.7271 9.788 22.6798 9.93193L26.8261 10.5583C29.2252 10.9208 30.1832 13.9861 28.4472 15.7455L25.4469 18.7862C24.7575 19.4849 24.4429 20.4915 24.6057 21.478L25.3139 25.7715C25.7238 28.2558 23.2158 30.1503 21.07 28.9773L17.3614 26.9502C16.5093 26.4845 15.4913 26.4845 14.6392 26.9502L10.9307 28.9773C8.78485 30.1503 6.27689 28.2558 6.68671 25.7715L7.39498 21.478C7.55772 20.4915 7.24314 19.4849 6.55377 18.7862L3.55349 15.7455C1.81748 13.9861 2.77543 10.9208 5.17454 10.5583L9.32082 9.93193C10.2735 9.788 11.0971 9.16588 11.5231 8.26832L13.3774 4.36196Z"
      stroke="#FBCB07"
      stroke-width="3"
      stroke-linejoin="round"
    />
  </svg>
);

export const StarSvgIcon = (props) => <Icon component={StarIcon} {...props} />;

const Notification = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  const LIMIT = 10;
  const OFFSET = (queryParams.page - 1) * LIMIT || 0;
  const [open, setOpen] = useState(false);

  const [allLists, setAllLists] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // getList();
  }, [queryParams.page]);

  async function getList() {
    setIsLoading(true);
    await request
      .get(`notifications/list/?limit=${LIMIT}&offset=${OFFSET}`)
      .then((res) => {
        setAllLists(res.data.results);
        setCount(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
    setIsLoading(false);
  }

  const onPageChange = (nextPage) => {
    props.history.push({
      search: queryString.stringify(
        { ...queryParams, page: nextPage },
        { skipNull: true }
      ),
    });
  };

  return (
    <div className="inner-fcc-page">

      <div className="top">
        <h1>{t("navbar.notification")}</h1>
        <div className="right">
          {/* <form className="input-wrap" onSubmit={searchList}>
            <div></div>
            <input
              type="text"
              placeholder={t("inner-fcc.search")}
            />
            <MdClose
              className={searchNumber ? "icoon icoon-visible" : "icoon"}
              onClick={() => {
                setSearchNumber("");
                getList();
              }}
            />
          </form> */}
          {/* <div style={{ display: "flex" }}>
            <div className="filter-icon" onClick={() => setFilter(false)}></div>
          </div> */}
        </div>
      </div>

      {isLoading ? <Loader /> : null}
      <div className="list-wrap">
        <div className="list-item">
          <span className="list-text">
            {t("rate.your")} № <a href="#">22110521093</a> {t("rate.confirm")}
          </span>
          <div className="list-action">
            <button className="btn" onClick={() => setOpen(true)}>
              {t("rate.rate")}
            </button>
          </div>
        </div>
        <div className="list-item list-red">
          <span className="list-text">
            {t("rate.your")} № <a href="#">22110521093</a> {t("rate.cancel")}
          </span>
          {/* <div className="list-action">
            <button className="btn">Baholash</button>
        </div> */}
        </div>
        <div className="list-item">
          <span className="list-text">
            {t("rate.your")} № <a href="#">22110521093</a> {t("rate.confirm")}
          </span>
          <div className="list-action">
            {/* <button className="btn">Baholash</button> */}
            <Rate
              disabled
              defaultValue={2}
              character={<StarSvgIcon style={{}} />}
            />
          </div>
        </div>
        <RateModal open={open} setOpen={setOpen} />
      </div>

      {_.isEmpty(allLists) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}

      {!_.isEmpty(allLists) && !isLoading && (
        <Pagination
          defaultCurrent={parseInt(queryParams.page || 1)}
          total={count}
          onChange={onPageChange}
          defaultPageSize={LIMIT}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default Notification;
