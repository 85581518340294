import { Select, Space, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
export const FormSelect = ({
  name,
  control,
  multiple,
  label,
  rules,
  options,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    name,
    control,
    rules: rules,
  });
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {label && (
        <label>
          {label}
          {rules?.required && (
            <Text type="danger">
              <sup> *</sup>
            </Text>
          )}
        </label>
      )}
      <Select
        {...props}
        {...field}
        {...fieldState}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          field.onChange(e);
        }}
        showSearch
        allowClear
        filterOption={
          !props?.notFilteredOption
            ? (input, option) =>
                (option?.label || "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
            : false
        }
        options={options}
        mode={multiple ? "multiple" : undefined}
        style={{
          width: "100%",
        }}
        status={fieldState?.error && "error"}
      />
      {fieldState?.error?.message && (
        <Text type="danger">{t(fieldState.error.message)}</Text>
      )}
    </Space>
  );
};
