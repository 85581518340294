import { Modal } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import request from "../../helpers/createRequest";

const ExportDetailModal = (props) => {
  const { setOpenInfo, openInfo, item, allRegions, allCountries } = props;
  const { pinOrTin, name: nameUser } = useSelector((state) => state.login);

  const { t } = useTranslation();
  const [districts, setDistrics] = useState([]);
  const [allDistricts, setAllDistrics] = useState([]);

  const getDistricts = (regionId) => {
    if (regionId) {
      request
        .get(`api/districts/?region=${regionId}`)
        .then((res) => {
          setAllDistrics(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (item?.manufactured_region && openInfo) {
      getDistricts(item?.manufactured_region);
    }
  }, [openInfo]);

  return (
    <Modal
      open={openInfo}
      onOk={() => setOpenInfo(false)}
      onCancel={() => setOpenInfo(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={1060}
      className="add-fcc-modal"
    >
      <h1>{t("lab.app-more")}</h1>

      <form className="wow slideInLeft" data-wow-duration={"0.3s"}>
        <div className="roow">
          <div>
            <label>{t("lab.exporter")}</label>
            <input type="text" value={nameUser} readOnly />
          </div>
          <div>
            <label>{t("inner-fcc.inn")}</label>
            <input type="text" value={pinOrTin} readOnly />
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("lab.applicant")}</label>
            <input type="text" value={item?.representative_name} readOnly />
          </div>
          <div>
            <label>{t("inner-fcc.phone-num")}</label>
            <input type="text" value={item?.representative_phone} readOnly />
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("lab.importer")}</label>
            <select value={item?.importing_country} readOnly disabled>
              <option hidden>{t("inner-fcc.tanla")}</option>
              {allCountries?.map((country, index) => {
                return (
                  <option key={index} value={country.id}>
                    {country.name_local}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="">
            <label>{t("invoice.admin")}</label>
            <select
              value={item?.inspection}
              readOnly
              disabled
              //   onChange={(e) => setInspection(e.target.value)}
            >
              <option hidden>{t("inner-fcc.tanla")}</option>
              {allRegions?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name_local}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="roow">
          <div>
            <label>{t("inner-fcc.reg-pro")}</label>
            <select
              value={item?.manufactured_region}
              readOnly
              disabled
              // onChange={(e) => setRegion(e.target.value)}
            >
              <option hidden>{t("inner-fcc.tanla")}</option>
              {allRegions?.map((reg, index) => {
                return (
                  <option key={index} value={reg.id}>
                    {reg.name_local}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label>{t("inner-fcc.ray-pro")}</label>
            <select
              value={item?.manufactured_district}
              readOnly
              disabled
              //   onChange={(e) => setDistrict(e.target.value)}
            >
              <option hidden>{t("inner-fcc.tanla")}</option>
              {allDistricts?.map((dist, index) => {
                return (
                  <option key={index} value={dist.id}>
                    {dist.name_local}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("lab.field-name")}</label>
            <input
              type="text"
              value={item?.manufacturer_name}
              readOnly
              disabled
              //   onChange={(e) => setManufacturer(e.target.value)}
            />
          </div>
          <div>
            <label>{t("inner-fcc.inn")}</label>
            <input
              type="number"
              value={item?.manufacturer_tin}
              readOnly
              //   onChange={(e) => setManufacturerTin(e.target.value)}
            />
          </div>
        </div>
      </form>

      {!_.isEmpty(item?.products) && (
        <div className="table-outer">
          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  <th>№</th>
                  <th>{t("lab.product-name")}</th>
                  <th>{t("lab.product-type")}</th>
                  <th>{t("lab.yield")}</th>
                </tr>
              </thead>
              <tbody>
                {item?.products?.map((pro, idx) => {
                  return (
                    <tr key={pro.id}>
                      <td>{idx + 1}</td>
                      <td>{pro.name}</td>
                      <td>{pro.type}</td>
                      <td>
                        {pro.productivity_amount}
                        {pro.productivity_unit == 166 ? " кг" : " тн"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ExportDetailModal;
