import { Button, Col, Modal, Row, Space, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { FormInput } from "../../../../../../components/formComponents/input";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postSubstance } from "../../../../../../apis/guvohnoma";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
const { Title } = Typography;
export const DetailModal = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm();
  const { handleSubmit, control } = form;
  const queryClient = useQueryClient();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { mutate, isLoading } = useMutation(
    async (data) => {
      return await postSubstance(data);
    },
    {
      onSuccess: () => {
        form.reset({});
        void queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_KEYS.MINERAL_SUBSTANCES],
        });
        handleCancel();
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <FormProvider {...form}>
      <Tooltip title="Iltimos yangi yaratishdan oldin qidirb ko'ring, topilmasa keyin yarating!">
        <Button
          onClick={showModal}
          style={{ marginTop: "30px" }}
          icon={<PlusOutlined />}
        />
      </Tooltip>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        footer={null}
        className="my-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title style={{ fontSize: "20px" }} level={3}>
            {t("createSub")}
          </Title>
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <FormInput
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "inner-fcc.error",
                  },
                }}
                label={t("mineral.activeSub")}
                placeholder={t("mineral.activeSub")}
                name="substance"
              />
            </Col>
            <Col span={24} md={12}>
              <FormInput
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "inner-fcc.error",
                  },
                }}
                label={t("mineral.manufacturer")}
                placeholder={t("mineral.manufacturer")}
                name="company"
              />
            </Col>
            <Col span={24}>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "14px",
                  paddingTop: "12px",
                }}
              >
                <Button
                  style={{ boxShadow: "none", minWidth: "150px" }}
                  onClick={handleCancel}
                >
                  {t("inner-fcc.cancel")}
                </Button>
                <Button
                  style={{ boxShadow: "none", minWidth: "150px" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("inner-fcc.send")}
                </Button>
              </Space>
            </Col>
          </Row>
        </form>
      </Modal>
    </FormProvider>
  );
};
