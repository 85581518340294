export const LANGS = "LANGS";
export const LINK = "LINK";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const PIN_LOGIN = "PIN_LOGIN";
export const PIN_LOGOUT = "PIN_LOGOUT";

export const GET_LISTS_REQUEST = "GET_LISTS_REQUEST";
export const GET_LISTS_SUCCESS = "GET_LISTS_SUCCESS";
export const GET_LISTS_ERROR = "GET_LISTS_ERROR";

export const SET_ACT_DATA = "SET_ACT_DATA";
