import React from "react";
import { QRCode as LogQRCode } from "react-qrcode-logo";
import IMGLOGO from "../../../../assets/images/main-logo.webp";

export const QRCodeComponets = ({ size = 100, style }) => {
  return (
    <div
      style={{
        maxWidth: `${size}px`,
        margin: "auto",
        textAlign: "center",
        ...style,
      }}
    >
      <LogQRCode
        size={80}
        fgColor="#056323"
        quietZone={0}
        ecLevel="H"
        logoImage={IMGLOGO}
        logoPadding={1}
        logoPaddingStyle="circle"
        logoWidth={22.5}
        logoHeight={27}
        qrStyle="dots"
        eyeRadius={3}
        style={{
          height: "auto",
          maxWidth: "100%",
          width: "100%",
        }}
        value={window.location.href}
      />
    </div>
  );
};
