import { Modal } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import download from "../../assets/icons/download-green.svg";
import request from "../../helpers/createRequest";
import "./archive.css";

const ArchiveInfo = ({ open, setOpen, data, services }) => {
  const { t } = useTranslation();
  const { lang: lan } = useSelector((state) => state.language);

  const [allRegions, setAllRegions] = useState([]);
  const [name, setName] = useState("name_local");
  //   console.log(data);

  const getRegions = () => {
    request
      .get(`/api/regions/`)
      .then((res) => {
        setAllRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    lan == "uz" ? setName("name_local") : setName("name_ru");
  }, [lan]);

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={950}
      className="add-fcc-modal"
    >
      {!_.isEmpty(data?.invoices) ? (
        <>
          <h1>{t("invoice.invoices")}</h1>

          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  {/* <th>№</th> */}
                  <th>{t("invoice.invoice-num")}</th>
                  <th>{t("invoice.date")}</th>
                  <th>{t("invoice.service")}</th>
                  <th>{t("invoice.admin")}</th>
                  <th>{t("invoice.download")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.invoices?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.number}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {item.given_date}
                      </td>
                      <td>
                        {
                          services?.filter(
                            (sr, idx) => sr.id == item.service_type
                          )[0].value
                        }
                      </td>
                      <td>
                        {allRegions?.filter(
                          (reg, idx) => reg.id == item.region
                        )[0]?.[`${name}`] || t("invoice.reg")}
                      </td>
                      <td>
                        <a
                          className=""
                          href={`https://efito.uz/invoice/${item.number}/download`}
                          target="_blank"
                        >
                          <img className="mobile" src={download} />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}

      {!_.isEmpty(data.balances) ? (
        <>
          <h2 style={{ marginTop: "30px" }}>{t("invoice.net")}</h2>

          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  {/* <th>№</th> */}
                  <th>{t("invoice.admin")}</th>
                  <th>{t("invoice.service")}</th>
                  <th>{t("invoice.date")}</th>
                  <th>{t("invoice.sums")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.balances?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {allRegions?.filter(
                          (reg, idx) => reg.id == item.region
                        )[0]?.[`${name}`] || t("invoice.reg")}
                      </td>
                      <td>
                        {
                          services?.filter(
                            (sr, idx) => sr.id == item.service_type
                          )?.[0]?.value
                        }
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>{item.month}</td>
                      <td>
                        {item?.amount.toLocaleString("en-US") +
                          " " +
                          t("invoice.sum")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ArchiveInfo;
